/**
 * Credit card control class
 * gets user credit card information
 */
import { Payment } from '@mui/icons-material';
import {
  Box, FormControl,
  InputAdornment, TextField, Typography, InputLabel
} from '@mui/material';
import React, { ChangeEvent, useEffect, useState } from 'react';
import './CreditCardControl.scss';
import '../../../pages/VirtualTerminal/VirtualTerminal.scss';
import useCreditCardTypeDetection from '../../../Hooks/useCreditCardTypeDetection';
import {
  FaCcVisa,
  FaCcAmex,
  FaCcMastercard,
  FaCcDiscover,
  FaCcDinersClub,
  FaCcJcb,
} from 'react-icons/fa';
import { CreditCardData } from '../../../types/CreditCardInput.types';

interface CreditCardControlProps {
  values?: any;
  hideCvv: boolean;
  onChange: (values: CreditCardData) => void;
  disabled?: boolean;
  onBlur: (values: CreditCardData) => void;
  setIsPaymentInfoFormValid?: (value: boolean) => void;
}

const CreditCardControl: React.FC<CreditCardControlProps> = ({
  values,
  hideCvv,
  onChange,
  disabled = false,
  onBlur,
  setIsPaymentInfoFormValid,
}) => {
  const [creditCardNumber, setCreditCardNumber] = useState<string>('');
  const [maskedCreditCardNumber, setMaskedCreditCardNumber] =
    useState<string>('');
  const [showMaskedValue, setShowMaskedValue] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<Array<string>>([]);
  const { name } = useCreditCardTypeDetection(creditCardNumber);
  const [creditCardExpiry, setCreditCardExpiry] = useState<string>('');
  const [creditCardCvv, setCreditCardCvv] = useState<string>('');
  const [maskedcreditCardCvv, setMaskedCreditCardCvv] = useState<string>('');
  const [showMaskedCvvValue, setShowMaskedCvvValue] = useState<boolean>(false);
  const [creditCardState, setCreditCardState] = useState<CreditCardData>(
    values || {
      number: '',
      expDate: '',
      cvv: '',
      trackData: '',
    }
  );

  useEffect(() => {
    if (!values?.number) {
      setCreditCardExpiry('');
      setCreditCardCvv('');
      setMaskedCreditCardCvv('');
      setShowMaskedCvvValue(false);
      setMaskedCreditCardNumber('');
      setShowMaskedValue(false);
      setValidationErrors([]);
      // paste card number value repited
      // setCreditCardState(
      //   values || {
      //     number: '',
      //     expDate: '',
      //     cvv: '',
      //     trackData: '',
      //   }
      // );
    }
  }, [values]);

  useEffect(() => {
    onChange && onChange(creditCardState);
    setIsPaymentInfoFormValid &&
      setIsPaymentInfoFormValid(
        validationErrors && validationErrors.length ? false : true
      );
  }, [creditCardState, validationErrors]);

  const handleFieldChange = (e: ChangeEvent<any>) => {
    if (onChange !== undefined) {
      onChange(creditCardState);
    }
  };

  const handleFieldBlur = (e: ChangeEvent<any>) => {
    if (onBlur !== undefined) {
      onBlur(creditCardState);
    }
  };

  const creditCardMod10Validator = (creditCardNumber: string) => {
    const ccNumber = creditCardNumber?.replace(/\D/g, '');

    let checkSum = 0;
    let isEven = false;

    for (let i = ccNumber?.length - 1; i >= 0; i--) {
      let digit = parseInt(ccNumber?.charAt(i), 10);

      if (isEven && (digit *= 2) > 9) {
        digit -= 9;
      }

      checkSum += digit;
      isEven = !isEven;
    }

    return checkSum % 10 === 0;
  };

  const getCcIconByName = (cardName: string) => {
    cardName = cardName ? cardName : '';
    switch (true) {
      case ['visa'].includes(cardName):
        return (
          <FaCcVisa
            color="#b4b7c3"
            size="1.3rem"
            data-testid="cc-input-icon-visa"
          />
        );
      case ['amex', 'americanexpress'].includes(cardName):
        return (
          <FaCcAmex
            color="#b4b7c3"
            size="1.3rem"
            data-testid="cc-input-icon-amex"
          />
        );
      case ['master', 'mastercard'].includes(cardName):
        return (
          <FaCcMastercard
            color="#b4b7c3"
            size="1.3rem"
            data-testid="cc-input-icon-master"
          />
        );
      case ['discover'].includes(cardName):
        return (
          <FaCcDiscover
            color="#b4b7c3"
            size="1.3rem"
            data-testid="cc-input-icon-discover"
          />
        );
      case ['diners'].includes(cardName):
        return (
          <FaCcDinersClub
            color="#b4b7c3"
            size="1.3rem"
            data-testid="cc-input-icon-diners"
          />
        );
      case ['jcb'].includes(cardName):
        return (
          <FaCcJcb
            color="#b4b7c3"
            size="1.3rem"
            data-testid="cc-input-icon-jcb"
          />
        );
      default:
        return <Payment />;
    }
  };

  const handleCreditCardChange = (e: any) => {
    let { value } = e.target;
    handleFieldChange(e);
    if (value == value.replace(/[^\d ]/g, '') && value.length < 20) {
      let cardNumber = value
        .replace(/[^\dA-Z]/g, '')
        .replace(/(.{4})/g, '$1 ')
        .trim();
      setCreditCardNumber(cardNumber);
      setCreditCardState((prevState) => ({
        ...prevState,
        number: value,
      }));
    }
  };

  const handleCreditCardBlur = (e: any) => {
    let { value } = e.target;
    const ccNumberLength = value.length;
    const maskedPart = value
      .substring(0, ccNumberLength - 4)
      .replace(/\d/g, '*');

    const maskedValue = `${maskedPart.trim()}${value.substring(
      maskedPart.length - 1
    )}`;
    setMaskedCreditCardNumber(maskedValue);
    setShowMaskedValue(true);
    if (!creditCardMod10Validator(value)) {
      if (!validationErrors.includes('Card number is not valid')) {
        setValidationErrors([...validationErrors, 'Card number is not valid']);
      }
    } else {
      setValidationErrors([
        ...validationErrors.filter(
          (error) => error !== 'Card number is not valid'
        ),
      ]);
    }
    handleFieldBlur(e);
  };

  const handleExpiryDateChange = (e: any) => {
    let { value } = e.target;
    handleFieldChange(e);
    value = value.replace(/[^\dA-Z]/g, '');
    if (value.length < 5 && value == value.replace(/[^\d ]/g, '')) {
      if (value.length > 2) {
        setCreditCardExpiry(value.replace(/(.{2})/, '$1/').trim());
      } else {
        setCreditCardExpiry(value.trim());
      }
      setCreditCardState((prevState) => ({
        ...prevState,
        expDate: value,
      }));
    }
  };

  const renderCvvBox = () => {
    if (hideCvv) { return (<></>) }
    return (<TextField

      id="Cvv"
      name="Cvv"
      placeholder="CVV"
      className="CvvStyle"
      disabled={disabled}
      onChange={handleCvvChange}
      onBlur={handleCvvBlur}

      value={showMaskedCvvValue ? maskedcreditCardCvv : creditCardCvv}
      onFocus={() => {
        setShowMaskedCvvValue(false);
      }}

    />)
  }

  const handleExpiryDateBlur = (e: any) => {
    const {
      target: { value },
    } = e;
    if (value) {
      const [month, year] = value.split('/');
      const currentYear = new Date().getFullYear().toString().substr(-2);
      const currentMonth = new Date().getMonth() + 1;

      if (
        !month ||
        !year ||
        month > 12 ||
        (month < currentMonth && year <= currentYear) ||
        (month >= currentMonth && year < currentYear)
      ) {
        if (!validationErrors.includes('Card expiration date is not valid')) {
          setValidationErrors([
            ...validationErrors,
            'Card expiration date is not valid',
          ]);
        }
      } else {
        setValidationErrors([
          ...validationErrors.filter(
            (error) => error !== 'Card expiration date is not valid'
          ),
        ]);
      }
      handleFieldBlur(e);
    }
  };

  const handleCvvChange = (e: any) => {
    let { value } = e.target;
    handleFieldChange(e);
    if (value == value.replace(/[^\d ]/g, '') && value.length < 5) {
      setCreditCardCvv(value.trim());
      setCreditCardState((prevState) => ({
        ...prevState,
        cvv: value,
      }));
    }
  };

  const handleCvvBlur = (e: any) => {
    let { value } = e.target;
    const maskedPart = value.replace(/\d/g, '*');
    const cvvRequiredLength = name === 'amex' ? 4 : 3;
    if (value) {
      if (value.length < cvvRequiredLength) {
        if (!validationErrors.includes('CVV number is not valid')) {
          setValidationErrors([...validationErrors, 'CVV number is not valid']);
        }
      } else {
        setValidationErrors([
          ...validationErrors.filter(
            (error) => error !== 'CVV number is not valid'
          ),
        ]);
      }
    } else {
      setValidationErrors([
        ...validationErrors.filter(
          (error) => error !== 'CVV number is not valid'
        ),
      ]);
    }
    setMaskedCreditCardCvv(maskedPart);
    setShowMaskedCvvValue(true);
    handleFieldBlur(e);
  };

  return (
    <FormControl className="CreditCardContainerStyle">

      <Box className="CreditCardInputStyle" display="inline-flex">
        <TextField
          id="CardNumber"
          name="CardNumber"
          placeholder="Card Number"
          className="CreditCardStyle"
          disabled={disabled}

          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {getCcIconByName(name)}
              </InputAdornment>
            ),
            style: {
              borderColor: 'blue'
            },
          }}
          value={showMaskedValue ? maskedCreditCardNumber : creditCardNumber}
          onChange={handleCreditCardChange}
          onBlur={handleCreditCardBlur}
          label="Card Information"

          onFocus={() => {
            setShowMaskedValue(false);
          }}
        ></TextField>
        <TextField

          id="ExpiryDate"
          name="ExpiryDate"
          placeholder="MM/YY"
          className="ExpiryDateStyle"
          disabled={disabled}
          onChange={handleExpiryDateChange}
          onBlur={handleExpiryDateBlur}
          value={creditCardExpiry}
        />
        {renderCvvBox()}
      </Box>
      {validationErrors.length > 0 &&
        validationErrors.map((error: string, index: number) => (
          <Typography
            fontSize="small"
            className="CreditCardErrorStyle"
            key={index}
          >
            {error}
          </Typography>
        ))}
    </FormControl>
  );
};

export default CreditCardControl;
