/**
 * client Host Actions
 */

import api from '../../libs/api';
import { StatusMessageType } from '../../types/common';
import { setStatusMessageAction } from './appActions';
import {
  CLIENT_CREATE_SHAZAM_HOST,
  CLIENT_CREATE_TOKENIZATION_HOST,
  CLIENT_CREATE_TSYS_HOST,
  CLIENT_CREATE_FISERV_HOST,
  CLIENT_UPDATE_TOKENIZATION_HOST,
  CLIENT_UPDATE_TSYS_HOST,
  CLIENT_UPDATE_FISERV_HOST
} from './types';




export const thunkCreateFiservHostAction =
  (params: any) => async (dispatch: any) => {
    const response = await dispatch({
      type: CLIENT_CREATE_FISERV_HOST,
      payload: api.clients.host.createFiservHost(params),
    });
    if (!response?.value?.error) {
      await dispatch(
        setStatusMessageAction(
          StatusMessageType.SUCCESS,
          `Fiserv Host UPdate Successfully.`
        )
      );
    }
    return response;
  };



export const thunkUpdateFiservHostAction =
  (params: any) => async (dispatch: any) => {
    const response = await dispatch({
      type: CLIENT_UPDATE_FISERV_HOST,
      payload: api.clients.host.updateFiservHost(params),
    });
    if (!response?.value?.error) {
      await dispatch(
        setStatusMessageAction(
          StatusMessageType.SUCCESS,
          `Fiserv Host UPdate Successfully.`
        )
      );
    }
    return response;
  };



export const thunkCreateTsysHostAction =
  (params: any) => async (dispatch: any) => {
    const response = await dispatch({
      type: CLIENT_CREATE_TSYS_HOST,
      payload: api.clients.host.createTsysHost(params),
    });
    if (!response?.value?.error) {
      await dispatch(
        setStatusMessageAction(
          StatusMessageType.SUCCESS,
          `Tsys Host Created Successfully.`
        )
      );
    }
    return response;
  };


export const thunkUpdateTsysHostAction =
  (params: any) => async (dispatch: any) => {
    const response = await dispatch({
      type: CLIENT_UPDATE_TSYS_HOST,
      payload: api.clients.host.updateTsysHost(params),
    });
    if (!response?.value?.error) {
      await dispatch(
        setStatusMessageAction(
          StatusMessageType.SUCCESS,
          `Tsys Host UPdate Successfully.`
        )
      );
    }
    return response;
  };



export const thunkCreateTokenizationHostAction =
  (params: any) => async (dispatch: any) => {
    const response = await dispatch({
      type: CLIENT_CREATE_TOKENIZATION_HOST,
      payload: api.clients.host.createTokenizationHost(params),
    });
    if (!response?.value?.error) {
      await dispatch(
        setStatusMessageAction(
          StatusMessageType.SUCCESS,
          `Tokenization Host Created Successfully.`
        )
      );
    }
    return response;
  };

  export const thunkUpdateTokenizationHostAction =
  (params: any) => async (dispatch: any) => {
    const response = await dispatch({
      type: CLIENT_UPDATE_TOKENIZATION_HOST,
      payload: api.clients.host.updateTokenizationHost(params),
    });
    if (!response?.value?.error) {
      await dispatch(
        setStatusMessageAction(
          StatusMessageType.SUCCESS,
          `Tokenization Host UPdate Successfully.`
        )
      );
    }
    return response;
  };

export const thunkCreateShazamHostAction =
  (params: any) => async (dispatch: any) => {
    const response = await dispatch({
      type: CLIENT_CREATE_SHAZAM_HOST,
      payload: api.clients.host.createShazamHost(params),
    });
    if (!response?.value?.error) {
      await dispatch(
        setStatusMessageAction(
          StatusMessageType.SUCCESS,
          `Shazam Host Created Successfully.`
        )
      );
    }
    return response;
  };
