import { Box, Menu, MenuItem, SvgIcon, Typography, Link, colors } from '@mui/material';
import React, { useState } from 'react';
import { useStore } from 'lnox';

import { ReactComponent as Profile } from '../../assets/Profile.svg';
import '../Layout/Layout.scss';

import packageJson from '../../../package.json';
import { showStringsAtLength } from '../../libs/helpers';

const version = packageJson.version;

interface UserProfileProps {
  onLogOut: () => void;
  isTablet: any;
  onProfileSettingsClick: () => void;
  hideClientName?: boolean;
}

function ProfileIcon({ isOpen, ...props }: { isOpen: boolean }) {
  const [isHovered, setHovered] = useState(false);
  const [isActive, setActive] = useState(false);

  const getColor = () => {
    if (isActive || isOpen) {
      return '2A50EE';
    }
    if (isHovered) {
      return '2A50EE';
    }
    return '#36454F';
  };

  return (
    <SvgIcon
      component={Profile}
      style={{ color: getColor() }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onMouseDown={() => setActive(true)}
      onMouseUp={() => setActive(false)}
      {...props}
    />
  );
}

const UserProfile: React.FC<UserProfileProps> = ({
  onLogOut,
  isTablet,
  onProfileSettingsClick,
  hideClientName
}) => {
  const {
    state: {
      auth: { userData, clientId },
    },
  } = useStore();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setIsOpen(true);
    setAnchorEl(event?.currentTarget);
  };
  const handleClose = () => {
    setIsOpen(false);
    setAnchorEl(null);
  };

  const handleProfileSettingsClick = () => {
    onProfileSettingsClick && onProfileSettingsClick();
    setAnchorEl(null);
  };

  const userName = clientId && userData && userData.Clients && userData.Clients.length > 0
    ? userData.Clients.find((item: any) => item.Id === clientId).Name
    : '';


  return (
    <div>
      <Box
        id="demo-positioned-button"
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        display="flex"
        sx={{
          height: '1.5em',
          cursor: 'pointer',
        }}
      >
        <ProfileIcon isOpen={isOpen} />
        {(hideClientName !== true) &&
          (<div style={{ fontSize: "16px", fontWeight: 100, color: colors.grey[700], paddingLeft: 10, marginTop: (userName.length > 18) ? -4 : 0 }}>
            {userName}
          </div>)}

      </Box>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        className="Profile-menu"
      >
        <div style={{ width: "100%", display: "flex", flexDirection: "column", padding: 10 }}>
          <div>
            {clientId && userData && userData.Clients && userData.Clients.length > 0
              ? userData.Clients.find((item: any) => item.Id === clientId).Name
              : ''}
          </div>
          <div>
            <p style={{ fontSize: "12px", fontWeight: 200, color: colors.grey[700] }}>{userData && userData.User && userData.User.Email}</p>
          </div>
        </div>
        <MenuItem>
          <Link underline="hover" href="#" onClick={handleProfileSettingsClick}>Profile Settings</Link>
        </MenuItem>
        <MenuItem>
          <Link underline="hover" href="#" onClick={handleClose}>About</Link>
        </MenuItem>
        <MenuItem>
          <Link underline="hover" href="#" onClick={onLogOut}>Logout</Link>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default UserProfile;
