import { RepeatOneSharp } from '@mui/icons-material';
import api from '../../libs/api';
import { ClientHostConfig } from '../../types/api';

import {
  CLIENT_FETCH_LIST_PENDING,
  CLIENT_FETCH_LIST_FULFILLED,
  CLIENT_FETCH_LIST_REJECTED,
  CLIENT_LIST_RESET,
  CLIENT_FETCH_API_KEY,
  CLIENT_FETCH_LIST_HOST_CONFIGS_PENDING,
  CLIENT_FETCH_LIST_HOST_CONFIGS_FULFILLED,
  CLIENT_FETCH_LIST_HOST_CONFIGS_REJECTED,
  CLIENT_FETCH_LIST_HOST_CONFIGS_RESET,
  ClientHostConfigTypes

} from './types';

export const thunkClientFetchListAction = (params?: any) => {
  return (dispatch: any) => {
    dispatch({
      type: CLIENT_FETCH_LIST_PENDING,
    });
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        api.clients.fetch(params)
          .then((response) => {
            dispatch({
              type: CLIENT_FETCH_LIST_FULFILLED,
              payload: response,
            });
            resolve(response);
          })
          .catch((error) => {
            dispatch({
              type: CLIENT_FETCH_LIST_REJECTED,
              payload: error,
            });
            reject(error);
          });
      }, 1000);

    });
  };
};

export const resetClientListAction = () => ({
  type: CLIENT_LIST_RESET,
});

export const resetTransactionsAction = () => ({
  type: CLIENT_LIST_RESET,
});

export const thunkFetchClientApiKeyAction =
  (clientId: string) => async (dispatch: any) => {
    dispatch({
      type: CLIENT_FETCH_API_KEY,
      payload: api.clients.fetchApiKey(clientId),
    });
  };



// this is against the Clients API althoguth it's questionable if it should be 
// here or in hostActions
export const thunkClientFetchConfigHostListAction = (clientId: string) => {
  return (dispatch: any) => {
    dispatch({
      type: CLIENT_FETCH_LIST_HOST_CONFIGS_PENDING,
    });
    return new Promise((resolve, reject) => {
      api.clients.fetchClientHostConfigs(clientId)
        .then(async (response: any) => {
          if( ! response || ! response.message || ! response.message.ClientHosts || response.message.ClientHosts.length < 1) {
            resolve(response);
            dispatch({
              type: CLIENT_FETCH_LIST_HOST_CONFIGS_FULFILLED,
              payload: { ClientHosts: [] },
            });
            return;
          }

          const clientHosts: ClientHostConfig[] = (response && response.message && response.message.ClientHosts) ? response.message.ClientHosts : [];

          const tsysConfig: ClientHostConfig | undefined = clientHosts.find((clientHost) => {
            return clientHost.HostId === ClientHostConfigTypes.CLIENT_HOST_ID_TSYS;
          });
          const shazamConfig: ClientHostConfig | undefined = clientHosts.find((clientHost) => {
            return clientHost.HostId === ClientHostConfigTypes.CLIENT_HOST_ID_SHAZAM;
          });
          const tokenConfig: ClientHostConfig | undefined = clientHosts.find((clientHost) => {
            return clientHost.HostId === ClientHostConfigTypes.CLIENT_HOST_ID_VGS;
          });
          const fiservConfig: ClientHostConfig | undefined = clientHosts.find((clientHost) => {
            return  [ClientHostConfigTypes.CLIENT_HOST_ID_FISERV_NASHVILLE,
              ClientHostConfigTypes.CLIENT_HOST_ID_FISERV_NORTH,
              ClientHostConfigTypes.CLIENT_HOST_ID_FISERV_OMAHA].includes(clientHost.HostId as number);
          });
          const worldpayConfig: ClientHostConfig | undefined = clientHosts.find((clientHost) => {
            return  ClientHostConfigTypes.CLIENT_HOST_ID_WORLDPAY === clientHost.HostId;
          });


          const foundClientHostsWithData: ClientHostConfig[] = [];

          if (tokenConfig) {
            const configData = await api.clients.host.fetchTokenizationHost("" + tokenConfig.HostConfigId);
            if (configData && configData.message && configData.message.VgsHostDetails) {
              tokenConfig.ConfigData = configData.message.VgsHostDetails;
              foundClientHostsWithData.push(tokenConfig);
            }
          }

          if (shazamConfig) {
            const configData = await api.clients.host.fetchShazamHost("" + shazamConfig.HostConfigId);
            shazamConfig.ConfigData = configData.message.ShazamHostDetails;
            foundClientHostsWithData.push(shazamConfig);
          }

          if (tsysConfig) {
            const configData = await api.clients.host.fetchTsysHost("" + tsysConfig.HostConfigId);
            tsysConfig.ConfigData = configData.message.TsysHostDetails;
            foundClientHostsWithData.push(tsysConfig);
          }

          if (fiservConfig) {
            const configData = await api.clients.host.fetchFiservHost("" + fiservConfig.HostConfigId);
            fiservConfig.ConfigData = configData.message.FiservHostDetails;
            foundClientHostsWithData.push(fiservConfig);
          }

          if (worldpayConfig) {
            const configData = await api.clients.host.fetchWorldpayHost("" + worldpayConfig.HostConfigId);
            worldpayConfig.ConfigData = configData.message.WorldpayFisHostDetails;
            foundClientHostsWithData.push(worldpayConfig);
          }


          dispatch({
            type: CLIENT_FETCH_LIST_HOST_CONFIGS_FULFILLED,
            payload: { message: {
              ClientHosts: foundClientHostsWithData }
            },
          });
          resolve(response);
        })
        .catch((error) => {
          dispatch({
            type: CLIENT_FETCH_LIST_HOST_CONFIGS_REJECTED,
            payload: error,
          });
          reject(error);
        });

    });
  };
};