/**
 * User Transaction Reporting Page
 */
import React, { ChangeEvent, useEffect, useState } from 'react';
import './Reporting.scss';
import '../../components/TransactionDetails/TransactionDetails.scss';
import {
  Box,
  Typography,
  Button,
  Tab,
  Tabs,
  IconButton,
  Grid,
  CircularProgress,
  Fade
} from '@mui/material';
import { useStore } from 'lnox';
import SaveAlt from '@mui/icons-material/SaveAlt';
import TransactionTable from '../../components/TransactionTable';
import {
  thunkReportingFetchTransactionsAction,
  thunkExportingFetchTransactionsAction,
  thunkReportingFetchTransactionsSummaryAction,
  thunkReportingFetchBatchesAction,
  thunkBatchCloseAction,
  thunkFetchBatchTransactionsAction,
} from '../../store/actions/reportingActions';
import {
  CARD_NETWORKS,
  CREATED_STATUS,
  DATETIME_FORMAT,
  DATE_FORMAT,
  TRANSACTIONS,
  TRANSACTION_STATUSES,
} from '../../store/constants';
import TransactionDetails from '../../components/TransactionDetails';
import SideRail from '../../components/Common/SideRail';
import { BatchTableData, TransactionTableData } from '../../types/Transactions';
import { ArrowBack } from '@mui/icons-material';
import PrintIcon from '@mui/icons-material/Print';
import TransactionSearchBox from './TransactionSearchBox';
import useCurrency from '../../Hooks/useCurrency';
import { Exporter, ExportType } from '../../libs/ExportHelper';
import TransactionPeriodSelect, {
  defaultPeriodRange,
} from './TransactionPeriodSelect';
import moment from 'moment';
import { TransactionHelper } from '../../libs/TransactionHelper';
import { useNavigate } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import BatchTable from '../../components/BatchTable';
import BatchSearchBox from './BatchSearchBox';
import BatchPeriodSelect from './BatchPeriodSelect';
import BatchDetails from '../../components/BatchDetails';
import { searchObjectForString } from '../../libs/helpers';

export enum TabIndex {
  Transactions = 0,
  Batches = 1,
}

export enum TabDetailsIndex {
  TransactionsDetails = 0,
  BatchesDetails,
}

export enum TransactionDetailsTabIndex {
  General = 0,
  RelatedTransactions = 1,
  Notes = 2,
}

export enum BatchDetailsTabIndex {
  General = 0,
  Notes = 1,
}


interface PayloadProps {
  skip?: number;
  take?: number;
  term?: string;
  searchText?: string;
  sortOrder?: string;
  sortColumn?: string;
}



function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const DEFAULT_TRANSACTIONS_PAYLOAD: PayloadProps = {
  take: 40,
  term: '',
  searchText: '',
  sortOrder: '-1',
  sortColumn: 'TransactionDateTime',
};

export const DEFAULT_BATCHES_PAYLOAD: PayloadProps = {
  take: 80,
  term: '',
  searchText: '',
  sortOrder: '-1',
  sortColumn: '',
};

export const { start, end } = defaultPeriodRange;
export const DEFAULT_DATE_TIME = `createdAt>=${moment(start).format(
  DATE_FORMAT
)},createdAt<=${moment(end).format(DATE_FORMAT)}`;

export const BATCH_DEFAULT_DATE_TIME = `createdate>='${moment(start)
  .startOf('D')
  .format(DATETIME_FORMAT)}',createdate<='${moment(end)
    .endOf('D')
    .format(DATETIME_FORMAT)}'`;



const Reporting: React.FC<{}> = () => {
  const {
    dispatch,
    state: { reporting },
  } = useStore();

  const {
    transactions: {
      fetch: {
        isPending: isPendingTransactionsFetch,
        isFulfilled: isFulfilledTransactionsFetch,
        message: { Results: transactionsResults = [] },
      },
      fetchByID: {
        isPending: isTransactionInfoPending,
        message: { Results: transactionDetailsResult = [] },
      },
    },
    batches: {
      fetch: {
        isPending: isFetchAllBatchesPending,
        isFulfilled: isFetchAllBatchesFulfilled,
        message: { Results: batchesResults = [] },
      },
      close: {
        isFulfilled: batchCloseFulfilled,
        isPending: batchClosePending,
        isRejected: batchCloseRejected,
      },
      transactions: {
        message: { Results: batchTransactionsResult = [] },
        isPending: isFetchBatchTransactionsPending,
        isFulfilled: isFetchBatchTransactionsFulfilled,
      },
    },
  } = reporting;

  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [showTransactionSideRail, setShowTransactionSideRail] = useState(false);
  const [showBatchSideRail, setShowBatchSideRail] = useState(false);
  const [showBackButton, setShowBackButton] = useState<boolean>(false);
  const [selectedTransactionId, setSelectedTransactionId] =
    useState<string>('');
  const [selectedBatchesId, setSelectedBatchesId] = useState<string>('');
  const [transactionDetails, setTransactionDetails] = useState<any>([]);
  const [batchDetails, setBatchDetails] = useState<any>([]);
  const [
    transactionDetailsActiveTabIndex,
    setTransactionDetailsActiveTabIndex,
  ] = useState(0);
  const [batchesToSkip, setBatchesToSkip] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [batchSearchTerm, setBatchSearchTerm] = useState<string>('');
  const [advanceSearchTerm, setAdvanceSearchTerm] = useState<string>('');
  const [createdStatusFilter, setCreatedStatusFilter] = useState<any[]>([]);
  const [transactionFilter, setTransactionFilter] = useState<any[]>([]);
  const [typeFilter, setTypeFilter] = useState<any[]>([]);
  const { toCurrency } = useCurrency();
  const [dateTimeTerm, setDateTimeTerm] = useState<string>(DEFAULT_DATE_TIME);
  const [startDate, setStartDate] = useState<string>(defaultPeriodRange.start);
  const [endDate, setEndDate] = useState<string>(defaultPeriodRange.end);

  const navigate = useNavigate();
  const [transactionsToSkip, setTransactionsToSkip] = useState<number>(0);
  const [componentRef, setComponentRef] = useState<any>({});
  const [batchTransactions, setBatchTransactions] = useState<any>([]);
  const [bacthDateTimeTerm, setBacthDateTimeTerm] = useState<string>(
    BATCH_DEFAULT_DATE_TIME
  );
  const [batchStartDate, setBatchStartDate] = useState<string>(defaultPeriodRange.start);
  const [batchEndDate, setBatchEndDate] = useState<string>(defaultPeriodRange.end);

  useEffect(() => {
    if (transactionDetailsResult.length > 0) {
      setTransactionDetails(transactionDetailsResult);
    }
  }, [transactionDetailsResult]);

  useEffect(() => {
    if (batchTransactionsResult?.length > 0) {
      setBatchTransactions(batchTransactionsResult);
    }
  }, [batchTransactionsResult]);

  // fetch transactions list
  const fetchTransactionsList = (search?: any, filter?: any) => {
    if (isPendingTransactionsFetch === true) {
      return;
    }

    const queryParams = {
      ...DEFAULT_TRANSACTIONS_PAYLOAD,
      term: dateTimeTerm,
    };
    if (search?.length) {
      queryParams.searchText = search;
    }
    if (filter?.length) {
      queryParams.term = filter;
    }
    queryParams.skip = transactionsToSkip;

    dispatch(
      thunkReportingFetchTransactionsSummaryAction({
        ...queryParams,
        // ...payload,
      })
    );
  };

  // fetch batches list
  const fetchBatchesList = (payload: PayloadProps = {}) => {
    if (isFetchAllBatchesPending === true) {
      return;
    }
    const queryParams = {
      ...DEFAULT_BATCHES_PAYLOAD,
      ...payload,
      skip: 0
    };

    if (payload?.searchText) {
      queryParams.searchText = payload?.searchText;
    }

    dispatch(
      thunkReportingFetchBatchesAction({
        ...queryParams,
      })
    );
  };

  const handleFilterUserChanged = (tDateTimeTerm: string): string => {
    let transactionsTypeQuery = '';
    let createdStatusQuery = '';
    let paymentMethodQuery = '';
    if (transactionFilter?.length !== TRANSACTIONS.length) {
      transactionsTypeQuery = `,transactionType=[${transactionFilter
        .map((item: any) => item.key)
        .join(',')}]`;
    }
    if (createdStatusFilter?.length !== CREATED_STATUS.length) {
      createdStatusQuery = `,responseType=[${createdStatusFilter
        .map((item: any) => item.key)
        .join(',')}]`;
    }
    if (typeFilter.length !== CARD_NETWORKS.length) {
      paymentMethodQuery = `,network=[${typeFilter
        .map((item: any) => item.key)
        .join(',')}]`;
    }
    const term = `${tDateTimeTerm}${transactionsTypeQuery}${createdStatusQuery}${paymentMethodQuery}`;
    return term;
  };

  // handle Repoting Page Tab Change
  const handleRepotingPageTabChange = (
    event: React.SyntheticEvent,
    index: number
  ) => {
    setActiveTabIndex(index);
    setSearchTerm('');
    setBatchSearchTerm('');
  };

  const handleSearchFilterChange = (e: ChangeEvent<any>) => {
    const {
      target: { value },
    } = e;
    setSearchTerm(value);
  };

  const handleBatchSearchFilterChange = (e: ChangeEvent<any>) => {
    const {
      target: { value },
    } = e;
    setBatchSearchTerm(value);
  };

  const handleSearchReset = () => {
    setSearchTerm('');
    setAdvanceSearchTerm('');
    setTransactionFilter([]);
    setTypeFilter([]);
    setCreatedStatusFilter([]);
  };

  const handleBatchSearchReset = () => {
    setBatchSearchTerm('');
  };

  const handleTransactionPeriodSelect = (dateTime: string, start: string, end: string) => {
    setDateTimeTerm(dateTime);
    setStartDate(start);
    setEndDate(end);
    const newAdvanceSearchTerm = handleFilterUserChanged(dateTime);
    setAdvanceSearchTerm(newAdvanceSearchTerm);
    fetchTransactionsList(searchTerm, newAdvanceSearchTerm);

  };

  const handleBatchPeriodSelect = (dateTime: string, start: string, end: string) => {
    setBacthDateTimeTerm(dateTime);
    setBatchStartDate(start);
    setBatchEndDate(end);
    fetchBatchesList({
      term: dateTime,
    });
  };

  const formatExportData = (results: any) => {
    let data: any = [];
    results.forEach((transaction: any) => {
      let transactionData = {
        'Date/Time': `${moment(transaction?.DateTime).format(DATETIME_FORMAT)}`,
        Customer: transaction?.Customer,
        'Trans Type': transaction?.TypeOfTransaction,
        Response: transaction?.Response,
        'Last 4': transaction?.CardLast4Digit || transaction?.CardLast4Digit,
        Network: transaction?.CardBrand,
        Amount: toCurrency(
          Number(transaction?.AmountTotal).toFixed(2).toString(),
          '.',
          '$',
          ','
        ),
        'Merchant DBA': transaction?.ClientName || '-',
        'Transaction Id': `${transaction?.TransactionId}\t`,
      };
      data.push(transactionData);
    });
    return data;
  };

  const fetchExportData = async (payload: PayloadProps = {}) => {
    const queryParams = {
      take: '1000',
      term: advanceSearchTerm ? advanceSearchTerm : dateTimeTerm,
      searchText: '',
      sortOrder: '-1',
      sortColumn: 'TransactionDateTime',
    };
    if (searchTerm?.length) {
      queryParams.searchText = searchTerm;
    }
    return await dispatch(
      thunkExportingFetchTransactionsAction({
        ...queryParams,
        ...payload,
      })
    );
  };

  //download report button csvExport file
  const handleTransactionCSVExport: any = async () => {
    const response: any = (await fetchExportData()) || [];
    if (
      response &&
      response.message &&
      response.message.Results &&
      response.message.Results.length
    ) {
      const exportType = ExportType.csv;
      const data: any = formatExportData(response.message.Results) || [];
      const fileName = 'TRANSACTION_REPORT';
      new Exporter(data, fileName, exportType).export();
    }
  };

  const formatBatchExportData = () => {
    let data: any = [];
    batchTransactions?.forEach((transaction: any) => {
      let transactionBatchData = {
        Microservice: 'Terminal',
        Command: transaction.LastAction,
        Amount: toCurrency(
          Number(transaction.Amount).toFixed(2).toString(),
          '.',
          '$',
          ','
        ),
        ApprovedAmount: toCurrency(
          Number(transaction.Amount).toFixed(2).toString(),
          '.',
          '$',
          ','
        ),
        CurrencyCode: '840',
        TerminalId: '',
        CardType: transaction.Network ? `${transaction.Network}\t` : '',
        LastFour: transaction.last4
          ? `${transaction.last4}\t`
          : `${transaction.CardLast4Digit}\t`,
        Expiration: transaction.ExpirationDate
          ? `${transaction.ExpirationDate}\t`
          : '',
        TrackDataType: '',
        EmvType: '',
        CardholderName: transaction.Name || '',
        Industry: '',
        Eci: '',
        PurchaseId: '',
        Guid: `${transaction.TransactionId}\t`,
        TranDateTime: `${moment(transaction.OpenAt).format(DATETIME_FORMAT)}\t`,
        Status: transaction?.Status || '',
        ResponseCode: transaction.ResponseCode
          ? `${transaction.ResponseCode}\t`
          : '',
        ResponseType: transaction.ResponseType || '',
        AvsResult: transaction.AVSResultCode || '',
        CvvResult: '',
        ApprovalCode:
          transaction.ResponseType?.toLowerCase() === 'Approved'
            ? transaction.AuthorizationCode || ''
            : '',
        Client: '',
        UserName: transaction.ClientName || '',
      };
      data.push(transactionBatchData);
    });
    return data;
  };

  //download report button BatchTable
  const handleBatchTransactionCSVExport: any = async () => {
    if (batchDetails) {
      const exportType = ExportType.csv;
      const data: any = formatBatchExportData() || [];
      const fileName = 'BATCH_TRANSACTION_REPORT';
      new Exporter(data, fileName, exportType).export();

    }
  };

  // render transaction list filter
  const renderTransactionFilter = () => {
    return (
      <Box className="TrasactionFilterBox">
        <TransactionSearchBox
          value={searchTerm}
          onChange={handleSearchFilterChange}
          onReset={handleSearchReset}
          onSearch={(searchTerm: string) => {
            setSearchTerm(searchTerm);
            fetchTransactionsList();
          }}
          tranTypeFilter={transactionFilter}
          cardTypeFilter={typeFilter}
          statusFilter={createdStatusFilter}
          getListOfTranTypeChecked={(tranType: any) => {
            setTransactionFilter(tranType);
          }}
          getListOfCardChecked={(card: any) => {
            setTypeFilter(card);
          }}
          getListOfStatusChecked={(status: any) => {
            setCreatedStatusFilter(status);
          }}
        />

        <TransactionPeriodSelect startDate={startDate} endDate={endDate} onSelect={handleTransactionPeriodSelect} />
      </Box>
    );
  };

  const renderBatchFilter = () => {
    return (
      <Box className="TrasactionFilterBox">
        <BatchSearchBox
          value={batchSearchTerm}
          onChange={handleBatchSearchFilterChange}
          onReset={handleBatchSearchReset}
          onSearch={(searchTerm: string) => {
            setBatchSearchTerm(searchTerm);
            fetchBatchesList({ term: bacthDateTimeTerm });
          }}
        />
        <BatchPeriodSelect startDate={batchStartDate}
          endDate={batchEndDate}
          onSelect={handleBatchPeriodSelect} />
      </Box>
    );
  };

  // handle back button click for transaction details siderail
  const handleBackButtonClick = () => { };

  // handle tab change for transaction details siderail
  const handletransactionDetailsTabChange = (
    event: React.SyntheticEvent,
    index: number
  ) => {
    setTransactionDetailsActiveTabIndex(index);
  };

  // render transaction details side rail title
  const renderTransactionDetailsTitle = () => {
    return (
      <Box className="ReportingTabFullWidth">
        <Box className="ReportingTabFullWidth">
          {showBackButton ? (
            <IconButton
              className="IconButtonClass"
              onClick={() => {
                handleBackButtonClick();
              }}
            >
              <ArrowBack />
            </IconButton>
          ) : null}
          <Typography className="ReportingTabHeader">
            Transaction Details
          </Typography>
        </Box>
        <Typography
          fontSize="medium"
          margin={{ top: 'xsmall', left: `${showBackButton ? '30px' : '0px'}` }}
        >
          Transaction ID:{' '}
          {selectedTransactionId ? `${selectedTransactionId}` : '-'}
        </Typography>
        <Box className="ReportingTabFullWidth">
          <Tabs
            className="TabsStyle"
            value={transactionDetailsActiveTabIndex}
            onChange={handletransactionDetailsTabChange}
            aria-label="basic tabs example"
          >
            <Tab className="TabStyle" label="General" {...a11yProps(0)} />
            <Tab
              className="TabStyle DisplayNone"
              label="Related Transactions"
              {...a11yProps(1)}
            />
            <Tab
              className="TabStyle DisplayNone"
              label="Notes"
              {...a11yProps(2)}
            />
          </Tabs>
        </Box>
      </Box>
    );
  };

  // render related tarnsactions tab for transaction details
  const renderTranDetailsTransactionsTab = () => {
    return <Box>Related transactions tab</Box>;
  };

  // render notes tab for transaction details
  const renderTranDetailsNotesTab = () => {
    return <Box>Transactions notes tab</Box>;
  };

  // render general tab for transaction details
  const renderTranDetailsGeneralTab = () => {
    return (
      <Box>
        <TransactionDetails
          selectedTransactionId={selectedTransactionId}
          selectedTransactionInfo={transactionDetails}
          isTransactionInfoPending={isTransactionInfoPending}
        ></TransactionDetails>
      </Box>
    );
  };

  // render panes for transaction details opened in a siderail
  const renderTransactionDetailsTabPanes = (
    index: TransactionDetailsTabIndex
  ) => {
    switch (index) {
      case TransactionDetailsTabIndex.General:
        return (
          <>
            {renderTranDetailsGeneralTab()}
          </>
        );
      case TransactionDetailsTabIndex.RelatedTransactions:
        return (
          <>
            {renderTranDetailsTransactionsTab()}
          </>
        );
      case TransactionDetailsTabIndex.Notes:
        return (
          <>
            {renderTranDetailsNotesTab()}
          </>
        );
    }
  };

  //handle void transactions
  const handleVoid = () => {
    if (selectedTransactionId) {
      navigate(`/terminal/${selectedTransactionId}/void`);
    }
  };

  //handle refund transactions
  const handleRefund = () => {
    if (selectedTransactionId) {
      navigate(`/terminal/${selectedTransactionId}/refund`);
    }
  };

  //handle capture transactions
  const handleCapture = () => {
    if (selectedTransactionId) {
      navigate(`/terminal/${selectedTransactionId}/capture`);
    }
  };

  const marginTop = '20px';
  const marginRight = '5px';
  const marginBottom = '10px';
  const marginLeft = '5px';

  const renderPrintButton = () => {
    return (
      <>
        <ReactToPrint
          trigger={() => (
            <Button
              variant="contained"
              startIcon={<PrintIcon />}
              className="ReportingPrintButtonStyle"
            >
              PRINT
            </Button>
          )}
          content={() => componentRef}
          pageStyle={`@page { margin: ${marginTop} ${marginRight} ${marginBottom} ${marginLeft} !important; } @media { body { -webkit-print-color-adjust: exact; } }`}
        />
        <Box style={{ display: 'none' }}>
          <Box ref={(el) => setComponentRef(el)}>
            <TransactionDetails
              selectedTransactionId={selectedTransactionId}
              selectedTransactionInfo={transactionDetails}
              isTransactionInfoPending={isTransactionInfoPending}
              isAccordionExpanded={true}
            ></TransactionDetails>
          </Box>
        </Box>
      </>
    );
  };

  // render transaction details in side rail
  const renderTransactionDetails = () => {
    const { ResponseType, TransactionType, Status } =
      transactionDetails[0] || {};

    const transactionHelper = new TransactionHelper();
    let [isRefundAllowed, isVoidAllowed, isCaptureAllowed] =
      transactionHelper.getSubsequentTransactions(Status, TransactionType);
    const refundVisibilityConditions = [
      ResponseType !== TRANSACTION_STATUSES.Declined,
      isRefundAllowed,
    ];
    const voidVisibilityConditions = [
      ResponseType !== TRANSACTION_STATUSES.Declined,
      isVoidAllowed,
    ];
    const captureVisibilityConditions = [
      ResponseType !== TRANSACTION_STATUSES.Declined,
      isCaptureAllowed,
    ];
    return (
      <SideRail
        open={showTransactionSideRail}
        title={renderTransactionDetailsTitle()}
        onClose={() => {
          setShowTransactionSideRail(false);
          setTransactionDetails([]);
        }}
        footer={
          <Box className="footerClass">
            <Grid container direction="row" alignItems="center">
              <Box display="inline-flex" className="ReportingTabFullWidth">
                <Box>
                  {transactionDetails &&
                    transactionDetails[0] &&
                    renderPrintButton()}
                </Box>
                <Box className="SubTransactionButtonsStyle">
                  {voidVisibilityConditions?.every(Boolean) ? (
                    <Button
                      variant="contained"
                      className="SideRailfooterButtonStyle"
                      onClick={handleVoid}
                    >
                      {TransactionType?.toLowerCase() === 'auth'
                        ? 'Reversal'
                        : 'Void'}
                    </Button>
                  ) : (
                    ''
                  )}
                  {refundVisibilityConditions?.every(Boolean) ? (
                    <Button
                      variant="contained"
                      className="SideRailfooterButtonStyle"
                      onClick={handleRefund}
                    >
                      Refund
                    </Button>
                  ) : (
                    ''
                  )}
                  {captureVisibilityConditions?.every(Boolean) ? (
                    <Button
                      variant="contained"
                      className="SideRailfooterButtonStyle"
                      onClick={handleCapture}
                    >
                      Capture
                    </Button>
                  ) : (
                    ''
                  )}
                </Box>
              </Box>
            </Grid>
          </Box>
        }
      >
        <Box className="PaddingDetailsPage">
          {renderTransactionDetailsTabPanes(transactionDetailsActiveTabIndex)}
        </Box>
      </SideRail>
    );
  };

  // render general tab for Batch details
  const renderBatchDetailsGeneralTab = () => {
    return (
      <Box>
        <BatchDetails
          transactions={batchTransactions}
          batchDetails={batchDetails}
          onViewDetailsClick={handleViewTransactionDetails}
          handleOnMore={handleOnMoreTransactions}
          isBatchInfoPending={isFetchBatchTransactionsPending}
        />
      </Box>
    );
  };

  // render Notes tab for Batch details
  const renderBatchDetailsNotesTab = () => {
    return <Box>Notes Tab</Box>;
  };

  // render batch title side rail
  const renderBatchDetailsTitle = () => {
    return (
      <Box className="ReportingTabFullWidth">
        <Box className="ReportingTabFullWidth">
          <Typography className="ReportingTabHeader">Batch Details</Typography>
        </Box>
        <Typography className="BatchDetailsIdStyle">
          Batch ID: {selectedBatchesId ? `${selectedBatchesId}` : '-'}
        </Typography>
        <Box className="ReportingTabFullWidth">
          <Tabs value={0} className="TabsStyle" aria-label="basic tabs example">
            <Tab className="TabStyle" label="General" {...a11yProps(0)} />
            <Tab
              className="TabStyle DisplayNone"
              label="Notes"
              {...a11yProps(1)}
            />
          </Tabs>
        </Box>
      </Box>
    );
  };

  const renderBatchDetailsTabPanes = (index: BatchDetailsTabIndex) => {
    switch (index) {
      case BatchDetailsTabIndex.General:
        return (
          <>
            {renderBatchDetailsGeneralTab()}
          </>
        );
      case BatchDetailsTabIndex.Notes:
        return (
          <>
            {renderBatchDetailsNotesTab()}
          </>
        );
    }
  };

  // handle close batch
  const handleCloseBatch = async () => {
    const { BatchId = 0 } = batchDetails || {};
    localStorage.setItem('IsFinancialTransaction', 'true');
    const response = await dispatch(thunkBatchCloseAction(BatchId));
    if (response && response.status) {
      setShowBatchSideRail(false);
      localStorage.setItem('IsFinancialTransaction', 'false');
      fetchBatchesList({ term: bacthDateTimeTerm });
    }
  };

  // render Batch details in side rail
  const renderBatchDetails = () => {
    return (
      <SideRail
        open={showBatchSideRail}
        title={renderBatchDetailsTitle()}
        onClose={() => {
          setShowBatchSideRail(false);
          setBatchDetails([]);
        }}
        footer={
          <Box className="footerClass">
            <Grid container direction="row" alignItems="center">
              <Box display="inline-flex" className="ReportingTabFullWidth">
                <Box className="DownlaodBatchButtonsStyle">
                  <Button
                    variant="outlined"
                    onClick={handleBatchTransactionCSVExport}
                    className="ReportingPrintButtonStyle"
                  >
                    <SaveAlt fontSize="small" className="DownloadIconStyle" />{' '}
                    Download Report
                  </Button>
                </Box>
                <Box className="SubTransactionButtonsStyle">
                  {batchDetails?.ClosedAtDateTime ? (
                    <Typography>
                      Batch closed on{' '}
                      {moment(batchDetails?.ClosedAtDateTime).format(
                        DATETIME_FORMAT
                      )}
                    </Typography>
                  ) : batchDetails?.Status !== 'In Progress' ? (
                    <Box>
                      <Fade in={batchClosePending} unmountOnExit>
                        <CircularProgress
                          size={24}
                          className="BatchCloseSpinnerStyle"
                        />
                      </Fade>
                      <Button
                        variant="contained"
                        className="SideRailfooterButtonStyle"
                        disabled={batchClosePending}
                        onClick={handleCloseBatch}
                      >
                        CLOSE BATCH
                      </Button>
                    </Box>
                  ) : null}
                </Box>
              </Box>
            </Grid>
          </Box>
        }
      >
        <Box className="PaddingDetailsPage">
          {renderBatchDetailsTabPanes(transactionDetailsActiveTabIndex)}
        </Box>
      </SideRail>
    );
  };

  const handleOnMoreTransactions = () => {
    fetchTransactionsList({ skip: 0 }, advanceSearchTerm);
  };

  // handle click on transaction list on particular transactio to show details
  const handleViewTransactionDetails = (row: TransactionTableData) => {
    setTransactionDetails([]);
    dispatch(
      thunkReportingFetchTransactionsAction({
        ...DEFAULT_TRANSACTIONS_PAYLOAD,
        term: `transactionId=${row?.TransactionId}`,
      })
    );
    setSelectedTransactionId(row?.TransactionId);
    showTransactionSideRail
      ? setShowTransactionSideRail(false)
      : setShowTransactionSideRail(true);
  };

  const handleViewBatchDetails = (batchRow: BatchTableData) => {
    setBatchDetails([]);
    dispatch(
      thunkFetchBatchTransactionsAction({
        ...DEFAULT_TRANSACTIONS_PAYLOAD,
        term: `batchId=${batchRow?.BatchId}`,
      })
    );
    setBatchDetails(batchRow);
    setSelectedBatchesId(batchRow?.BatchId);
    showBatchSideRail
      ? setShowBatchSideRail(false)
      : setShowBatchSideRail(true);
  };

  // render transaction table or transaction list
  const renderTransactionTable = () => {
    const searchWord = (searchTerm) ? searchTerm.trim() : "";

    let results: [] = [];

    if (transactionsResults) {

      results = transactionsResults.filter((transRow: any) => {

        let foundTransActionFilter = (transactionFilter.length < 1) ? true : false;
        if (foundTransActionFilter === false) {
          for (let i = 0; i < transactionFilter.length; i++) {
            foundTransActionFilter = transactionFilter[i].value === transRow.TypeOfTransaction;
            if (foundTransActionFilter === true) {
              break;
            }
          }
        };

        let foundTypeFilter = (typeFilter.length < 1) ? true : false;
        if (foundTypeFilter === false) {
          for (let i = 0; i < typeFilter.length; i++) {
            foundTypeFilter = typeFilter[i].value === transRow.CardBrand;
            if (foundTypeFilter === true) {
              break;
            }
          }
        };


        let foundCreatedStatusFilter = (createdStatusFilter.length < 1) ? true : false;
        if (foundCreatedStatusFilter === false) {
          for (let i = 0; i < createdStatusFilter.length; i++) {
            foundCreatedStatusFilter = createdStatusFilter[i].value === transRow.Response;
            if (foundCreatedStatusFilter === true) {
              break;
            }
          }
        };


        let foundSearchString = searchObjectForString(searchWord, transRow);

        let isFound: boolean = (foundTransActionFilter === true &&
          foundTypeFilter === true &&
          foundCreatedStatusFilter === true &&
          foundSearchString === true);
        return isFound;
      });
    }

    return (
      <>
        {isPendingTransactionsFetch && (
          <CircularProgress className="CircularBarStyle"></CircularProgress>
        )}
        {isPendingTransactionsFetch === false && <TransactionTable
          transactions={results}
          onViewDetailsClick={handleViewTransactionDetails}
          handleOnMore={handleOnMoreTransactions}
          isBatchDetails={false}
        />}
      </>
    );
  };

  const handleOnMoreBatches = () => {
    fetchBatchesList({
      searchText: batchSearchTerm,
      skip: batchesToSkip,
      term: bacthDateTimeTerm,
    });
  };

  const renderBatchTable = () => {

    const searchWord = (batchSearchTerm) ? batchSearchTerm.trim() : "";

    let results: [] = [];

    if (searchWord === "") {
      results = batchesResults;
    } else if (batchesResults) {
      results = batchesResults.filter((transRow: any) => {
        return searchObjectForString(searchWord, transRow);
      });
    }

    return (
      <Box>
        {isFetchAllBatchesPending && (
          <CircularProgress className="CircularBarStyle"></CircularProgress>
        )}
        {isFetchAllBatchesPending === false && (
          <BatchTable
            batches={results}
            onViewDetailsClick={handleViewBatchDetails}
            handleOnMore={handleOnMoreBatches}
          />
        )}
      </Box>
    );
  };

  // render transaction tab pane
  const renderTransactionsTab = () => {
    return (
      <Grid container>
        <Grid xs={12} sm={12} md={12} lg={12} xl={12} item container direction={"row"}>
          <div className={"ReportingMainPageItemRowContent"}>
            {renderTransactionFilter()}
          </div>
        </Grid>
        <Grid xs={12} sm={12} md={12} lg={12} xl={12} item container direction={"row"}>
          <div className={"ReportingMainPageItemRowBottomContent"}>
            {renderTransactionTable()}
          </div>
        </Grid>
      </Grid>
    );
  };

  // render batches tab pane
  const renderBatchesTab = () => {
    return (
      <Grid container>
        <Grid xs={12} sm={12} md={12} lg={12} xl={12} item container direction={"row"}>
          <div className={"ReportingMainPageItemRowContent"}>
            {renderBatchFilter()}
          </div>
        </Grid>
        <Grid xs={12} sm={12} md={12} lg={12} xl={12} item container direction={"row"}>
          <div className={"ReportingMainPageItemRowBottomContent"}>
            {renderBatchTable()}
          </div>
        </Grid>
      </Grid>
    );
  };

  // render tabs eg transaction, batches
  const renderTabPanes = (index: TabIndex) => {
    switch (index) {
      case TabIndex.Transactions:
        return (
          <>
            {renderTransactionsTab()}
          </>
        );
      case TabIndex.Batches:
        return (
          <>
            {renderBatchesTab()}
          </>
        );
    }
  };

  const renderDetailsPanes = (index: TabDetailsIndex) => {
    switch (index) {
      case TabDetailsIndex.TransactionsDetails:
        return (
          <>
            {renderTransactionDetails()}
          </>
        );
      case TabDetailsIndex.BatchesDetails:
        return (
          <>
            {renderBatchDetails()}
          </>
        );
    }
  };

  return (
    <Grid className="ReportingPageStyle" container>
      <Grid container>
        <div className="PageHeaderStyle">
          <div>
            <Typography variant="h5" color="primary">
              Reporting
            </Typography>
          </div>
          {transactionsResults?.length && activeTabIndex === 0 ? (
            <Box className="DownloadButtonBoxStyle">
              <Button
                variant="outlined"
                onClick={handleTransactionCSVExport}
                className="DownloadButtonStyle"
              >
                <SaveAlt fontSize="small" className="DownloadIconStyle" />{' '}
                Download Report
              </Button>
            </Box>
          ) : (
            ''
          )}
        </div>
      </Grid>

      <Grid container style={{ width: "100%" }}>
        <Grid container direction="row" className='TabsContainer'>
          <Tabs
            className="TabsStyle"
            textColor="primary"
            value={activeTabIndex}
            onChange={handleRepotingPageTabChange}
            aria-label="basic tabs example"
          >
            <Tab className="TabStyle" label="Transactions" {...a11yProps(0)} />
            <Tab className="TabStyle" label="Batches" {...a11yProps(0)} />
          </Tabs>
        </Grid>
      </Grid>

      <Grid container direction="row">
        <div className={"ReportingMainPageDisplayArea"}>
          {renderTabPanes(activeTabIndex)}
          {renderDetailsPanes(activeTabIndex)}
        </div>

      </Grid>

    </Grid>
  );
};

export default Reporting;
