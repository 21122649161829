import { combineReducers } from '../../index';
import saleReducer from './saleReducer';
import authoriseReducer from './authoriseReducer';
import refundReducer from './refundReducer';
import captureReducer from './captureReducer';
import voidReducer from './voidReducer';
import fetchTransaction from './fetchTransaction';
import payReducer from './payReducer';
import takeReducer from './takeReducer';

export default combineReducers({
  sale: saleReducer,
  authorize: authoriseReducer,
  refund: refundReducer,
  capture: captureReducer,
  void: voidReducer,
  transaction: fetchTransaction,
  pay: payReducer,
  take: takeReducer
});
