import api from '../../libs/api';
import {
  VIRTUAL_TERMINAL_SALE,
  VIRTUAL_TERMINAL_RESET_SALE,
  VIRTUAL_TERMINAL_AUTHORISE,
  VIRTUAL_TERMINAL_RESET_AUTHORISE,
  VIRTUAL_TERMINAL_REFUND,
  VIRTUAL_TERMINAL_RESET_REFUND,
  VIRTUAL_TERMINAL_CAPTURE,
  VIRTUAL_TERMINAL_RESET_CAPTURE,
  VIRTUAL_TERMINAL_RESET_VOID,
  VIRTUAL_TERMINAL_VOID,
  VIRTUAL_TERMINAL_FETCH_TRANSACTION,
  VIRTUAL_TERMINAL_RESET_TRANSACTION,
  VIRTUAL_TERMINAL_RESET_PAY,
  VIRTUAL_TERMINAL_PAY,
  VIRTUAL_TERMINAL_RESET_TAKE,
  VIRTUAL_TERMINAL_TAKE,
} from './types';

export const thunkSaleTransactionAction =
  (transactionRequest?: any) => async (dispatch: any) => {
    dispatch({
      type: VIRTUAL_TERMINAL_SALE,
      payload: api.virtualTerminal.sale(transactionRequest),
    });
  };

export const terminalResetSaleAction = () => ({
  type: VIRTUAL_TERMINAL_RESET_SALE,
});

export const thunkAuthoriseTransactionAction =
  (transactionRequest?: any) => async (dispatch: any) => {
    dispatch({
      type: VIRTUAL_TERMINAL_AUTHORISE,
      payload: api.virtualTerminal.authorise(transactionRequest),
    });
  };

export const terminalResetAuthoriseAction = () => ({
  type: VIRTUAL_TERMINAL_RESET_AUTHORISE,
});

export const thunkRefundTransactionAction =
  (transactionRequest?: any) => async (dispatch: any) => {
    dispatch({
      type: VIRTUAL_TERMINAL_REFUND,
      payload: api.virtualTerminal.refund(transactionRequest),
    });
  };

export const terminalResetRefundAction = () => ({
  type: VIRTUAL_TERMINAL_RESET_REFUND,
});

export const thunkCaptureTransactionAction =
  (transactionRequest?: any) => async (dispatch: any) => {
    dispatch({
      type: VIRTUAL_TERMINAL_CAPTURE,
      payload: api.virtualTerminal.capture(transactionRequest),
    });
  };

export const terminalResetCaptureAction = () => ({
  type: VIRTUAL_TERMINAL_RESET_CAPTURE,
});

export const thunkVoidTransactionAction =
  (transactionRequest?: any) => async (dispatch: any) => {
    dispatch({
      type: VIRTUAL_TERMINAL_VOID,
      payload: api.virtualTerminal.void(transactionRequest),
    });
  };

export const terminalResetVoidAction = () => ({
  type: VIRTUAL_TERMINAL_RESET_VOID,
});

export const thunkTerminalFetchTransactionsAction =
  (params?: any) => async (dispatch: any) => {
    dispatch({
      type: VIRTUAL_TERMINAL_FETCH_TRANSACTION,
      payload: api.reporting.transactions.fetch(params),
    });
  };

export const terminalResetFetchTransactionAction = () => ({
  type: VIRTUAL_TERMINAL_RESET_TRANSACTION,
});


export const thunkPayTransactionAction =
  (transactionRequest?: any) => async (dispatch: any) => {
    dispatch({
      type: VIRTUAL_TERMINAL_PAY,
      payload: api.virtualTerminal.pay(transactionRequest),
    });
  };

export const terminalResetPayAction = () => ({
  type: VIRTUAL_TERMINAL_RESET_PAY,
});

export const thunkTakeTransactionAction =
  (transactionRequest?: any) => async (dispatch: any) => {
    dispatch({
      type: VIRTUAL_TERMINAL_TAKE,
      payload: api.virtualTerminal.take(transactionRequest),
    });
  };

export const terminalResetTakeAction = () => ({
  type: VIRTUAL_TERMINAL_RESET_TAKE,
});