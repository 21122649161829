/**
 * Tsys host form
 */
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import { usStatesSelectOptions } from '../../../../../libs/helpers';
import {
  AMEX_OPT_BLUE,
  AZURE_IMAGE_API,
  getHostSettlementTimeOptions,
  HOST_COUNTRY_OPTIONS,
  TSYS_INDUSTRY_TYPE_OPTIONS,
} from '../../../../../store/constants';
import SelectField from '../../../../Common/SelectInput';
import ToggleSwitch from '../../../../Common/ToggleSwitch';

import { thunkClientFetchConfigHostListAction } from '../../../../../store/actions/clientActions';
import { thunkUpdateTsysHostAction } from '../../../../../store/actions/hostActions';


import './TsysHostForm.scss';
import { validationSchema } from './validationSchema';
import '../../../../Layout/Layout.scss';
import { useStore } from 'lnox';
import { ClientHostConfigTypes } from '../../../../../store/actions/types';
import { ClientHostConfig } from '../../../../../types/api';


interface TsysHostFormProps {
  selectedMerchantId: string;
  values: any;
  onConfigChange: (values: any) => void;
  isDisabled: boolean;
  setIsTsysFormValid?: (value: boolean) => void;
}
const TsysHostForm: React.FC<TsysHostFormProps> = ({
  values,
  selectedMerchantId,
  onConfigChange,
  isDisabled,
  setIsTsysFormValid,
}) => {

  const {
    dispatch,
    state: {
      client: { clients },
    },
  } = useStore();

  const {
    fetchClientHostConfigs: {
      isPending: isPendingClientHostConfigs,
      isFulfilled: isFulfilledClientHostConfigs,
      message: { ClientHosts },
    }

  } = clients;


  const [tsysHostFormValues, setTsysHostFormValues] = useState<any>(values);
  const [phoneNumberError, setPhoneNumberError] = useState<boolean>(false);
  const [phoneNumberValue, setPhoneNumberValue] = useState<string>('');
  const stateListOptions = usStatesSelectOptions();

  const [disabled, setDisabled] = useState<boolean>(isDisabled);


  const [confirmDialog, setConfirmDialog] = useState<any>(null);

  const confirmMessage = (message: string, handleClose: ((event: {}) => void) | undefined) => {

    setConfirmDialog(<Dialog
      open={true}
      onClose={(event) => {
        handleClose && handleClose(event);
        setConfirmDialog(null);
      }}

      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Please Confirm this action."}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={(event) => {
          setConfirmDialog(null);
        }}>Cancel</Button>
        <Button onClick={(event) => {
          handleClose && handleClose(event);
          setConfirmDialog(null);
        }} autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>);

  }

  const formik: any = useFormik({
    initialValues: {},
    validationSchema: validationSchema,
    onSubmit: (fromValues: any) => { },
    validateOnChange: true,
  });

  const submitFormValues = () => {
    if (!tsysHostFormValues.IsChanged) {
      setTsysHostFormValues((prevState: any) => ({
        ...prevState,
        IsChanged: true,
      }));
    }
    onConfigChange && onConfigChange(tsysHostFormValues);
    setIsTsysFormValid &&
      setIsTsysFormValid(
        formik.errors && Object.keys(formik.errors).length ? false : true
      );
  };

  const validatePhoneNumber = (value: any) => {
    const regex = /^\d{11}$/;
    if (value) {
      value = value.trim();
      regex.test(value)
        ? setPhoneNumberError(false)
        : setPhoneNumberError(true);
      setPhoneNumberValue(value);
    } else {
      setPhoneNumberError(false);
    }
  };

  return (
    <Box className="HostContainerStyle">

      {confirmDialog}

      <Grid container>
        <Grid item xs={8}>
          <img
            alt={`${AZURE_IMAGE_API}/hosts/tsys.png`}
            src={`${AZURE_IMAGE_API}/hosts/tsys.png`}
            className="HostImageStyle"
          />

        </Grid>
        <Grid xs={4}>
          {Object.keys(values).length > 0 &&
            <div style={{ width: 180, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>

              <Button variant="outlined"
                className="SideRailfooterButtonStyle"
                onClick={() => {
                  setDisabled(!disabled);
                }}>{disabled ? "Edit" : "Cancel"}</Button>


              {!disabled && (<Button variant="outlined"
                className="SideRailfooterButtonStyle"
                onClick={() => {
                  setDisabled(true);

                  confirmMessage("Your about to save this Host Configuration (Tsys).  Please confirm to do this.", () => {
                    new Promise((resolve, reject) => {
                      try {

                        let configData = ClientHosts.find((clientHost: ClientHostConfig) => {
                          return [ClientHostConfigTypes.CLIENT_HOST_ID_TSYS].includes(clientHost.HostId as number);
                        });

                        let hostConfig = {
                          ClientId: selectedMerchantId,
                          TsysHostConfigId: (configData && configData.HostConfigId) ? configData.HostConfigId : '',
                          TsysHostDetails: values,
                          IsActive: values?.IsActive == undefined || values?.IsActive == null
                            ? true
                            : values?.IsActive,
                          IsChanged: values?.IsChanged,
                          Tsys: {
                            ClientId: selectedMerchantId,
                            TsysHostConfigId: (configData && configData.HostConfigId) ? configData.HostConfigId : '',
                            TsysHostDetails: values,
                            IsActive: values?.IsActive == undefined || values?.IsActive == null
                              ? true
                              : values?.IsActive,
                            IsChanged: values?.IsChanged,
                          },
                        };

                        dispatch(thunkUpdateTsysHostAction(hostConfig)).then(() => {
                          try {
                            dispatch(thunkClientFetchConfigHostListAction(tsysHostFormValues.ClientId));
                            resolve({ value: true });
                          } catch (error) {
                            reject({ value: false, error: error });
                          }
                        });
                      } catch (error) {
                        reject({ value: false, error: error });
                      }

                    });
                  });


                }}>Save</Button>)}


              {disabled && (<Button variant="outlined"
                className="SideRailfooterButtonStyle"
                onClick={() => {
                  setDisabled(true);
                  confirmMessage("Your about to delete this Host Configuration (Tsys).  Please confirm to do this.", () => {

                    new Promise((resolve, reject) => {
                      try {
                        dispatch(thunkClientFetchConfigHostListAction(tsysHostFormValues.ClientId));
                        resolve({ value: true });
                      } catch (error) {
                        reject({ value: false, error: error });
                      }

                    });

                  });

                }}>Delete</Button>)}
            </div>}

        </Grid>


      </Grid>


      <Typography className="TitleStyle">General</Typography>

      <Box className="StatusBoxStyle">
        <Typography fontWeight="bold">Status</Typography>
        <ToggleSwitch
          disabled={disabled}
          isChecked={true}
          handleOnChange={(value: any) => {
            setTsysHostFormValues({
              ...values,
              IsActive: value,
              IsTsysEnabled: value
            });
            submitFormValues();
          }}
        ></ToggleSwitch>
      </Box>


      <Box className="MarginTop" display="inline-flex">
        <FormControl className="FormFieldLabelStyle">
          <TextField
            id={'MID'}
            name={'MID'}
            label="MID"
            disabled={disabled}
            className="LeftControlStyle"
            value={tsysHostFormValues['MID']}
            onChange={(e: any) => {
              const {
                target: { value },
              } = e;
              formik.setFieldValue('MID', value);
              setTsysHostFormValues((prevState: any) => ({
                ...prevState,
                MID: value,
              }));
            }}
            onBlur={(e: any) => {
              formik.setFieldTouched('MID');
              submitFormValues();
            }}
            error={formik.touched?.MID && formik.errors?.MID ? true : false}
            helperText={
              formik.touched?.MID && formik.errors['MID']
                ? formik.errors['MID']
                : ''
            }
          />
        </FormControl>
        <FormControl className="FormFieldLabelStyle">
          <TextField
            id={'Bin'}
            name={'Bin'}
            label="BIN"
            disabled={disabled}
            className="RightControlStyle"
            value={tsysHostFormValues['Bin']}
            onChange={(e: any) => {
              const {
                target: { value },
              } = e;
              formik.setFieldValue('Bin', value);
              setTsysHostFormValues((prevState: any) => ({
                ...prevState,
                Bin: value,
              }));
            }}
            onBlur={(e: any) => {
              formik.setFieldTouched('Bin');
              submitFormValues();
            }}
            error={formik.touched?.Bin && formik.errors['Bin'] ? true : false}
            helperText={
              formik.touched?.Bin && formik.errors['Bin']
                ? formik.errors['Bin']
                : ''
            }
          />
        </FormControl>
      </Box>

      <Box className="MarginTop" display="inline-flex">
        <FormControl className="FormFieldLabelStyle">
          <TextField
            id={'AmexNumber'}
            name={'AmexNumber'}
            label="AMEX Number"
            disabled={disabled}
            className="LeftControlStyle"
            value={tsysHostFormValues['AmexNumber']}
            onChange={(e: any) => {
              const {
                target: { value },
              } = e;
              formik.setFieldValue('AmexNumber', value);
              setTsysHostFormValues((prevState: any) => ({
                ...prevState,
                AmexNumber: value,
              }));
            }}
            onBlur={(e: any) => {
              submitFormValues();
              formik.setFieldTouched('AmexNumber');
            }}
            error={
              formik.touched?.AmexNumber && formik.errors['AmexNumber']
                ? true
                : false
            }
            helperText={
              formik.touched?.AmexNumber && formik.errors['AmexNumber']
                ? formik.errors['AmexNumber']
                : ''
            }
          />
        </FormControl>
        <FormControl className="FormFieldLabelStyle">
          <InputLabel id={'AmexOptBlue'}>AMEX Opt Blue</InputLabel>
          <Select
            label={'AMEX Opt Blue'}
            labelId={'AmexOptBlue'}
            id={'AmexOptBlue'}
            name={'AmexOptBlue'}
            disabled={disabled}
            className={'RightControlStyle'}
            value={tsysHostFormValues['State']}
            onChange={(e: any) => {
              const {
                target: { value },
              } = e;
              formik.setFieldValue('AmexOptBlue', value);

              setTsysHostFormValues((prevState: any) => ({
                ...prevState,
                AmexOptBlue: value,
              }));
            }}
            onBlur={(e: any) => {
              submitFormValues();
              formik.setFieldTouched('AmexOptBlue');
            }}
            error={
              formik.touched?.AmexOptBlue && formik.errors['AmexOptBlue']
                ? true
                : false
            }
          >
            {AMEX_OPT_BLUE?.map((option: any) => (
              <MenuItem key={option.key} value={option.value}>
                {option.key}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText sx={{ color: '#d32f2f' }}>
            {formik.touched?.AmexOptBlue && formik.errors['AmexOptBlue']
              ? formik.errors['AmexOptBlue']
              : ''}
          </FormHelperText>
        </FormControl>
      </Box>

      <Box className="MarginTop" display="inline-flex">
        <FormControl className="FormFieldLabelStyle">
          <TextField
            id={'Association'}
            name={'Association'}
            label="Association"
            disabled={disabled}
            className="LeftControlStyle"
            value={tsysHostFormValues['Association']}
            onChange={(e: any) => {
              const {
                target: { value },
              } = e;
              formik.setFieldValue('Association', value);
              setTsysHostFormValues((prevState: any) => ({
                ...prevState,
                Association: value,
              }));
            }}
            onBlur={(e: any) => {
              submitFormValues();
              formik.setFieldTouched('Association');
            }}
            error={
              formik.touched?.Association && formik.errors['Association']
                ? true
                : false
            }
            helperText={
              formik.touched?.Association && formik.errors['Association']
                ? formik.errors['Association']
                : ''
            }
          />
        </FormControl>
        <FormControl className="FormFieldLabelStyle">
          <InputLabel id={'Industry'}>Industry</InputLabel>
          <Select
            label={'Industry'}
            labelId={'Industry'}
            id={'Industry'}
            name={'Industry'}
            disabled={disabled}
            className={'RightControlStyle'}
            value={tsysHostFormValues['State']}
            onChange={(e: any) => {
              const {
                target: { value },
              } = e;
              formik.setFieldValue('Industry', value);
              setTsysHostFormValues((prevState: any) => ({
                ...prevState,
                Industry: value,
              }));
            }}
            onBlur={(e: any) => {
              submitFormValues();
              formik.setFieldTouched('Industry');
            }}
            error={
              formik.touched?.Industry && formik.errors['Industry']
                ? true
                : false
            }
          >
            {TSYS_INDUSTRY_TYPE_OPTIONS?.map((option: any) => (
              <MenuItem key={option.key} value={option.value}>
                {option.key}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText sx={{ color: '#d32f2f' }}>
            {formik.touched?.Industry && formik.errors['Industry']
              ? formik.errors['Industry']
              : ''}
          </FormHelperText>
        </FormControl>
      </Box>

      <Box className="MarginTop" display="inline-flex">
        <FormControl className="FormFieldLabelStyle">
          <TextField
            id={'MCC'}
            name={'MCC'}
            label="MCC"
            disabled={disabled}
            className="LeftControlStyle"
            value={tsysHostFormValues['MCC']}
            onChange={(e: any) => {
              const {
                target: { value },
              } = e;
              formik.setFieldValue('MCC', value);
              setTsysHostFormValues((prevState: any) => ({
                ...prevState,
                MCC: value,
              }));
            }}
            onBlur={(e: any) => {
              submitFormValues();
              formik.setFieldTouched('MCC');
            }}
            error={formik.touched.MCC && formik.errors['MCC'] ? true : false}
            helperText={
              formik.touched?.MCC && formik.errors['MCC']
                ? formik.errors['MCC']
                : ''
            }
          />
        </FormControl>
        <FormControl className="FormFieldLabelStyle">
          <TextField
            id={'BankNumber'}
            name={'BankNumber'}
            label="Bank Number"
            disabled={disabled}
            className="RightControlStyle"
            value={tsysHostFormValues['BankNumber']}
            onChange={(e: any) => {
              const {
                target: { value },
              } = e;
              formik.setFieldValue('BankNumber', value);
              setTsysHostFormValues((prevState: any) => ({
                ...prevState,
                BankNumber: value,
              }));
            }}
            onBlur={(e: any) => {
              submitFormValues();
              formik.setFieldTouched('BankNumber');
            }}
            error={
              formik.touched?.BankNumber && formik.errors['BankNumber']
                ? true
                : false
            }
            helperText={
              formik.touched?.BankNumber && formik.errors['BankNumber']
                ? formik.errors['BankNumber']
                : ''
            }
          />
        </FormControl>
      </Box>

      <Box className="MarginTop" display="inline-flex">
        <FormControl className="FormFieldLabelStyle">
          <TextField
            id={'DiscoverNumber'}
            name={'DiscoverNumber'}
            label="Discover Number"
            disabled={disabled}
            className="LeftControlStyle"
            value={tsysHostFormValues['DiscoverNumber']}
            onChange={(e: any) => {
              const {
                target: { value },
              } = e;
              formik.setFieldValue('DiscoverNumber', value);
              setTsysHostFormValues((prevState: any) => ({
                ...prevState,
                DiscoverNumber: value,
              }));
            }}
            onBlur={(e: any) => {
              submitFormValues();
              formik.setFieldTouched('DiscoverNumber');
            }}
            error={
              formik.touched?.DiscoverNumber && formik.errors['DiscoverNumber']
                ? true
                : false
            }
            helperText={
              formik.touched?.DiscoverNumber && formik.errors['DiscoverNumber']
                ? formik.errors['DiscoverNumber']
                : ''
            }
          />
        </FormControl>
        <FormControl className="FormFieldLabelStyle">
          <TextField
            id={'URL'}
            name={'URL'}
            label="URL"
            disabled={disabled}
            className="RightControlStyle"
            value={tsysHostFormValues['URL']}
            onChange={(e: any) => {
              const {
                target: { value },
              } = e;
              formik.setFieldValue('URL', value);
              setTsysHostFormValues((prevState: any) => ({
                ...prevState,
                URL: value,
              }));
            }}
            onBlur={(e: any) => {
              submitFormValues();
              formik.setFieldTouched('URL');
            }}
            error={formik.touched?.URL && formik.errors['URL'] ? true : false}
            helperText={
              formik.touched?.URL && formik.errors['URL']
                ? formik.errors['URL']
                : ''
            }
          />
        </FormControl>
      </Box>

      <Box className="MarginTop" display="inline-flex">
        <FormControl className="FormFieldLabelStyle">
          <InputLabel id={'SettlementTime'}>Settlement Time</InputLabel>
          <Select
            label={'Settlement Time'}
            labelId={'SettlementTime'}
            id={'SettlementTime'}
            name={'SettlementTime'}
            disabled={disabled}
            className={'SingleControlStyle'}
            value={tsysHostFormValues['State']}
            onChange={(e: any) => {
              const {
                target: { value },
              } = e;
              formik.setFieldValue('SettlementTime', value);
              setTsysHostFormValues((prevState: any) => ({
                ...prevState,
                SettlementTime: value,
              }));
            }}
            onBlur={(e: any) => {
              submitFormValues();
              formik.setFieldTouched('SettlementTime');
            }}
            error={
              formik.touched?.SettlementTime && formik.errors['SettlementTime']
                ? true
                : false
            }
          >
            {getHostSettlementTimeOptions()?.map((option: any) => (
              <MenuItem key={option.key} value={option.value}>
                {option.key}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText sx={{ color: '#d32f2f' }}>
            {formik.touched?.SettlementTime && formik.errors['Industry']
              ? formik.errors['Industry']
              : ''}
          </FormHelperText>
        </FormControl>
      </Box>

      <Typography className="TitleStyle">Merchant</Typography>

      <Box className="MarginTop" display="inline-flex">
        <FormControl className="FormFieldLabelStyle">
          <TextField
            id={'Name'}
            name={'Name'}
            label="Full Name"
            disabled={disabled}
            className="LeftControlStyle"
            value={tsysHostFormValues['Name']}
            onChange={(e: any) => {
              const {
                target: { value },
              } = e;
              formik.setFieldValue('Name', value);
              setTsysHostFormValues((prevState: any) => ({
                ...prevState,
                Name: value,
              }));
            }}
            onBlur={(e: any) => {
              submitFormValues();
              formik.setFieldTouched('Name');
            }}
            error={formik.touched?.Name && formik.errors['Name'] ? true : false}
            helperText={
              formik.touched?.Name && formik.errors['Name']
                ? formik.errors['Name']
                : ''
            }
          />
        </FormControl>
        <FormControl fullWidth>
          <PhoneInput
            disabled={disabled}
            specialLabel="Phone Number"
            inputProps={{
              name: 'DescriptorPhone',
            }}
            country={'us'}
            placeholder={'Phone Number'}
            value={phoneNumberValue}
            isValid={!phoneNumberError}
            countryCodeEditable={false}
            onChange={(phone) => {
              setPhoneNumberValue(phone);
              formik.setFieldValue('DescriptorPhone', phone);
              formik.setFieldTouched('DescriptorPhone');
              setTsysHostFormValues((prevState: any) => ({
                ...prevState,
                DescriptorPhone: phone,
              }));
            }}
            onBlur={(phone) => {
              validatePhoneNumber(phoneNumberValue);
              submitFormValues();
              formik.setFieldTouched('DescriptorPhone');
            }}
            containerStyle={{
              width: '101%',
            }}
            inputStyle={{
              width: '96%',
              height: '95%',
              marginRight: '5%',
              borderRadius: '10px',
            }}
          />
          {phoneNumberError && (
            <FormHelperText sx={{ color: '#d32f2f' }}>
              {phoneNumberError ? 'Phone Number is not valid' : ''}
            </FormHelperText>
          )}
        </FormControl>
      </Box>

      <Box className="MarginTop" display="inline-flex">
        <FormControl className="FormFieldLabelStyle">
          <TextField
            id={'Address1'}
            name={'Address1'}
            label="Street Address"
            disabled={disabled}
            className="LeftControlStyle"
            value={tsysHostFormValues['Address1']}
            onChange={(e: any) => {
              const {
                target: { value },
              } = e;
              formik.setFieldValue('Address1', value);
              setTsysHostFormValues((prevState: any) => ({
                ...prevState,
                Address1: value,
              }));
            }}
            onBlur={(e: any) => {
              submitFormValues();
              formik.setFieldTouched('Address1');
            }}
            error={
              formik.touched?.Address1 && formik.errors['Address1']
                ? true
                : false
            }
            helperText={
              formik.touched?.Address1 && formik.errors['Address1']
                ? formik.errors['Address1']
                : ''
            }
          />
        </FormControl>
        <FormControl className="FormFieldLabelStyle">
          <TextField
            id={'Address2'}
            name={'Address2'}
            label="Apt., Building, Suite, Floor"
            className="RightControlStyle"
            disabled={disabled}
            value={tsysHostFormValues['Address2']}
            onChange={(e: any) => {
              const {
                target: { value },
              } = e;
              formik.setFieldValue('Address2', value);
              setTsysHostFormValues((prevState: any) => ({
                ...prevState,
                Address2: value,
              }));
            }}
            onBlur={(e: any) => {
              submitFormValues();
              formik.setFieldTouched('Address2');
            }}
            error={
              formik.touched?.Address2 && formik.errors['Address2']
                ? true
                : false
            }
            helperText={
              formik.touched?.Address2 && formik.errors['Address2']
                ? formik.errors['Address2']
                : ''
            }
          />
        </FormControl>
      </Box>

      <Box className="MarginTop" display="inline-flex">
        <FormControl className="FormFieldLabelStyle">
          <TextField
            id={'DescriptorCity'}
            name={'DescriptorCity'}
            label="City"
            disabled={disabled}
            className="LeftControlStyle"
            value={tsysHostFormValues['DescriptorCity']}
            onChange={(e: any) => {
              const {
                target: { value },
              } = e;
              formik.setFieldValue('DescriptorCity', value);
              setTsysHostFormValues((prevState: any) => ({
                ...prevState,
                DescriptorCity: value,
              }));
            }}
            onBlur={(e: any) => {
              submitFormValues();
              formik.setFieldTouched('DescriptorCity');
            }}
            error={
              formik.touched?.DescriptorCity && formik.errors['DescriptorCity']
                ? true
                : false
            }
            helperText={
              formik.touched?.DescriptorCity && formik.errors['DescriptorCity']
                ? formik.errors['DescriptorCity']
                : ''
            }
          />
        </FormControl>
        <FormControl className="FormFieldLabelStyle">
          <InputLabel id={'State'}>State</InputLabel>
          <SelectField
            label={'State'}
            labelId={'DescriptorState'}
            id={'DescriptorState'}
            disabled={disabled}
            name={'DescriptorState'}
            className="RightControlStyle"
            options={stateListOptions}
            value={tsysHostFormValues['DescriptorState']}
            onChange={(e: any) => {
              const {
                target: { value },
              } = e;
              formik.setFieldValue('DescriptorState', value);
              setTsysHostFormValues((prevState: any) => ({
                ...prevState,
                DescriptorState: value,
              }));
            }}
            onBlur={(e: any) => {
              submitFormValues();
              formik.setFieldTouched('DescriptorState');
            }}
            error={
              formik.touched?.DescriptorState &&
                formik.errors['DescriptorState']
                ? true
                : false
            }
          ></SelectField>
          <FormHelperText sx={{ color: '#d32f2f' }}>
            {formik.touched?.DescriptorState && formik.errors['DescriptorState']
              ? formik.errors['DescriptorState']
              : ''}
          </FormHelperText>
        </FormControl>
      </Box>

      <Box className="MarginTop" display="inline-flex">
        <FormControl className="FormFieldLabelStyle">
          <TextField
            id={'DescriptorPostal'}
            name={'DescriptorPostal'}
            placeholder="xxxxx-xxxx"
            disabled={disabled}
            onChange={(e: any) => {
              const {
                target: { value },
              } = e;
              let zipCode = value;
              zipCode = zipCode.replace(/[^\dA-Z]/g, '');
              if (
                zipCode == zipCode.replace(/[^\d ]/g, '') &&
                zipCode.length < 10
              ) {
                if (zipCode.length > 5) {
                  zipCode = value
                    .replace(/[^\dA-Z]/g, '')
                    .replace(/(.{5})/g, '$1-')
                    .trim();
                }
                formik.setFieldValue('DescriptorPostal', zipCode);
                setTsysHostFormValues((prevState: any) => ({
                  ...prevState,
                  DescriptorPostal: zipCode,
                }));
              }
            }}
            onBlur={(e: any) => {
              submitFormValues();
              formik.setFieldTouched('DescriptorPostal');
            }}
            value={tsysHostFormValues['DescriptorPostal']}
            className="LeftControlStyle"
            label="Zip Code"
            error={
              formik.touched?.DescriptorPostal &&
                formik.errors['DescriptorPostal']
                ? true
                : false
            }
            helperText={
              formik.touched?.DescriptorPostal &&
                formik.errors['DescriptorPostal']
                ? formik.errors['DescriptorPostal']
                : ''
            }
          />
        </FormControl>
        <FormControl className="FormFieldLabelStyle">
          <InputLabel id={'DescriptorCountry'}>Country</InputLabel>
          <Select
            id={'DescriptorCountry'}
            name={'DescriptorCountry'}
            label="Country"
            className="RightControlStyle"
            disabled={true}
            onChange={(e: any) => {
              const {
                target: { value },
              } = e;
              formik.setFieldValue('DescriptorCountry', value);
              setTsysHostFormValues((prevState: any) => ({
                ...prevState,
                DescriptorCountry: value,
              }));
            }}
            onBlur={(e: any) => {
              submitFormValues();
              formik.setFieldTouched('DescriptorCountry');
            }}
            value={'840'}
            error={
              formik.touched?.DescriptorCountry &&
                formik.errors['DescriptorCountry']
                ? true
                : false
            }
          >
            {HOST_COUNTRY_OPTIONS.length > 0 &&
              HOST_COUNTRY_OPTIONS.map((item: any) => {
                return (
                  <MenuItem key={item.key} value={item.value}>
                    {item.key}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </Box>

      <Typography className="TitleStyle">Agent</Typography>

      <Box className="MarginTop" display="inline-flex">
        <FormControl className="FormFieldLabelStyle">
          <TextField
            id={'AgentBankNumber'}
            name={'AgentBankNumber'}
            label="Agent Bank"
            className="LeftControlStyle"
            disabled={disabled}
            value={tsysHostFormValues['AgentBankNumber']}
            onChange={(e: any) => {
              const {
                target: { value },
              } = e;
              formik.setFieldValue('AgentBankNumber', value);
              setTsysHostFormValues((prevState: any) => ({
                ...prevState,
                AgentBankNumber: value,
              }));
            }}
            onBlur={(e: any) => {
              submitFormValues();
              formik.setFieldTouched('AgentBankNumber');
            }}
            error={
              formik.touched?.AgentBankNumber &&
                formik.errors['AgentBankNumber']
                ? true
                : false
            }
            helperText={
              formik.touched?.AgentBankNumber &&
                formik.errors['AgentBankNumber']
                ? formik.errors['AgentBankNumber']
                : ''
            }
          />
        </FormControl>
        <FormControl className="FormFieldLabelStyle">
          <TextField
            id={'AgentChainNumber'}
            name={'AgentChainNumber'}
            label="Chain"
            disabled={disabled}
            className="RightControlStyle"
            value={tsysHostFormValues['AgentChainNumber']}
            onChange={(e: any) => {
              const {
                target: { value },
              } = e;
              formik.setFieldValue('AgentChainNumber', value);
              setTsysHostFormValues((prevState: any) => ({
                ...prevState,
                AgentChainNumber: value,
              }));
            }}
            error={
              formik.touched?.AgentChainNumber &&
                formik.errors['AgentChainNumber']
                ? true
                : false
            }
            onBlur={(e: any) => {
              submitFormValues();
              formik.setFieldTouched('AgentChainNumber');
            }}
            helperText={
              formik.touched?.AgentChainNumber &&
                formik.errors['AgentChainNumber']
                ? formik.errors['AgentChainNumber']
                : ''
            }
          />
        </FormControl>
      </Box>

      <Box className="MarginTop" display="inline-flex">
        <FormControl className="FormFieldLabelStyle">
          <TextField
            id={'StoreNumber'}
            name={'StoreNumber'}
            label="Store Number"
            disabled={disabled}
            className="LeftControlStyle"
            value={tsysHostFormValues['StoreNumber']}
            onChange={(e: any) => {
              const {
                target: { value },
              } = e;
              formik.setFieldValue('StoreNumber', value);
              setTsysHostFormValues((prevState: any) => ({
                ...prevState,
                StoreNumber: value,
              }));
            }}
            onBlur={(e: any) => {
              submitFormValues();
              formik.setFieldTouched('StoreNumber');
            }}
            error={
              formik.touched?.StoreNumber && formik.errors['StoreNumber']
                ? true
                : false
            }
            helperText={
              formik.touched?.StoreNumber && formik.errors['StoreNumber']
                ? formik.errors['StoreNumber']
                : ''
            }
          />
        </FormControl>
        <FormControl className="FormFieldLabelStyle">
          <TextField
            id={'MVV'}
            name={'MVV'}
            label="MVV"
            disabled={disabled}
            className="RightControlStyle"
            value={tsysHostFormValues['MVV']}
            onChange={(e: any) => {
              const {
                target: { value },
              } = e;
              formik.setFieldValue('MVV', value);
              setTsysHostFormValues((prevState: any) => ({
                ...prevState,
                MVV: value,
              }));
            }}
            onBlur={(e: any) => {
              submitFormValues();
              formik.setFieldTouched('MVV');
            }}
            error={formik.touched?.MVV && formik.errors['MVV'] ? true : false}
            helperText={
              formik.touched?.MVV && formik.errors['MVV']
                ? formik.errors['MVV']
                : ''
            }
          />
        </FormControl>
      </Box>

      <Box className="MarginTop" display="inline-flex">
        <FormControl className="FormFieldLabelStyle">
          <TextField
            id={'TerminalId'}
            name={'TerminalId'}
            label="Terminal ID"
            disabled={disabled}
            className="LeftControlStyle"
            value={tsysHostFormValues['TerminalId']}
            onChange={(e: any) => {
              const {
                target: { value },
              } = e;
              formik.setFieldValue('TerminalId', value);
              setTsysHostFormValues((prevState: any) => ({
                ...prevState,
                TerminalId: value,
              }));
            }}
            onBlur={(e: any) => {
              submitFormValues();
              formik.setFieldTouched('TerminalId');
            }}
            error={
              formik.touched?.TerminalId && formik.errors['TerminalId']
                ? true
                : false
            }
            helperText={
              formik.touched?.TerminalId && formik.errors['TerminalId']
                ? formik.errors['TerminalId']
                : ''
            }
          />
        </FormControl>
        <FormControl className="FormFieldLabelStyle">
          <TextField
            id={'TerminalNumber'}
            name={'TerminalNumber'}
            label="Terminal Number"
            disabled={disabled}
            className="RightControlStyle"
            value={tsysHostFormValues['TerminalNumber']}
            onChange={(e: any) => {
              const {
                target: { value },
              } = e;
              formik.setFieldValue('TerminalNumber', value);
              setTsysHostFormValues((prevState: any) => ({
                ...prevState,
                TerminalNumber: value,
              }));
            }}
            onBlur={(e: any) => {
              submitFormValues();
              formik.setFieldTouched('TerminalNumber');
            }}
            error={
              formik.touched?.TerminalNumber && formik.errors['TerminalNumber']
                ? true
                : false
            }
            helperText={
              formik.touched?.TerminalNumber && formik.errors['TerminalNumber']
                ? formik.errors['TerminalNumber']
                : ''
            }
          />
        </FormControl>
      </Box>

      <Box className="MarginTop" display="inline-flex">
        <FormControl className="FormFieldLabelStyle">
          <TextField
            id={'VNumber'}
            name={'VNumber'}
            label="V Number"
            disabled={disabled}
            className="SingleControlStyle"
            value={tsysHostFormValues['VNumber']}
            onChange={(e: any) => {
              const {
                target: { value },
              } = e;
              formik.setFieldValue('VNumber', value);
              setTsysHostFormValues((prevState: any) => ({
                ...prevState,
                VNumber: value,
              }));
            }}
            onBlur={(e: any) => {
              submitFormValues();
              formik.setFieldTouched('VNumber');
            }}
            error={
              formik.touched?.VNumber && formik.errors['VNumber'] ? true : false
            }
            helperText={
              formik.touched?.VNumber && formik.errors['VNumber']
                ? formik.errors['VNumber']
                : ''
            }
          />
        </FormControl>
      </Box>

    </Box>
  );
};

export default TsysHostForm;
