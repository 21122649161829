/**
 * Validation schema for tsys host form
 * Validation is done using YUP
 */
import * as Yup from 'yup';
import {
  requiredFieldValidationRule,
  requiredTextFieldValidationRule,
} from '../../../../Forms/validationRules';

export const validationSchema = () =>
  Yup.object().shape({
    Name: Yup.lazy((val: string) => {
      let validationSchema = requiredTextFieldValidationRule('Name');
      return validationSchema;
    }),

    MId: Yup.lazy((val: string) => {
      let validationSchema = requiredTextFieldValidationRule('MId');
      return validationSchema;
    }),

    MerchantIndustry: Yup.lazy((val: string) => {
      let validationSchema = requiredTextFieldValidationRule('MerchantIndustry');
      return validationSchema;
    }),

    Mcc: Yup.lazy((val: string) => {
      let validationSchema = requiredTextFieldValidationRule('Mcc');
      return validationSchema;
    }),

    VisaAUAR: Yup.lazy((val: string) => {
      let validationSchema = requiredTextFieldValidationRule('VisaAUAR');
      return validationSchema;
    }),

    VisaBid: Yup.lazy((val: string) => {
      let validationSchema = requiredTextFieldValidationRule('VisaBid');
      return validationSchema;
    }),

    EcomTransIndicator: Yup.lazy((val: string) => {
      let validationSchema = requiredTextFieldValidationRule('EcomTransIndicator');
      return validationSchema;
    }),

    GroupId: Yup.lazy((val: string) => {
      let validationSchema = requiredTextFieldValidationRule('Group Id');
      return validationSchema;
    }),

    TerminalCategoryCode: Yup.lazy((val: string) => {
      let validationSchema = requiredTextFieldValidationRule('TerminalCategoryCode');
      return validationSchema;
    }),

    TerminalEntryCapability: Yup.lazy((val: string) => {
      let validationSchema = requiredTextFieldValidationRule('TerminalEntryCapability');
      return validationSchema;
    }),

    URl: Yup.lazy((val: string) => {
      let validationSchema = requiredFieldValidationRule('URl');
      return validationSchema;
    }),

    // descriptorCountry:Yup.string().trim().required(`Country is Required`),

    Tid: Yup.lazy((val: string) => {
      let validationSchema =
        requiredTextFieldValidationRule('Tid');
      return validationSchema;
    }),

    TppId: Yup.lazy((val: string) => {
      let validationSchema =
        requiredTextFieldValidationRule('TppId');
      return validationSchema;
    }),

    DatawireId: Yup.lazy((val: string) => {
      let validationSchema =
        requiredTextFieldValidationRule('DatawireId');
      return validationSchema;
    })
  });
