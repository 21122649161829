/**
 * Layout Page
 * With all app layout, displayed after the user logs in
 */
import React, { useState, useEffect } from 'react';

import {
  SvgIcon,
  TextField,
  Popover,
  Divider,
  ListItem,
  Autocomplete,
  Link as MUILink,
  List,
} from '@mui/material';

import { makeStyles, Theme } from '@material-ui/core/styles';

import PopupState, {
  bindTrigger,
  bindMenu,
  bindPopover,
} from 'material-ui-popup-state';

import './Layout.scss';
import { useStore } from 'lnox';
import { thunkAuthSetUserClientAction } from '../../store/actions/authActions';
import { ReactComponent as Accounts } from '../../assets/Accounts.svg';
import { Client } from '../../types/api';
import { terminalResetSaleAction, terminalResetAuthoriseAction, terminalResetCaptureAction, terminalResetFetchTransactionAction, terminalResetPayAction, terminalResetRefundAction, terminalResetTakeAction, terminalResetVoidAction } from '../../store/actions/virtualTerminalActions';
import fetchAllUsersReducer from '../../store/reducers/users/fetchAllUsers';
import fetchUserProfile from '../../store/reducers/users/profile/fetchUserProfile';
import fetchTransaction from '../../store/reducers/virtualTerminal/fetchTransaction';
const useStyles = makeStyles((theme) => ({
  input: {
    boxSizing: 'border-box',
    fontSize: 'inherit',
    fontFamily: 'inherit',
    appearance: 'none',
    background: 'transparent',
    color: 'inherit',
    width: '100%',
    height: 32, // Adjust the height as desired
    fontWeight: 600,
    margin: '0px',
    borderRadius: '4px',
    outline: 'none',
    border: 'none',
    '&::placeholder': {
      color: theme.palette.grey[500],
      opacity: 1,
    },
    '&:focus::placeholder': {
      opacity: 0,
    },
  }
}));


function AccountsIcon({ isOpen, ...props }: { isOpen: boolean }) {
  const [isHovered, setHovered] = useState(false);
  const [isActive, setActive] = useState(false);

  const getColor = () => {
    if (isActive || isOpen) {
      return '2A50EE';
    }
    if (isHovered) {
      return '2A50EE';
    }
    return '#36454F';
  };

  return (
    <SvgIcon
      className="RightMenuIcons"
      component={Accounts}
      style={{ color: getColor() }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onMouseDown={() => setActive(true)}
      onMouseUp={() => setActive(false)}
      {...props}
    />
  );
}


const AccountList = () => {
  const classes = useStyles();
  const SHOW_LESS_LENGTH = 5;

  const {
    dispatch,
    state: {
      auth: {
        userData,
      }
    }
  } = useStore();

  const [search, setSearch] = useState<string>('');
  const [clients, setClients] = useState<any[]>([]);
  const [showMore, setShowMore] = useState<boolean>(false);

  const loadClientsWithShowLessRange = () => {
    const theClients: any[] = [];

    if (userData && userData.Clients && userData.Clients.length > 0) {
      let endIndex =
        userData.Clients.length <= SHOW_LESS_LENGTH
          ? userData.Clients.length
          : SHOW_LESS_LENGTH;

      for (let i = 0; i < endIndex; ++i) {
        theClients.push({ ...userData.Clients[i] });
      }
    }
    setClients(theClients);
  };

  useEffect(() => {
    loadClientsWithShowLessRange();
  }, [userData]);

  const handleSearchChange = (evt: any) => {
    let searchClientName = evt.currentTarget.value;
    setSearch(searchClientName);

    const theClients =
      userData && userData.Clients && userData.Clients.length > 0
        ? userData.Clients.filter((client: any) => {
          return client.Name.toLowerCase().startsWith(
            searchClientName.toLowerCase()
          );
        })
        : [];

    setClients(theClients);
    setShowMore(true);
  };

  const handleResetChange = (evt: any) => {
    setShowMore(true);
    setSearch('');

    new Promise((resolve, reject) => {
      loadClientsWithShowLessRange();
      resolve(true);
    });
  };

  const handleShowMoreShowLess = () => {
    const tShowMore = !showMore;
    setShowMore(tShowMore);

    const theClients: any[] = [];

    if (userData && userData.Clients && userData.Clients.length > 0) {
      let endIndex =
        tShowMore === true || userData.Clients.length <= SHOW_LESS_LENGTH
          ? userData.Clients.length
          : SHOW_LESS_LENGTH;

      for (let i = 0; i < endIndex; ++i) {
        theClients.push({ ...userData.Clients[i] });
      }
    }

    setClients(theClients);
  };

  return (
    <PopupState variant="popover" popupId="demo-popup-popover">
      {(popupState) => (

        <div>

          <AccountsIcon isOpen={popupState.isOpen} {...bindTrigger(popupState)} />

          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left', // Changed from 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left', // Changed from 'center'
            }}

          >
            <List style={{ width: "100%" }}>
              <ListItem>
                <div>Accounts</div>
              </ListItem>
              <ListItem>
                <div>
                  <TextField
                    value={search}
                    InputProps={{
                      type: 'search',
                      className: classes.input
                    }}
                    InputLabelProps={{
                      shrink: false,
                      className: classes.input,
                      focused: false,
                      style: {
                        opacity: 0,
                      },
                    }}
                    placeholder="Search" // Set the placeholder directly in the TextField component
                    onChange={handleSearchChange}
                    onReset={handleResetChange}
                  />
                </div>
              </ListItem>
              <Divider />
              {clients?.map((item: any, index: number) => (
                <React.Fragment key={index}>
                  <ListItem>
                    <div style={{ paddingLeft: 4 }}>
                      <MUILink
                        underline="hover"
                        href="#"
                        onClick={() => {
                          if (
                            clients &&
                            clients.length > 0
                          ) {
                            dispatch(
                              thunkAuthSetUserClientAction(
                                userData,
                                item as Client
                              )
                            );
                          }
                          dispatch(fetchTransaction)
                          popupState.close();
                        }}
                      >
                        {item.Name}
                      </MUILink>
                    </div>
                  </ListItem>
                  {index !== clients.length - 1 && <Divider />}
                </React.Fragment>
              ))}

              {search === '' && clients && clients.length >= SHOW_LESS_LENGTH ? (
                <>
                  <Divider />
                  <ListItem>
                    <MUILink
                      underline="hover"
                      href="#"
                      onClick={() => {
                        handleShowMoreShowLess();
                      }}
                    >
                      {showMore === true ? 'Show Less' : 'Show All'}
                    </MUILink>
                  </ListItem>
                </>
              ) : null}
            </List>
          </Popover>
        </div>
      )}
    </PopupState>
  );
};

export default AccountList;
