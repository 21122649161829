import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import './AddressForm.scss';
import {
  concatFirstString,
  usStatesSelectOptions,
} from '../../../libs/helpers';
import { useFormik } from 'formik';
import PhoneInput from 'react-phone-input-2';
import { CountryOptions } from '../../../store/constants';
import { AddressFormProps } from '../../../types/AddressInfoForm.types';
import { validateAddressForm } from './validationAddressSchema';
import SelectField from '../SelectInput';
import { isPossibleNumber } from 'libphonenumber-js';


const AddressForm: React.FC<AddressFormProps> = ({
  namePrefix = '',
  onChange,
  setIsAddressFormValid,
  requiredFields = [],
  values,
  disabled = false,
  isResetButtonClicked = false,
}) => {
  const [stateList, setStateList] = useState<Array<object>>(
    usStatesSelectOptions()
  );
  const [addressInfoState, setAddressInfoState] = useState<any>(values || {});
  const [addressCountry, setAddressCountry] = useState<string>("");
  const [fullNameError, setFullNameError] = useState<boolean>(false);
  const [fullNameValue, setFullNameValue] = useState<string>('');

  const [phoneNumberError, setPhoneNumberError] = useState<boolean>(false);
  const [phoneNumberValue, setPhoneNumberValue] = useState<string>('');

  const makeFieldName: any = (name: string) => {
    return namePrefix ? `${namePrefix}${concatFirstString(name || '')}` : name;
  };
  const initialValues = {};

  const formik: any = useFormik({
    initialValues: initialValues,
    validationSchema: validateAddressForm(makeFieldName, requiredFields),
    onSubmit: (fromValues: any) => {
      onChange && onChange(fromValues);
    },
    validateOnChange: true,
  });

  useEffect(() => {
    onChange && onChange(addressInfoState);
    setIsAddressFormValid &&
      setIsAddressFormValid(
        (formik.errors && Object.keys(formik.errors).length) ||
          fullNameError ||
          phoneNumberError
          ? false
          : true
      );
  }, [addressInfoState, formik.errors, fullNameError, phoneNumberError]);

  useEffect(() => {
    setAddressInfoState(values || {});
    if (isResetButtonClicked) {
      formik.setErrors({});
      setFullNameError(false);
      setFullNameValue('');
      setAddressCountry("");
      setPhoneNumberError(false);
      setPhoneNumberValue('+1');
      isResetButtonClicked = false;
    }
  }, [values]);

  // this is added to check teh address for full name field
  // seprately on blur and not on change as the rest fo the form is done
  const validateFullname = (value: string) => {
    const regex = /[A-Z,a-z][\s-][A-Z,a-z]/;
    if (value) {
      value = value.trim();
      regex.test(value) ? setFullNameError(false) : setFullNameError(true);
      setFullNameValue(value);
    } else {
      setFullNameError(false);
    }
  };

  const handlePhoneChange = (value: any, data: any,) => {
    if (value.charAt(0) != 1 && addressCountry == '' && value.length > 3) { value = "+1" + value }
    setPhoneNumberValue(value)
    setAddressCountry(data.countryCode.toUpperCase());
    formik.setFieldValue('Phone', value);
    formik.setFieldTouched('Phone');
    setAddressInfoState((prevState: any) => ({
      ...prevState,
      [`${makeFieldName('Phone')}`]: value,
    }));
  };

  const validateNumber = (value: any, country: any) => {
    formik.setFieldValue(makeFieldName('Phone'), value);
    setAddressInfoState((prevState: any) => ({
      ...prevState,
      [`${makeFieldName('Phone')}`]: value,
    }));

    if (isPossibleNumber(value, country)) {
      setPhoneNumberError(false)
    } else {
      setPhoneNumberError(true);
    }
  };

  return (
    <Box>
      <Box className="MarginTop">
        <FormControl className="AddressFieldLabelStyle">
          <TextField
            id={makeFieldName('FullName')}
            name={makeFieldName('FullName')}
            label="Full Name"
            className="AddressFullNameStyle"
            fullWidth
            disabled={disabled}
            value={fullNameValue}
            error={fullNameError}
            onChange={(e: any) => {
              const {
                target: { value },
              } = e;
              setFullNameValue(value);
              setAddressInfoState((prevState: any) => ({
                ...prevState,
                [`${makeFieldName('FullName')}`]: value,
              }));
            }}
            onBlur={(e: any) => {
              const {
                target: { value },
              } = e;

              validateFullname(value);
              setAddressInfoState((prevState: any) => ({
                ...prevState,
                [`${makeFieldName('FullName')}`]: value,
              }));
            }}
          />
          {fullNameError && (
            <FormHelperText sx={{ color: '#d32f2f' }}>
              {fullNameError ? 'Please enter first name and last name' : ''}
            </FormHelperText>
          )}
        </FormControl>
      </Box>
      <Box className="MarginTop" display="inline-flex">
        <FormControl className="AddressFieldLabelStyle">
          <TextField
            id={makeFieldName('Address1')}
            name={makeFieldName('Address1')}
            label="Street Address"
            className="StreeAddressStyle"
            disabled={disabled}
            value={addressInfoState[makeFieldName('Address1')]}
            onChange={(e: any) => {
              const {
                target: { value },
              } = e;
              formik.setFieldValue(makeFieldName('Address1'), value);
              setAddressInfoState((prevState: any) => ({
                ...prevState,
                [`${makeFieldName('Address1')}`]: value,
              }));
            }}
            onBlur={(e: any) => {
              const {
                target: { value },
              } = e;
              formik.setFieldValue(makeFieldName('Address1'), value);
              setAddressInfoState((prevState: any) => ({
                ...prevState,
                [`${makeFieldName('Address1')}`]: value,
              }));
            }}
            error={
              formik.touched && formik.errors[makeFieldName('Address1')]
                ? true
                : false
            }
          />
          {formik.touched && formik.errors[makeFieldName('Address1')] && (
            <FormHelperText sx={{ color: '#d32f2f' }}>
              {formik.touched && formik.errors[makeFieldName('Address1')]
                ? formik.errors[makeFieldName('Address1')]
                : ''}
            </FormHelperText>
          )}
        </FormControl>
        <FormControl className="AddressFieldLabelStyle">
          <TextField
            id={makeFieldName('Address2')}
            name={makeFieldName('Address2')}
            label="Apt.,Building,Suite,Floor"
            className="AddressLineStyle"
            disabled={disabled}
            value={addressInfoState[makeFieldName('Address2')]}
            onChange={(e: any) => {
              const {
                target: { value },
              } = e;
              formik.setFieldValue(makeFieldName('Address2'), value);
              setAddressInfoState((prevState: any) => ({
                ...prevState,
                [`${makeFieldName('Address2')}`]: value,
              }));
            }}
            onBlur={(e: any) => {
              const {
                target: { value },
              } = e;
              formik.setFieldValue(makeFieldName('Address2'), value);
              setAddressInfoState((prevState: any) => ({
                ...prevState,
                [`${makeFieldName('Address2')}`]: value,
              }));
            }}
            error={
              formik.touched && formik.errors[makeFieldName('Address2')]
                ? true
                : false
            }
            helperText={
              formik.touched && formik.errors[makeFieldName('Address2')]
                ? formik.errors[makeFieldName('Address2')]
                : ''
            }
          />
        </FormControl>
      </Box>
      <Box className="MarginTop" display="inline-flex">
        <Box className="Section1" display="inline-flex">
          <FormControl className="AddressFieldLabelStyle">
            <TextField
              id={makeFieldName('City')}
              name={makeFieldName('City')}
              label="City"
              className="AddressCityStyle"
              value={addressInfoState[makeFieldName('City')]}
              disabled={disabled}
              onChange={(e: any) => {
                const {
                  target: { value },
                } = e;
                formik.setFieldValue(makeFieldName('City'), value);
                setAddressInfoState((prevState: any) => ({
                  ...prevState,
                  [`${makeFieldName('City')}`]: value,
                }));
              }}
              onBlur={(e: any) => {
                const {
                  target: { value },
                } = e;
                formik.setFieldValue(makeFieldName('City'), value);
                setAddressInfoState((prevState: any) => ({
                  ...prevState,
                  [`${makeFieldName('City')}`]: value,
                }));
              }}
              error={
                formik.touched && formik.errors[makeFieldName('City')]
                  ? true
                  : false
              }
            />
            {formik.touched && formik.errors[makeFieldName('City')] && (
              <FormHelperText sx={{ color: '#d32f2f' }}>
                {formik.touched && formik.errors[makeFieldName('City')]
                  ? formik.errors[makeFieldName('City')]
                  : ''}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl className="AddressFieldLabelStyle">
            <InputLabel id={makeFieldName('State')}>State</InputLabel>
            <SelectField
              label={'State'}
              labelId={makeFieldName('State')}
              id={makeFieldName('State')}
              name={makeFieldName('State')}
              className={'AddressStateStyle'}
              options={stateList}
              value={addressInfoState[makeFieldName('State')]}
              onChange={(e: any) => {
                const {
                  target: { value },
                } = e;
                formik.setFieldValue(makeFieldName('State'), value);
                setAddressInfoState((prevState: any) => ({
                  ...prevState,
                  [`${makeFieldName('State')}`]: value,
                }));
              }}
              onBlur={(e: any) => {
                const {
                  target: { value },
                } = e;
                formik.setFieldValue(makeFieldName('State'), value);
                setAddressInfoState((prevState: any) => ({
                  ...prevState,
                  [`${makeFieldName('State')}`]: value,
                }));
              }}
              error={
                formik.touched && formik.errors[makeFieldName('State')]
                  ? true
                  : false
              }
            ></SelectField>
            <FormHelperText sx={{ color: '#d32f2f' }}>
              {formik.touched && formik.errors[makeFieldName('State')]
                ? formik.errors[makeFieldName('State')]
                : ''}
            </FormHelperText>
          </FormControl>
        </Box>
        <Box className="Section2" display="inline-flex">
          <FormControl className="AddressFieldLabelStyle">
            <TextField
              id={makeFieldName('ZipCode')}
              name={makeFieldName('ZipCode')}
              disabled={disabled}
              placeholder="xxxxx-xxxx"
              onChange={(e: any) => {
                const {
                  target: { value },
                } = e;
                let zipCode = value;
                zipCode = zipCode.replace(/[^\dA-Z]/g, '');
                if (
                  zipCode == zipCode.replace(/[^\d ]/g, '') &&
                  zipCode.length < 10
                ) {
                  if (zipCode.length > 5) {
                    zipCode = value
                      .replace(/[^\dA-Z]/g, '')
                      .replace(/(.{5})/g, '$1-')
                      .trim();
                  }
                  formik.setFieldValue(makeFieldName('ZipCode'), zipCode);
                  setAddressInfoState((prevState: any) => ({
                    ...prevState,
                    [`${makeFieldName('ZipCode')}`]: zipCode,
                  }));
                }
              }}
              onBlur={(e: any) => {
                const {
                  target: { value },
                } = e;
                formik.setFieldValue(makeFieldName('ZipCode'), value);
                setAddressInfoState((prevState: any) => ({
                  ...prevState,
                  [`${makeFieldName('ZipCode')}`]: value,
                }));
              }}
              value={addressInfoState[makeFieldName('ZipCode')]}
              className="AddressZipCodeStyle"
              label="Zip Code"
              error={
                formik.touched && formik.errors[makeFieldName('ZipCode')]
                  ? true
                  : false
              }
            />
            {formik.touched && formik.errors[makeFieldName('ZipCode')] && (
              <FormHelperText sx={{ color: '#d32f2f' }}>
                {formik.touched && formik.errors[makeFieldName('ZipCode')]
                  ? formik.errors[makeFieldName('ZipCode')]
                  : ''}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl className="AddressFieldLabelStyle">
            <InputLabel id={makeFieldName('Country')}>Country</InputLabel>
            <Select
              id={makeFieldName('Country')}
              name={makeFieldName('Country')}
              label="Country"
              className="AddressCountryStyle"
              disabled={disabled}
              onChange={(e: any) => {
                const {
                  target: { value },
                } = e;
                formik.setFieldValue(makeFieldName('Country'), value);
                setAddressInfoState((prevState: any) => ({
                  ...prevState,
                  [`${makeFieldName('Country')}`]: value,
                }));
              }}
              onBlur={(e: any) => {
                const {
                  target: { value },
                } = e;
                formik.setFieldValue(makeFieldName('Country'), value);
                setAddressInfoState((prevState: any) => ({
                  ...prevState,
                  [`${makeFieldName('Country')}`]: value,
                }));
              }}
              value={addressInfoState[makeFieldName('Country')]}
              error={
                formik.touched && formik.errors[makeFieldName('Country')]
                  ? true
                  : false
              }
            >
              {CountryOptions.length > 0 &&
                CountryOptions.map((item: any) => {
                  return (
                    <MenuItem key={item.key} value={item.key}>
                      {item.value}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </Box>
      </Box>
      <Box className="MarginTop" display="inline-flex">
        <FormControl className="AddressFieldLabelStyle">
          <TextField
            className="EmailAddressStyle"
            fullWidth
            id={makeFieldName('Email')}
            name={makeFieldName('Email')}
            label="Email Address"
            disabled={disabled}
            value={addressInfoState[makeFieldName('Email')]}
            onChange={(e: any) => {
              const {
                target: { value },
              } = e;
              formik.setFieldValue(makeFieldName('Email'), value);
              setAddressInfoState((prevState: any) => ({
                ...prevState,
                [`${makeFieldName('Email')}`]: value,
              }));
            }}
            onBlur={(e: any) => {
              const {
                target: { value },
              } = e;
              formik.setFieldValue(makeFieldName('Email'), value);
              setAddressInfoState((prevState: any) => ({
                ...prevState,
                [`${makeFieldName('Email')}`]: value,
              }));
            }}
            error={
              formik.touched && formik.errors[makeFieldName('Email')]
                ? true
                : false
            }
          />
          {formik.touched && formik.errors[makeFieldName('Email')] && (
            <FormHelperText sx={{ color: '#d32f2f' }}>
              {formik.touched && formik.errors[makeFieldName('Email')]
                ? formik.errors[makeFieldName('Email')]
                : ''}
            </FormHelperText>
          )}
        </FormControl>
        <FormControl className="AddressFieldLabelStyle">
          <PhoneInput
            specialLabel="Phone Number"
            inputProps={{
              name: makeFieldName('Phone'),
            }}
            country={'us'}
            placeholder={'Phone Number'}
            disabled={disabled}
            countryCodeEditable={true}
            value={phoneNumberValue}
            isValid={!phoneNumberError}
            onChange={handlePhoneChange}
            onBlur={() => {
              validateNumber(phoneNumberValue, addressCountry);
            }}
            inputStyle={{
              borderColor:
                formik.touched &&
                formik.errors[makeFieldName('Phone')] &&
                'red',
              width: '100%',
              height: '95%',
              borderRadius: '10px',
            }}
          />
          {phoneNumberError && (
            <FormHelperText sx={{ color: '#d32f2f' }}>
              {phoneNumberError ? 'Phone Number is not valid' : ''}
            </FormHelperText>
          )}
        </FormControl>
      </Box>
    </Box>
  );
};

export default AddressForm;
