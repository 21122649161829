/**
 * FETCH CLIENT LIST
 */

import { CLIENT_FETCH_LIST_HOST_CONFIGS_PENDING, CLIENT_FETCH_LIST_HOST_CONFIGS_FULFILLED, CLIENT_FETCH_LIST_HOST_CONFIGS_REJECTED, CLIENT_FETCH_LIST_HOST_CONFIGS_RESET } from '../../actions/types';


const initialState = {
  isPending: false,
  isFulfilled: false,
  isRejected: false,
  message: {
    count: 0,
    Results: [],  // very not cute that the API varies the casing of Results vs results.  grrrrr
    results: []
  },
  // Add other initial state properties here
};

const fetchClientHostConfigsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case CLIENT_FETCH_LIST_HOST_CONFIGS_PENDING:
      return {
        ...state,
        isPending: true,
      };
    case CLIENT_FETCH_LIST_HOST_CONFIGS_FULFILLED:
      return {
        ...state,
        ...action.payload,
        isFulfilled: true,
        isPending: false,
      };
    case CLIENT_FETCH_LIST_HOST_CONFIGS_REJECTED:
      return {
        ...state,
        ...action.payload,
        isRejected: true,
        isPending: false,
      };
    case CLIENT_FETCH_LIST_HOST_CONFIGS_RESET:
      return initialState;
    default:
      return state;
  }
};

export default fetchClientHostConfigsReducer;
