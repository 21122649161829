/**
 * Tsys host form
 */
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import {
  AZURE_IMAGE_API,
  FISERV_INDUSTRY_TYPE_OPTIONS
} from '../../../../../store/constants';
import ToggleSwitch from '../../../../Common/ToggleSwitch';
import './FiservHostForm.scss';
import { validationSchema } from './validationSchema';
import { useStore } from 'lnox';
import { ClientHostConfig } from '../../../../../types/api';
import { ClientHostConfigTypes } from '../../../../../store/actions/types';
import { thunkClientFetchConfigHostListAction } from '../../../../../store/actions/clientActions';
import { thunkUpdateFiservHostAction } from '../../../../../store/actions/hostActions';

interface FiservHostFormProps {
  values?: any;
  onConfigChange: (values: any) => void;
  selectedMerchantId: string;
  isDisabled: boolean;
  setIsFiservFormValid?: (value: boolean) => void;
}
const FiservHostForm: React.FC<FiservHostFormProps> = ({
  values = {},
  onConfigChange,
  isDisabled,
  selectedMerchantId,
  setIsFiservFormValid: setIsFiservFormValid,
}) => {


  const {
    dispatch,
    state: {
      client: { clients },
    },
  } = useStore();

  const {
    fetchClientHostConfigs: {
      isPending: isPendingClientHostConfigs,
      isFulfilled: isFulfilledClientHostConfigs,
      message: { ClientHosts },
    }

  } = clients;


  const [fiservHostFormValues, setFiservHostFormValues] = useState<any>(values);
  const [disabled, setDisabled] = useState<boolean>(isDisabled);


  const [confirmDialog, setConfirmDialog] = useState<any>(null);

  const confirmMessage = (message: string, handleClose: ((event: {}) => void) | undefined) => {

    setConfirmDialog(<Dialog
      open={true}
      onClose={(event) => {
        handleClose && handleClose(event);
        setConfirmDialog(null);
      }}

      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Please Confirm this action."}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={(event) => {
          setConfirmDialog(null);
        }}>Cancel</Button>
        <Button onClick={(event) => {
          handleClose && handleClose(event);
          setConfirmDialog(null);
        }} autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>);

  }

  const formik: any = useFormik({
    initialValues: {},
    validationSchema: validationSchema,
    onSubmit: (fromValues: any) => { },
    validateOnChange: true,
  });

  const submitFormValues = () => {
    if (!fiservHostFormValues.IsChanged) {
      setFiservHostFormValues((prevState: any) => ({
        ...prevState,
        IsChanged: true,
      }));
    }
    onConfigChange && onConfigChange(fiservHostFormValues);
    setIsFiservFormValid &&
      setIsFiservFormValid(
        formik.errors && Object.keys(formik.errors).length ? false : true
      );
  };


  return (
    <Box className="HostContainerStyle">
      {confirmDialog}

      <Grid container>
        <Grid item xs={8}>
          <img
            alt={`${AZURE_IMAGE_API}/hosts/fiserv.png`}
            src={`${AZURE_IMAGE_API}/hosts/fiserv.png`}
            className="HostImageStyle"
          />

        </Grid>
        <Grid xs={4}>

          {Object.keys(values).length > 0 &&
            <div style={{ width: 180, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
              <Button variant="outlined"
                className="SideRailfooterButtonStyle"
                onClick={() => {
                  setDisabled(!disabled);
                }}>{disabled ? "Edit" : "Cancel"}</Button>

              {!disabled && (<Button variant="outlined"
                className="SideRailfooterButtonStyle"
                onClick={() => {
                  setDisabled(true);

                  confirmMessage("Your about to save this Host Configuration (Fiserv).  Please confirm to do this.", () => {
                    new Promise((resolve, reject) => {
                      try {

                        let configData = ClientHosts.find((clientHost: ClientHostConfig) => {
                          return [ClientHostConfigTypes.CLIENT_HOST_ID_FISERV_NASHVILLE,
                          ClientHostConfigTypes.CLIENT_HOST_ID_FISERV_NORTH,
                          ClientHostConfigTypes.CLIENT_HOST_ID_FISERV_OMAHA,
                          ].includes(clientHost.HostId as number);
                        });

                        let hostConfig = {
                          FiservHostConfigId: (configData && configData.HostConfigId) ? configData.HostConfigId : '',
                          Region: '',
                          HostConfigFiserv: values,
                          IsActive: values?.IsActive == undefined || values?.IsActive == null
                            ? true
                            : values?.IsActive,
                          IsChanged: values?.IsChanged,
                        };

                        dispatch(thunkUpdateFiservHostAction(hostConfig)).then(() => {
                          try {
                            dispatch(thunkClientFetchConfigHostListAction(fiservHostFormValues.ClientId));
                            resolve({ value: true });
                          } catch (error) {
                            reject({ value: false, error: error });
                          }
                        });
                      } catch (error) {
                        reject({ value: false, error: error });
                      }

                    });
                  });

                }}>Save</Button>)}

              {disabled && (<Button variant="outlined"
                className="SideRailfooterButtonStyle"
                onClick={() => {
                  setDisabled(true);
                  confirmMessage("Your about to delete this Host Configuration (Fiserv).  Please confirm to do this.", () => {

                    new Promise((resolve, reject) => {
                      try {
                        dispatch(thunkClientFetchConfigHostListAction(fiservHostFormValues.ClientId));
                        resolve({ value: true });
                      } catch (error) {
                        reject({ value: false, error: error });
                      }

                    });

                  });
                }}>Delete</Button>)}
            </div>}

        </Grid>


      </Grid>

      <Typography className="TitleStyle">General</Typography>

      <Box className="StatusBoxStyle">
        <Typography fontWeight="bold">Status</Typography>
        <ToggleSwitch
          isChecked={true}
          disabled={disabled}
          handleOnChange={(value: any) => {
            setFiservHostFormValues((prevState: any) => ({
              ...prevState,
              IsActive: value,
            }));
            submitFormValues();
          }}
        ></ToggleSwitch>
      </Box>

      <Box className="MarginTop" display="inline-flex">
        <FormControl className="FormFieldLabelStyle">
          <TextField
            id={'MId'}
            name={'MId'}
            label="Mechant ID"
            disabled={true}
            className="LeftControlStyle"
            value={fiservHostFormValues['MId']}
            onChange={(e: any) => {
              const {
                target: { value },
              } = e;
              formik.setFieldValue('MId', value);
              setFiservHostFormValues((prevState: any) => ({
                ...prevState,
                MId: value,
              }));
            }}
            onBlur={(e: any) => {
              formik.setFieldTouched('MId');
              submitFormValues();
            }}
            error={formik.touched?.MId && formik.errors?.MId ? true : false}
            helperText={
              formik.touched?.MId && formik.errors['MId']
                ? formik.errors['Mid']
                : ''
            }
          />
        </FormControl>

      </Box>



      <Box className="MarginTop" display="inline-flex">

        <FormControl className="FormFieldLabelStyle">
          <TextField
            id={'Name'}
            name={'Name'}
            label="Name"
            disabled={disabled}
            className="LeftControlStyle"
            value={fiservHostFormValues['Name']}
            onChange={(e: any) => {
              const {
                target: { value },
              } = e;
              formik.setFieldValue('Name', value);
              setFiservHostFormValues((prevState: any) => ({
                ...prevState,
                Name: value,
              }));
            }}
            onBlur={(e: any) => {
              submitFormValues();
              formik.setFieldTouched('Name');
            }}
            error={formik.touched.MCC && formik.errors['Name'] ? true : false}
            helperText={
              formik.touched?.MCC && formik.errors['Name']
                ? formik.errors['Name']
                : ''
            }
          />
        </FormControl>

        <FormControl className="FormFieldLabelStyle">
          <TextField
            id={'GroupId'}
            name={'GroupId'}
            label="GroupId"
            disabled={disabled}
            className="RightControlStyle"
            value={fiservHostFormValues['GroupId']}
            onChange={(e: any) => {
              const {
                target: { value },
              } = e;
              formik.setFieldValue('GroupId', value);
              setFiservHostFormValues((prevState: any) => ({
                ...prevState,
                GroupId: value,
              }));
            }}
            onBlur={(e: any) => {
              formik.setFieldTouched('GroupId');
              submitFormValues();
            }}
            error={formik.touched?.GroupId && formik.errors['GroupId'] ? true : false}
            helperText={
              formik.touched?.GroupId && formik.errors['GroupId']
                ? formik.errors['GroupId']
                : ''
            }
          />
        </FormControl>
      </Box>


      <Box className="MarginTop" display="inline-flex">
        <FormControl className="FormFieldLabelStyle">
          <TextField
            id={'Mcc'}
            name={'Mcc'}
            label="Mcc"
            disabled={disabled}
            className="LeftControlStyle"
            value={fiservHostFormValues['Mcc']}
            onChange={(e: any) => {
              const {
                target: { value },
              } = e;
              formik.setFieldValue('Mcc', value);
              setFiservHostFormValues((prevState: any) => ({
                ...prevState,
                Mcc: value,
              }));
            }}
            onBlur={(e: any) => {
              submitFormValues();
              formik.setFieldTouched('Mcc');
            }}
            error={
              formik.touched?.Association && formik.errors['Mcc']
                ? true
                : false
            }
            helperText={
              formik.touched?.Association && formik.errors['Mcc']
                ? formik.errors['Mcc']
                : ''
            }
          />
        </FormControl>

        <FormControl className="FormFieldLabelStyle">
          <TextField
            id={'URl'}
            name={'URl'}
            label="URl"
            disabled={disabled}
            className="RightControlStyle"
            value={fiservHostFormValues['URl']}
            onChange={(e: any) => {
              const {
                target: { value },
              } = e;
              formik.setFieldValue('URl', value);
              setFiservHostFormValues((prevState: any) => ({
                ...prevState,
                URl: value,
              }));
            }}
            onBlur={(e: any) => {
              submitFormValues();
              formik.setFieldTouched('URl');
            }}
            error={formik.touched?.URl && formik.errors['URl'] ? true : false}
            helperText={
              formik.touched?.URL && formik.errors['URl']
                ? formik.errors['URl']
                : ''
            }
          />
        </FormControl>
      </Box>


    </Box>
  );
};

export default FiservHostForm;
