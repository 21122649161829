import typeToReducer from 'type-to-reducer';

import { DEFAULT_ASYNC_STATE } from '../../constants';
import { CLIENT_CREATE_TOKENIZATION_HOST, CLIENT_UPDATE_TOKENIZATION_HOST } from '../../actions/types';
import { ReducerAction, ReducerState } from '../../../types/common';

const DEFAULT_STATE = {
  ...DEFAULT_ASYNC_STATE,
  error: false,
  data: {},
};

const commonHandlers = {
  PENDING: () => ({
    ...DEFAULT_STATE,
    isPending: true,
  }),

  FULFILLED: (
    state: ReducerState | undefined,
    action: ReducerAction | any
  ) => {
    const { payload } = action;

    return {
      ...state,
      ...DEFAULT_STATE,
      ...payload,
      isFulfilled: true,
      isPending: false,
    };
  },

  REJECTED: (
    state: ReducerState | undefined,
    action: ReducerAction | any
  ) => {
    const { payload } = action;
    return {
      ...state,
      ...DEFAULT_STATE,
      ...payload,
      isRejected: true,
      isPending: false,
    };
  },
};

export default typeToReducer(
  {
    [CLIENT_CREATE_TOKENIZATION_HOST]: { ...commonHandlers },
    [CLIENT_UPDATE_TOKENIZATION_HOST]: { ...commonHandlers },
  },
  DEFAULT_STATE
);
