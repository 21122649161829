import typeToReducer from 'type-to-reducer';

import {
    VIRTUAL_TERMINAL_TAKE,
    VIRTUAL_TERMINAL_RESET_TAKE,
} from '../../actions/types';
import { DEFAULT_ASYNC_STATE } from '../../constants';
import { ReducerAction, ReducerState } from '../../../types/common';

const DEFAULT_STATE = {
    ...DEFAULT_ASYNC_STATE,
    data: {},
};

export default typeToReducer(
    {
        [VIRTUAL_TERMINAL_TAKE]: {
            PENDING: () => ({ ...DEFAULT_STATE, isPending: true }),
            FULFILLED: (
                state: ReducerState | undefined,
                action: ReducerAction | any
            ) => {
                const { payload } = action;
                return { ...state, ...payload, isFulfilled: true, isPending: false };
            },
            REJECTED: (
                state: ReducerState | undefined,
                action: ReducerAction | any
            ) => {
                const { payload } = action;
                return { ...state, ...payload, isRejected: true, isPending: false };
            },
        },
        [VIRTUAL_TERMINAL_RESET_TAKE]: () => DEFAULT_STATE,
    },
    DEFAULT_STATE
);