/**
 * Merchant Host tab
 */
import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  CircularProgress,
  Typography,
} from '@mui/material';
import { useStore } from 'lnox';
import React, { useEffect, useState } from 'react';
import {
  AZURE_IMAGE_API,
  VGS_HOST_DESCRIPTION,
  TSYS_HOST_DESCRIPTION,
  FISERV_HOST_DESCRIPTION,
  SHAZAM_HOST_DESCRIPTION,
  WORLDPAY_HOST_DESCRIPTION
} from '../../../store/constants';

import { thunkClientFetchConfigHostListAction } from "../../../store/actions/clientActions";
import WorldpayHostForm from './Hosts/WorldpayHostForm';
import FiservHostForm from './Hosts/FiservHostForm';
import ShazamHostForm from './Hosts/ShazamHostForm';
import TokenizationHostForm from './Hosts/TokenizationHostForm';
import TsysHostForm from './Hosts/TsysHostForm';
import './MerchantHostTab.scss';
import { ClientHostConfig } from '../../../types/api';
import { ClientHostConfigTypes } from '../../../store/actions/types';

interface MerchantHostTabProps {
  addNewHost: boolean;
  newHostSelected: boolean;
  isCancelClicked: boolean;
  onConfigChange: (values: any) => void;
  selectedMerchantId: string;
  isDisabled: boolean;
  setIsFiservFormValid?: (value: boolean) => void;
  setIsTokenizationFormValid?: (value: boolean) => void;
  setIsTsysFormValid?: (value: boolean) => void;
  setIsShazamFormValid?: (value: boolean) => void;
}
const MerchantHostTab: React.FC<MerchantHostTabProps> = ({
  addNewHost,
  newHostSelected,
  isCancelClicked,
  onConfigChange,
  selectedMerchantId,
  isDisabled = false,
  setIsFiservFormValid,
  setIsTokenizationFormValid,
  setIsTsysFormValid,
  setIsShazamFormValid,
}) => {
  const noHostSelected = {
    isVgsSelected: false,
    isTsysSelected: false,
    isFiservSelected: false,
    isShazamSelected: false,
    isWorldpaySelected: false,
  };
  const [selectedNewHosts, setSelectedNewHosts] = useState<any>(noHostSelected);
  const [hostConfig, setHostConfig] = useState<any>({});

  const {
    dispatch,
    state: {
      client: { clients },
    },
  } = useStore();

  const {
    fetchClientApiKey: {
      isPending: isPendingApiKey,
      isFulfilled: isFullfilledApiKey,
      message: { ApiKey },
    },
    fetchClientHostConfigs: {
      isPending: isPendingClientHostConfigs,
      isFulfilled: isFulfilledClientHostConfigs,
      message: { ClientHosts },
    }

  } = clients;






  useEffect(() => {
    if (isCancelClicked) {
      setSelectedNewHosts(noHostSelected);
    }
  }, [isCancelClicked]);

  useEffect(() => {
    dispatch(thunkClientFetchConfigHostListAction(selectedMerchantId));

  }, []);



  const renderAddNewHostWizard = () => {
    return (
      <Box className="HostContainer">
        {ClientHosts && !ClientHosts.find((clientHost: ClientHostConfig) => {
          return clientHost.HostId === ClientHostConfigTypes.CLIENT_HOST_ID_VGS
        }) === undefined && <Accordion
          sx={{
            '&:Before': {
              display: 'none',
            },
          }}
          className="AccordionStyle"
          defaultExpanded={true}
        >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography fontWeight="bold">Tokenization VGS</Typography>
            </AccordionSummary>

            <AccordionDetails className="AccordionDetailsStyle">
              <Box className="HostCardStyle">
                <Box display="inline-flex">
                  <Box className="HostCheckBoxStyle">
                    <Checkbox
                      onChange={(event: any) => {
                        setSelectedNewHosts((prevState: any) => ({
                          ...prevState,
                          isVgsSelected: event.target.checked,
                        }));
                      }}
                    ></Checkbox>
                  </Box>
                  <Box className="HostImageBoxStyle">
                    <img
                      alt={`${AZURE_IMAGE_API}/hosts/vgs.png`}
                      src={`${AZURE_IMAGE_API}/hosts/vgs.png`}
                      className="HostImageStyle"
                    />
                  </Box>
                  <Box>
                    <Typography>{VGS_HOST_DESCRIPTION}</Typography>
                  </Box>
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>}
        {ClientHosts &&
          !ClientHosts.find((clientHost: ClientHostConfig) => { return clientHost.HostId === ClientHostConfigTypes.CLIENT_HOST_ID_TSYS }) && <Accordion
            sx={{
              '&:Before': {
                display: 'none',
              },
            }}
            className="AccordionStyle"
            defaultExpanded={true}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography fontWeight="bold">Credit Processor Tsys</Typography>
            </AccordionSummary>
            <AccordionDetails className="AccordionDetailsStyle">
              <Box className="HostCardStyle">
                <Box display="inline-flex" className="HostPanelBoxStyle">
                  <Box className="HostCheckBoxStyle">
                    <Checkbox
                      onChange={(event: any) => {
                        setSelectedNewHosts((prevState: any) => ({
                          ...prevState,
                          isTsysSelected: event.target.checked,
                        }));
                      }}
                    ></Checkbox>
                  </Box>
                  <Box className="HostImageBoxStyle">
                    <img
                      alt={`${AZURE_IMAGE_API}/hosts/tsys.png`}
                      src={`${AZURE_IMAGE_API}/hosts/tsys.png`}
                      className="HostImageStyle"
                    />
                  </Box>
                  <Box>
                    <Typography>{TSYS_HOST_DESCRIPTION}</Typography>
                  </Box>
                </Box>

              </Box>
            </AccordionDetails>
          </Accordion>}


        {ClientHosts && ! ClientHosts.find((clientHost: ClientHostConfig) => {
            return [ClientHostConfigTypes.CLIENT_HOST_ID_FISERV_NASHVILLE,
            ClientHostConfigTypes.CLIENT_HOST_ID_FISERV_NORTH,
            ClientHostConfigTypes.CLIENT_HOST_ID_FISERV_OMAHA].includes(clientHost.HostId as number);
          }) && <Accordion
            sx={{
              '&:Before': {
                display: 'none',
              },
            }}
            className="AccordionStyle"
            defaultExpanded={true}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography fontWeight="bold">Credit Processor Fiserv</Typography>
            </AccordionSummary>
            <AccordionDetails className="AccordionDetailsStyle">
              <Box className="HostCardStyle">
                <Box display="inline-flex" className="HostPanelBoxStyle">
                  <Box className="HostCheckBoxStyle">
                    <Checkbox
                      onChange={(event: any) => {
                        setSelectedNewHosts((prevState: any) => ({
                          ...prevState,
                          isFiservSelected: event.target.checked,
                        }));
                      }}
                    ></Checkbox>
                  </Box>
                  <Box className="HostImageBoxStyle">
                    <img
                      style={{ marginLeft: 0 }}
                      alt={`${AZURE_IMAGE_API}/hosts/fiserv.png`}
                      src={`${AZURE_IMAGE_API}/hosts/fiserv.png`}
                      className="HostImageStyle"
                    />
                  </Box>
                  <Box>
                    <Typography>{FISERV_HOST_DESCRIPTION}</Typography>
                  </Box>
                </Box>
              </Box>

            </AccordionDetails>
          </Accordion>}


        {ClientHosts &&
          !ClientHosts.find((clientHost: ClientHostConfig) => { return clientHost.HostId === ClientHostConfigTypes.CLIENT_HOST_ID_WORLDPAY }) &&
          <Accordion
            sx={{
              '&:Before': {
                display: 'none',
              },
            }}
            className="AccordionStyle"
            defaultExpanded={true}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography fontWeight="bold">Credit Processor Worldpay</Typography>
            </AccordionSummary>
            <AccordionDetails className="AccordionDetailsStyle">
              <Box display="inline-flex" className="HostPanelBoxStyle">
                <Box className="HostCheckBoxStyle">
                  <Checkbox
                    onChange={(event: any) => {
                      setSelectedNewHosts((prevState: any) => ({
                        ...prevState,
                        isWorldpaySelected: event.target.checked,
                      }));
                    }}
                    disabled={true}
                  ></Checkbox>
                </Box>
                <Box className="HostImageBoxStyle">
                  <img
                    alt={`/logos/worldpay.png`}
                    src={`/logos/worldpay.png`}
                    className="HostImageStyle"
                  />
                </Box>
                <Box>
                  <Typography>{WORLDPAY_HOST_DESCRIPTION}</Typography>
                </Box>
              </Box>

            </AccordionDetails>
          </Accordion>}


        {
          !ClientHosts.find((clientHost: ClientHostConfig) => { return clientHost.HostId === ClientHostConfigTypes.CLIENT_HOST_ID_SHAZAM }) && <Accordion
            sx={{
              '&:Before': {
                display: 'none',
              },
            }}
            className="AccordionStyle"
            defaultExpanded={true}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography fontWeight="bold">Debit Processor Shazam</Typography>
            </AccordionSummary>
            <AccordionDetails className="AccordionDetailsStyle">
              <Box className="HostCardStyle">
                <Box display="inline-flex">
                  <Box className="HostCheckBoxStyle">
                    <Checkbox
                      onChange={(event: any) => {
                        setSelectedNewHosts((prevState: any) => ({
                          ...prevState,
                          isShazamSelected: event.target.checked,
                        }));
                      }}
                    ></Checkbox>
                  </Box>
                  <Box className="HostImageBoxStyle">
                    <img
                      alt={`${AZURE_IMAGE_API}/hosts/shazam.png`}
                      src={`${AZURE_IMAGE_API}/hosts/shazam.png`}
                      className="ShazamHostImageStyle"
                    />
                  </Box>
                  <Box>
                    <Typography>{SHAZAM_HOST_DESCRIPTION}</Typography>
                  </Box>
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        }

        {
          ClientHosts &&
          ClientHosts.find((clientHost: ClientHostConfig) => { return clientHost.HostId === ClientHostConfigTypes.CLIENT_HOST_ID_VGS }) &&
          ClientHosts.find((clientHost: ClientHostConfig) => { return clientHost.HostId === ClientHostConfigTypes.CLIENT_HOST_ID_SHAZAM }) &&
          ClientHosts.find((clientHost: ClientHostConfig) => { return clientHost.HostId === ClientHostConfigTypes.CLIENT_HOST_ID_TSYS }) &&
          (ClientHosts.find((clientHost: ClientHostConfig) => { return clientHost.HostId === ClientHostConfigTypes.CLIENT_HOST_ID_FISERV_NASHVILLE }) ||
            ClientHosts.find((clientHost: ClientHostConfig) => { return clientHost.HostId === ClientHostConfigTypes.CLIENT_HOST_ID_FISERV_NORTH }) ||
            ClientHosts.find((clientHost: ClientHostConfig) => { return clientHost.HostId === ClientHostConfigTypes.CLIENT_HOST_ID_FISERV_OMAHA })) &&
          ClientHosts.find((clientHost: ClientHostConfig) => { return clientHost.HostId === ClientHostConfigTypes.CLIENT_HOST_ID_WORLDPAY }) &&
          <Typography fontWeight="bold">You've already configured all the possible host configration combinations.  Please edit an existing host config or delete to add more host config data.</Typography>
        }

      </Box >
    );
  };

  const renderHostViewDetails = () => {

    if (isPendingClientHostConfigs === true) {
      return <Box className="NoHostContainer">
        <CircularProgress />
      </Box>
    }
    return !ClientHosts || ClientHosts.length < 1 ? (
      <Box className="NoHostContainer">
        No Host Configured for the merchant
      </Box>
    ) : (
      <Box className="HostContainer">

        {ClientHosts && ClientHosts.find((clientHost: ClientHostConfig) => {
          return clientHost.HostId === ClientHostConfigTypes.CLIENT_HOST_ID_VGS
        }) && <Accordion
          sx={{
            '&:Before': {
              display: 'none',
            },
          }}
          className="AccordionStyle"
          defaultExpanded={true}
        >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography fontWeight="bold">Tokenization VGS</Typography>
            </AccordionSummary>
            <AccordionDetails className="AccordionDetailsStyle">
              <Box className="HostCardStyle">
                <Box display="inline-flex">
                  <TokenizationHostForm
                    isDisabled={true}
                    selectedMerchantId={selectedMerchantId}
                    values={(() => {
                      let value = ClientHosts.find((clientHost: ClientHostConfig) => {
                        return clientHost.HostId === ClientHostConfigTypes.CLIENT_HOST_ID_VGS;
                      });
                      let configData = value.ConfigData;
                      return {
                        ...configData,
                        ClinetId: selectedMerchantId
                      };
                    })()}

                    onConfigChange={(values: any) => {
                      let hostConfig = {
                        ...values,
                        Tokenization: {
                          ClientId: selectedMerchantId,
                          VgsHostConfigId: '',
                          hostConfigVgs: values,
                          IsActive: values?.IsActive == undefined || values?.IsActive == null
                            ? true
                            : values?.IsActive,
                          IsChanged: values?.IsChanged,
                        },
                      };
                      setHostConfig(hostConfig);
                      onConfigChange && onConfigChange(hostConfig);
                    }}

                    setIsTokenizationFormValid={(value) => {
                      setIsTokenizationFormValid && setIsTokenizationFormValid(value);
                    }} />
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        }


        {ClientHosts && ClientHosts.find((clientHost: ClientHostConfig) => {
          return [ClientHostConfigTypes.CLIENT_HOST_ID_TSYS].includes(clientHost.HostId as number);
        }) && <Accordion
          sx={{
            '&:Before': {
              display: 'none',
            },
          }}
          className="AccordionStyle"
          defaultExpanded={true}
        >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography fontWeight="bold">Credit Processor Tsys</Typography>
            </AccordionSummary>
            <AccordionDetails className="AccordionDetailsStyle">
              <Box className="HostCardStyle">

                <Box display="inline-flex">
                  <TsysHostForm
                    isDisabled={true}
                    selectedMerchantId={selectedMerchantId}
                    values={ClientHosts.find((clientHost: ClientHostConfig) => {
                      return [ClientHostConfigTypes.CLIENT_HOST_ID_TSYS].includes(clientHost.HostId as number);
                    }).ConfigData}

                    onConfigChange={(values: any) => {
                      values.PhoneNumber =
                        values?.DescriptorPhone?.length > 10
                          ? values?.DescriptorPhone?.toString()?.slice(1)
                          : values?.DescriptorPhone;
                      values.DescriptorPhone = values?.PhoneNumber;
                      values.DescriptorCountry = '840';

                      let hostConfig = {
                        ...values,
                        Tsys: {
                          ClientId: selectedMerchantId,
                          TsysHostConfigId: '',
                          TsysHostDetails: values,
                          IsActive: values?.IsActive == undefined || values?.IsActive == null
                            ? true
                            : values?.IsActive,
                          IsChanged: values?.IsChanged,
                        },
                      };
                      setHostConfig(hostConfig);
                      onConfigChange && onConfigChange(hostConfig);
                    }}

                  ></TsysHostForm>

                </Box>

              </Box>
            </AccordionDetails>
          </Accordion>}


        {ClientHosts && ClientHosts.find((clientHost: ClientHostConfig) => {
          return [ClientHostConfigTypes.CLIENT_HOST_ID_FISERV_NASHVILLE,
          ClientHostConfigTypes.CLIENT_HOST_ID_FISERV_NORTH,
          ClientHostConfigTypes.CLIENT_HOST_ID_FISERV_OMAHA].includes(clientHost.HostId as number);
        }) && <Accordion
          sx={{
            '&:Before': {
              display: 'none',
            },
          }}
          className="AccordionStyle"
          defaultExpanded={true}
        >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography fontWeight="bold">Credit Processor Fiserv</Typography>
            </AccordionSummary>
            <AccordionDetails className="AccordionDetailsStyle">
              <Box className="HostCardStyle">


                <Box display="inline-flex">
                  <FiservHostForm
                    isDisabled={true}
                    selectedMerchantId={selectedMerchantId}
                    values={ { ...ClientHosts.find((clientHost: ClientHostConfig) => {
                      return [ClientHostConfigTypes.CLIENT_HOST_ID_FISERV_NASHVILLE,
                      ClientHostConfigTypes.CLIENT_HOST_ID_FISERV_NORTH,
                      ClientHostConfigTypes.CLIENT_HOST_ID_FISERV_OMAHA].includes(clientHost.HostId as number);
                    }).ConfigData,  MId: selectedMerchantId}}

                    onConfigChange={(values: any) => {
                      let hostConfig = {
                        ...values,
                        Fiserv: {
                          ClientId: selectedMerchantId,
                          FiservHostConfigId: '',
                          FiservHostDetails: values,
                          IsActive: values?.IsActive == undefined || values?.IsActive == null
                            ? true
                            : values?.IsActive,
                          IsChanged: values?.IsChanged,
                        },
                      };
                      setHostConfig(hostConfig);
                      onConfigChange && onConfigChange(hostConfig);
                    }}

                  ></FiservHostForm>

                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>}


        {ClientHosts && ClientHosts.find((clientHost: ClientHostConfig) => {
          return [ClientHostConfigTypes.CLIENT_HOST_ID_WORLDPAY].includes(clientHost.HostId as number);
        }) && <Accordion
          sx={{
            '&:Before': {
              display: 'none',
            },
          }}
          className="AccordionStyle"
          defaultExpanded={true}
        >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography fontWeight="bold">Credit Processor Worldpay</Typography>
            </AccordionSummary>
            <AccordionDetails className="AccordionDetailsStyle">
              <Box className="HostCardStyle">


                <WorldpayHostForm
                  selectedMerchantId={selectedMerchantId}
                  values={ClientHosts.find((clientHost: ClientHostConfig) => {
                    return ClientHostConfigTypes.CLIENT_HOST_ID_WORLDPAY === clientHost.HostId;
                  }).ConfigData}
                  onConfigChange={(values: any) => {
                    setHostConfig((prevState: any) => ({
                      ...prevState,
                      fiserv: {
                        ClientId: selectedMerchantId,
                        fiservHostConfigId: '',
                        fiservHostDetails: values,
                        IsActive:
                          values?.IsActive == undefined || values?.IsActive == null
                            ? true
                            : values?.IsActive,
                        IsChanged: values?.IsChanged,
                      },
                    }));
                    onConfigChange && onConfigChange(hostConfig);
                  }}
                  isDisabled={true}
                ></WorldpayHostForm>

              </Box>
            </AccordionDetails>
          </Accordion>}



        {ClientHosts && ClientHosts.find((clientHost: ClientHostConfig) => {
          return clientHost.HostId === ClientHostConfigTypes.CLIENT_HOST_ID_SHAZAM
        }) &&
          <Accordion
            sx={{
              '&:Before': {
                display: 'none',
              },
            }}
            className="AccordionStyle"
            defaultExpanded={true}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography fontWeight="bold">Debit Processor Shazam</Typography>
            </AccordionSummary>
            <AccordionDetails className="AccordionDetailsStyle">
              <Box className="HostCardStyle">
                <Box display="inline-flex">
                  <ShazamHostForm
                    selectedMerchantId={selectedMerchantId}
                    values={ClientHosts.find((clientHost: ClientHostConfig) => {
                      return [ClientHostConfigTypes.CLIENT_HOST_ID_SHAZAM].includes(clientHost.HostId as number);
                    }).ConfigData}
                    onConfigChange={(values: any) => {
                      setHostConfig((prevState: any) => ({
                        ...prevState,
                        Shazam: {
                          ClientId: selectedMerchantId,
                          shazamHostConfigId: '',
                          ShazamHostDetails: values,
                          IsActive:
                            values?.IsActive == undefined || values?.IsActive == null
                              ? true
                              : values?.IsActive,
                          IsChanged: values?.IsChanged,
                        },
                      }));
                      onConfigChange && onConfigChange(hostConfig);
                    }}
                    isDisabled={true}
                    setIsShazamFormValid={(value) => {
                      setIsShazamFormValid && setIsShazamFormValid(value);
                    }}
                  ></ShazamHostForm>
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>}
      </Box>
    );
  };

  const renderAddHostDetails = () => {
    return (
      <Box>
        {selectedNewHosts.isVgsSelected && (
          <TokenizationHostForm
            selectedMerchantId={selectedMerchantId}
            values={{}}
            onConfigChange={(values: any) => {
              let configData = ClientHosts.find((clientHost: ClientHostConfig) => {
                return [ClientHostConfigTypes.CLIENT_HOST_ID_VGS].includes(clientHost.HostId as number);
              }).ConfigData;

              let hostConfig = {
                ...values,
                ...configData,
                Tokenization: {
                  ClientId: selectedMerchantId,
                  VgsHostConfigId: (configData && configData.HostConfigId) ? configData.HostConfigId : '',
                  hostConfigVgs: values,
                  IsActive: values?.IsActive == undefined || values?.IsActive == null
                    ? true
                    : values?.IsActive,
                  IsChanged: values?.IsChanged,
                },
              };
              setHostConfig(hostConfig);
              onConfigChange && onConfigChange(hostConfig);
            }}

            isDisabled={isDisabled}
            setIsTokenizationFormValid={(value) => {
              setIsTokenizationFormValid && setIsTokenizationFormValid(value);
            }}
          ></TokenizationHostForm>
        )}
        {selectedNewHosts.isTsysSelected && (
          <TsysHostForm
            selectedMerchantId={selectedMerchantId}
            values={{}}
            onConfigChange={(values: any) => {
              values.PhoneNumber =
                values?.DescriptorPhone?.length > 10
                  ? values?.DescriptorPhone?.toString()?.slice(1)
                  : values?.DescriptorPhone;
              values.DescriptorPhone = values?.PhoneNumber;
              values.DescriptorCountry = '840';

              let configData = ClientHosts.find((clientHost: ClientHostConfig) => {
                return [ClientHostConfigTypes.CLIENT_HOST_ID_TSYS].includes(clientHost.HostId as number);
              });

              let hostConfig = {
                ...values,
                ...configData,
                Tsys: {
                  ClientId: selectedMerchantId,
                  TsysHostConfigId: (configData && configData.HostConfigId) ? configData.HostConfigId : '',
                  TsysHostDetails: values,
                  IsActive: values?.IsActive == undefined || values?.IsActive == null
                    ? true
                    : values?.IsActive,
                  IsChanged: values?.IsChanged,
                },
              };
              setHostConfig(hostConfig);
              onConfigChange && onConfigChange(hostConfig);
            }}
            isDisabled={isDisabled}
            setIsTsysFormValid={(value: any) => {
              setIsTsysFormValid && setIsTsysFormValid(value);
            }}
          ></TsysHostForm>
        )}

        {selectedNewHosts.isFiservSelected && (
          <FiservHostForm
            selectedMerchantId={selectedMerchantId}
            values={{ MId: selectedMerchantId}}
            onConfigChange={(values: any) => {
              let hostConfig = {
                Fiserv: {
                  FiservHostConfigId: '',
                  Region: '',
                  HostConfigFiserv : values,
                  IsActive: values?.IsActive == undefined || values?.IsActive == null
                    ? true
                    : values?.IsActive,
                  IsChanged: values?.IsChanged,
                },
              };

              setHostConfig(hostConfig);
              onConfigChange && onConfigChange(hostConfig);
            }}
            isDisabled={isDisabled}
            setIsFiservFormValid={(value: any) => {
              setIsFiservFormValid && setIsFiservFormValid(value);
            }}
          ></FiservHostForm>
        )}

        {selectedNewHosts.isShazamSelected && (
          <ShazamHostForm
            selectedMerchantId={selectedMerchantId}
            values={{}}
            onConfigChange={(values: any) => {
              setHostConfig((prevState: any) => ({
                ...prevState,
                Shazam: {
                  ClientId: selectedMerchantId,
                  shazamHostConfigId: '',
                  ShazamHostDetails: values,
                  IsActive:
                    values?.IsActive == undefined || values?.IsActive == null
                      ? true
                      : values?.IsActive,
                  IsChanged: values?.IsChanged,
                },
              }));
              onConfigChange && onConfigChange(hostConfig);
            }}
            isDisabled={isDisabled}
            setIsShazamFormValid={(value) => {
              setIsShazamFormValid && setIsShazamFormValid(value);
            }}
          ></ShazamHostForm>
        )}
      </Box>
    );
  };

  return (
    <Box>
      {addNewHost && !newHostSelected && renderAddNewHostWizard()}
      {!addNewHost && renderHostViewDetails()}
      {newHostSelected && addNewHost && renderAddHostDetails()}
    </Box>
  );
};

export default MerchantHostTab;
