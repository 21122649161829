import { Box, Button, CircularProgress, Link, Typography } from '@mui/material';
import { useStore } from 'lnox';
import React, { useEffect, useState } from 'react';
import {
  thunkSendVerificationOTP,
  thunkVerifyOTPCodeAction,
} from '../../../store/actions/authActions';
import OTPInput from '../../OTPInput';
import './AuthVerification.scss';

interface AuthVerificationFormProps {
  userEmail: string;
  userPhoneNumber: string;
  onBackToLogin: (event: any) => void;
}

const AuthVerificationForm: React.FC<AuthVerificationFormProps> = ({
  userEmail,
  userPhoneNumber,
  onBackToLogin
}) => {
  const { dispatch, state: { app: { statusMessages = [] } = {} } = {} } =
    useStore();
  const [otp, setOtp] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    if (statusMessages) {
      statusMessages.map((message: any, index: number) => {
        return setErrorMessage(message.text);
      });
    }
  }, [statusMessages]);

  let ismobileinterface = false;
  userPhoneNumber = '+' + userPhoneNumber;
  let userid = userEmail || userPhoneNumber;
  let maskedEmail = '';
  if (userEmail) {
    let firstChar = userid[0];
    let regex = /[A-Z,a-z,0-9]/gi;
    let encryptedEmail = userid?.split('@')[0]?.replaceAll(regex, '*');
    maskedEmail = `${firstChar}${encryptedEmail?.slice(1)}@${userid?.split('@')[1]
      }`;
  } else {
    maskedEmail =
      userPhoneNumber &&
      `${userPhoneNumber?.slice(0, 2)} (***) ***-${userPhoneNumber?.slice(
        8,
        12
      )}`;
  }

  const handleOnResend = async () => {
    setIsSubmitting(true);

    dispatch(thunkSendVerificationOTP(userEmail || userPhoneNumber)).finally(() => {
      setIsSubmitting(false);
    });

  };

  const renderOtpResend = () => (
    <Box>
      <Box
        mt={1}
        sx={{
          display: 'flex',
          flex: 'grow',
          gap: '3px',
          alignItems: 'center',
          flexDirection: 'row',
          bgcolor: 'background.paper',
        }}
      >
        <Typography fontSize={'12px'}>Didn't receive a code?</Typography>
        <Link
          className="Link"
          underline="hover"
          href="#"
          onClick={handleOnResend}
        >
          Resend
        </Link>
      </Box>
    </Box>
  );

  const renderPasswordBox = () => (
    <div className="PasswordBox">
      <Button
        color="primary"
        sx={{
          padding: '16px',
          marginTop: errorMessage ? '40px' : '30px',
          fontSize: '18px',
          fontWeight: 600,
        }}
        disabled={isSubmitting}
        variant="contained"
        fullWidth
        onClick={handleVerifyOtp}
      >
        {(isSubmitting) ?
          <CircularProgress size={26}></CircularProgress>
          : errorMessage ?
            'Try Again'
            :
            'VERIFY'
        }
      </Button>
    </div>
  );

  const handleVerifyOtp = async () => {
    setIsSubmitting(true);
    dispatch(
      thunkVerifyOTPCodeAction({ ismobileinterface, userid, otp })
    ).finally(() => {
      setIsSubmitting(false);
    })

  };

  return (
    <Box className="TwofactAuthBox">
      {!errorMessage && (
        <Box>
          <Typography variant="h6" className="OtpHeader">
            Verification Code
          </Typography>
          <Typography color={'#7C919D'}>
            Enter the code that was sent to
          </Typography>
          <Typography>{maskedEmail}</Typography>
        </Box>
      )}
      {errorMessage && (
        <Box>
          <Typography variant="h6" className="OtpHeader">
            Invalid Code
          </Typography>
          <Typography color={'#7C919D'}>Please Try Again</Typography>
        </Box>
      )}
      <Box className="otpBox">
        <OTPInput
          autoFocus
          length={6}
          className="otpContainer"
          inputClassName={errorMessage ? 'InvalidOtpInput' : 'otpInput'}
          onChangeOTP={(otp) => {
            setOtp(otp);
          }}
          onPaste={(otp) => {
            setOtp(otp);
          }}
        />
      </Box>
      {renderOtpResend()}
      <Box mt={1}>
        <Link
          className="StyleLink"
          underline="hover"
          href="#"
          onClick={onBackToLogin}
        >
          Back to Login
        </Link>
      </Box>
      {renderPasswordBox()}
    </Box>
  );
};

export default AuthVerificationForm;
