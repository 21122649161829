/**
 * Shazam host form
 */
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import {
  AZURE_IMAGE_API,
  OFFSET_TRANSACTION_ENABLED,
} from '../../../../../store/constants';
import ToggleSwitch from '../../../../Common/ToggleSwitch';
import './ShazamHostForm.scss';
import { validationSchema } from './validationSchema';
import { thunkClientFetchConfigHostListAction } from '../../../../../store/actions/clientActions';
import { useStore } from 'lnox';

interface ShazamHostFormProps {
  values?: any;
  selectedMerchantId: string;
  onConfigChange: (values: any) => void;
  isDisabled?: boolean;
  setIsShazamFormValid?: (value: boolean) => void;
}
const ShazamHostForm: React.FC<ShazamHostFormProps> = ({
  values = {},
  onConfigChange,
  selectedMerchantId,
  isDisabled = false,
  setIsShazamFormValid,
}) => {

  const {
    dispatch,
    state: {
      client: { clients },
    },
  } = useStore();

  const {
    fetchClientHostConfigs: {
      isPending: isPendingClientHostConfigs,
      isFulfilled: isFulfilledClientHostConfigs,
      message: { ClientHosts },
    }

  } = clients;



  const [shazamHostValues, setShazamHostValues] = useState<any>({});
  const [disabled, setDisabled] = useState<boolean>(isDisabled);

  const initialValues = {};

  const [confirmDialog, setConfirmDialog] = useState<any>(null);

  const confirmMessage = (message: string, handleClose: ((event: {}) => void) | undefined) => {

    setConfirmDialog(<Dialog
      open={true}
      onClose={(event) => {
        handleClose && handleClose(event);
        setConfirmDialog(null);
      }}

      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Please Confirm this action."}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={(event) => {
          setConfirmDialog(null);
        }}>Cancel</Button>
        <Button onClick={(event) => {
          handleClose && handleClose(event);
          setConfirmDialog(null);
        }} autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>);

  }


  const formik: any = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (fromValues: any) => { },
    validateOnChange: true,
  });

  const submitFormValues = () => {
    if (!shazamHostValues.IsChanged) {
      setShazamHostValues((prevState: any) => ({
        ...prevState,
        IsChanged: true,
      }));
    }
    onConfigChange && onConfigChange(shazamHostValues);
    setIsShazamFormValid &&
      setIsShazamFormValid(
        formik.errors && Object.keys(formik.errors).length ? false : true
      );
  };
  return (
    <Box className="HostContainerStyle">

      {confirmDialog}

      <Grid container>
        <Grid item xs={8}>
          <img
            alt={`${AZURE_IMAGE_API}/hosts/shazam.png`}
            src={`${AZURE_IMAGE_API}/hosts/shazam.png`}
            className="ShazamHostImageStyle"
          />

        </Grid>
        <Grid xs={4}>
          {Object.keys(values).length > 0 && <div style={{ width: 180, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>

            <Button variant="outlined"
              className="SideRailfooterButtonStyle"
              onClick={() => {
                setDisabled(!disabled);
              }}>{disabled ? "Edit" : "Cancel"}</Button>

            {!disabled && (<Button variant="outlined"
              className="SideRailfooterButtonStyle"
              onClick={() => {
                setDisabled(true);
              }}>Save</Button>)}

            {disabled && (<Button variant="outlined"
              className="SideRailfooterButtonStyle"
              onClick={() => {
                setDisabled(true);
                confirmMessage("Your about to delete this Host Configuration (Shazam).  Please confirm to do this.", () => {

                  new Promise((resolve, reject) => {
                    try {
                      dispatch(thunkClientFetchConfigHostListAction(shazamHostValues.ClientId));
                      resolve({ value: true });
                    } catch (error) {
                      reject({ value: false, error: error });
                    }

                  });

                });

              }}>Delete</Button>)}
          </div>}

        </Grid>


      </Grid>

      <Box className="StatusBoxStyle">
        <Typography fontWeight="bold">Status</Typography>
        <ToggleSwitch
          isChecked={true}
          handleOnChange={(value: any) => {
            setShazamHostValues((prevState: any) => ({
              ...prevState,
              IsActive: value,
            }));
            submitFormValues();
          }}
        ></ToggleSwitch>
      </Box>

      <Box className="MarginTop" display="inline-flex">
        <FormControl className="FormFieldLabelStyle">
          <TextField
            id={'ClientId'}
            name={'ClientId'}
            label="Client ID"
            disabled={disabled}
            className="LeftControlStyle"
            value={shazamHostValues['ClientId']}
            onChange={(e: any) => {
              const {
                target: { value },
              } = e;
              formik.setFieldValue('ClientId', value);
              setShazamHostValues((prevState: any) => ({
                ...prevState,
                ClientId: value,
              }));
            }}
            onBlur={(e: any) => {
              formik.setFieldTouched('ClientId');
              submitFormValues();
            }}
            error={
              formik.touched?.ClientId && formik.errors?.ClientId ? true : false
            }
            helperText={
              formik.touched?.ClientId && formik.errors['ClientId']
                ? formik.errors['ClientId']
                : ''
            }
          />
        </FormControl>
        <FormControl className="FormFieldLabelStyle">
          <TextField
            id={'ClientSecret'}
            name={'ClientSecret'}
            label="Client Secret"
            disabled={disabled}
            className="LeftControlStyle"
            value={shazamHostValues['ClientSecret']}
            onChange={(e: any) => {
              const {
                target: { value },
              } = e;
              formik.setFieldValue('ClientSecret', value);
              setShazamHostValues((prevState: any) => ({
                ...prevState,
                ClientSecret: value,
              }));
            }}
            onBlur={(e: any) => {
              formik.setFieldTouched('ClientSecret');
              submitFormValues();
            }}
            error={
              formik.touched?.ClientSecret && formik.errors?.ClientSecret
                ? true
                : false
            }
            helperText={
              formik.touched?.ClientSecret && formik.errors['ClientSecret']
                ? formik.errors['ClientSecret']
                : ''
            }
          />
        </FormControl>
      </Box>

      <Box className="MarginTop" display="inline-flex">
        <FormControl className="FormFieldLabelStyle">
          <TextField
            id={'MCC'}
            name={'MCC'}
            label="Merchant Category Code (MCC)"
            disabled={disabled}
            className="LeftControlStyle"
            value={shazamHostValues['MCC']}
            onChange={(e: any) => {
              const {
                target: { value },
              } = e;
              formik.setFieldValue('MCC', value);
              setShazamHostValues((prevState: any) => ({
                ...prevState,
                MCC: value,
              }));
            }}
            onBlur={(e: any) => {
              submitFormValues();
              formik.setFieldTouched('MCC');
            }}
            error={formik.touched?.MCC && formik.errors['MCC'] ? true : false}
            helperText={
              formik.touched?.MCC && formik.errors['MCC']
                ? formik.errors['MCC']
                : ''
            }
          />
        </FormControl>
        <FormControl className="FormFieldLabelStyle">
          <TextField
            id={'AccountId'}
            name={'AccountId'}
            label="Account ID"
            disabled={disabled}
            className="LeftControlStyle"
            value={shazamHostValues['AccountId']}
            onChange={(e: any) => {
              const {
                target: { value },
              } = e;
              formik.setFieldValue('AccountId', value);
              setShazamHostValues((prevState: any) => ({
                ...prevState,
                AccountId: value,
              }));
            }}
            onBlur={(e: any) => {
              submitFormValues();
              formik.setFieldTouched('AccountId');
            }}
            error={
              formik.touched?.AccountId && formik.errors['AccountId']
                ? true
                : false
            }
            helperText={
              formik.touched?.AccountId && formik.errors['AccountId']
                ? formik.errors['AccountId']
                : ''
            }
          />
        </FormControl>
      </Box>

      <Box className="MarginTop" display="inline-flex">
        <FormControl className="FormFieldLabelStyle">
          <TextField
            id={'AccountName'}
            name={'AccountName'}
            label="Account Name"
            disabled={disabled}
            className="LeftControlStyle"
            value={shazamHostValues['AccountName']}
            onChange={(e: any) => {
              const {
                target: { value },
              } = e;
              formik.setFieldValue('AccountName', value);
              setShazamHostValues((prevState: any) => ({
                ...prevState,
                AccountName: value,
              }));
            }}
            onBlur={(e: any) => {
              submitFormValues();
              formik.setFieldTouched('AccountName');
            }}
            error={
              formik.touched?.AccountName && formik.errors['AccountName']
                ? true
                : false
            }
            helperText={
              formik.touched?.AccountName && formik.errors['AccountName']
                ? formik.errors['AccountName']
                : ''
            }
          />
        </FormControl>
        <FormControl className="FormFieldLabelStyle">
          <TextField
            id={'AccountType'}
            name={'AccountType'}
            label="Account Type"
            disabled={disabled}
            className="LeftControlStyle"
            value={shazamHostValues['AccountType']}
            onChange={(e: any) => {
              const {
                target: { value },
              } = e;
              formik.setFieldValue('AccountType', value);
              setShazamHostValues((prevState: any) => ({
                ...prevState,
                AccountType: value,
              }));
            }}
            onBlur={(e: any) => {
              submitFormValues();
              formik.setFieldTouched('AccountType');
            }}
            error={
              formik.touched?.AccountType && formik.errors['AccountType']
                ? true
                : false
            }
            helperText={
              formik.touched?.AccountType && formik.errors['AccountType']
                ? formik.errors['AccountType']
                : ''
            }
          />
        </FormControl>
      </Box>

      <Box className="MarginTop" display="inline-flex">
        <FormControl className="FormFieldLabelStyle">
          <TextField
            id={'Url'}
            name={'Url'}
            label="URL"
            disabled={disabled}
            className="LeftControlStyle"
            value={shazamHostValues['Url']}
            onChange={(e: any) => {
              const {
                target: { value },
              } = e;
              formik.setFieldValue('Url', value);
              setShazamHostValues((prevState: any) => ({
                ...prevState,
                Url: value,
              }));
            }}
            onBlur={(e: any) => {
              submitFormValues();
              formik.setFieldTouched('Url');
            }}
            error={formik.touched?.Url && formik.errors['Url'] ? true : false}
            helperText={
              formik.touched?.Url && formik.errors['Url']
                ? formik.errors['Url']
                : ''
            }
          />
        </FormControl>

        <FormControl className="FormFieldLabelStyle">
          <InputLabel id={'IsOffsetTrnx'}>Offset Transactions?</InputLabel>
          <Select
            label={'Offset Transactions?'}
            labelId={'IsOffsetTrnx'}
            id={'IsOffsetTrnx'}
            name={'IsOffsetTrnx'}
            disabled={disabled}
            className={'RightControlStyle'}
            value={shazamHostValues['State']}
            onChange={(e: any) => {
              const {
                target: { value },
              } = e;
              formik.setFieldValue('IsOffsetTrnx', value);

              setShazamHostValues((prevState: any) => ({
                ...prevState,
                IsOffsetTrnx: value,
              }));
            }}
            onBlur={(e: any) => {
              submitFormValues();
              formik.setFieldTouched('IsOffsetTrnx');
            }}
            error={
              formik.touched?.IsOffsetTrnx && formik.errors['IsOffsetTrnx']
                ? true
                : false
            }
          >
            {OFFSET_TRANSACTION_ENABLED?.map((option: any) => (
              <MenuItem key={option.key} value={option.value}>
                {option.key}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText sx={{ color: '#d32f2f' }}>
            {formik.touched?.IsOffsetTrnx && formik.errors['IsOffsetTrnx']
              ? formik.errors['IsOffsetTrnx']
              : ''}
          </FormHelperText>
        </FormControl>
      </Box>
    </Box>
  );
};

export default ShazamHostForm;
