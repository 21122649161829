/**
 * Tokenization host form
 */
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@mui/material';
import { useFormik } from 'formik';
import React, { useState } from 'react';

import { useStore } from 'lnox';

import { AZURE_IMAGE_API, AMEX_OPT_BLUE, HOST_COUNTRY_OPTIONS } from '../../../../../store/constants';
import ToggleSwitch from '../../../../Common/ToggleSwitch';

import { thunkClientFetchConfigHostListAction } from '../../../../../store/actions/clientActions';
import { thunkUpdateTokenizationHostAction } from '../../../../../store/actions/hostActions';


import './TokenizationHostForm.scss';
import { validationSchema } from './validationSchema';
import { ClientHostConfigTypes } from '../../../../../store/actions/types';
import { ClientHostConfig } from '../../../../../types/api';

interface TokenizationHostFormProps {
  selectedMerchantId: string;
  values: any;
  onConfigChange: (values: any) => void;
  isDisabled?: boolean;
  setIsTokenizationFormValid?: (value: boolean) => void;
}
const TokenizationHostForm: React.FC<TokenizationHostFormProps> = ({
  values,
  onConfigChange,
  isDisabled,
  selectedMerchantId,
  setIsTokenizationFormValid,
}) => {


  const {
    dispatch,
    state: {
      client: { clients },
    },
  } = useStore();

  const {
    fetchClientHostConfigs: {
      isPending: isPendingClientHostConfigs,
      isFulfilled: isFulfilledClientHostConfigs,
      message: { ClientHosts },
    }

  } = clients;

  
  const [tokenizationHostValues, setTokenizationHostValues] =
    useState<any>(values);
  const [passwordValues, setPasswordValues] = React.useState({
    password: '',
    showPassword: false,
  });

  

  const [disabled, setDisabled] = useState<boolean>((isDisabled !== undefined) ? isDisabled : false);

  const [confirmDialog, setConfirmDialog] = useState<any>(null);

  const confirmMessage = (message: string, handleClose: ((event: {}) => void) | undefined) => {

    setConfirmDialog(<Dialog
      open={true}
      onClose={(event) => {
        handleClose && handleClose(event);
        setConfirmDialog(null);
      }}

      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Please Confirm this action."}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={(event) => {
          setConfirmDialog(null);
        }}>Cancel</Button>
        <Button onClick={(event) => {
          handleClose && handleClose(event);
          setConfirmDialog(null);
        }} autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>);

  }


  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const handleClickShowPassword = () => {
    setPasswordValues({
      ...passwordValues,
      showPassword: !passwordValues.showPassword,
    });
  };

  const formik: any = useFormik({
    initialValues: values,
    validationSchema: validationSchema,
    onSubmit: (fromValues: any) => { },
    validateOnChange: true,
  });

  const submitFormValues = () => {
    if (!tokenizationHostValues.IsChanged) {
      setTokenizationHostValues((prevState: any) => ({
        ...prevState,
        IsChanged: true,
      }));
    }
    onConfigChange && onConfigChange(tokenizationHostValues);
    setIsTokenizationFormValid &&
      setIsTokenizationFormValid(
        formik.errors && Object.keys(formik.errors).length ? false : true
      );
  };

  return (
    <Box className="HostContainerStyle">

      {confirmDialog}

      <Grid container>
        <Grid item xs={8}>
          <img
            alt={`${AZURE_IMAGE_API}/hosts/vgs.png`}
            src={`${AZURE_IMAGE_API}/hosts/vgs.png`}
            className="HostImageStyle"
          />

        </Grid>
        <Grid xs={4}>
          {Object.keys(values).length > 0 && <div style={{ width: 180, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>

            <Button variant="outlined"
              className="SideRailfooterButtonStyle"
              onClick={() => {
                setDisabled(!disabled);
              }}>{disabled ? "Edit" : "Cancel"}</Button>

            {!disabled && (<Button variant="outlined"
              className="SideRailfooterButtonStyle"
              onClick={() => {
                setDisabled(true);

                confirmMessage("Your about to save this Host Configuration (Tokenization VGS).  Please confirm to do this.", () => {
                  new Promise((resolve, reject) => {
                    try {

                      let configData = ClientHosts.find((clientHost: ClientHostConfig) => {
                        return [ClientHostConfigTypes.CLIENT_HOST_ID_VGS].includes(clientHost.HostId as number);
                      });

                      let hostConfig = {
                        VgsHostConfigId: configData.HostConfigId,
                        ClientId: selectedMerchantId,
                        Vgs: { ...values, 
                                         VgsHostConfigId: configData.HostConfigId, 
                                         ClientId: selectedMerchantId },
                        IsActive:
                          values?.IsActive == undefined || values?.IsActive == null
                            ? true
                            : values?.IsActive
                      };


                      dispatch(thunkUpdateTokenizationHostAction(hostConfig)).then(() => {
                        try {
                          dispatch(thunkClientFetchConfigHostListAction(tokenizationHostValues.ClientId));
                          resolve({ value: true });
                        } catch (error) {
                          reject({ value: false, error: error });
                        }
                      });
                    } catch (error) {
                      reject({ value: false, error: error });
                    }

                  });
                });


              }}>Save</Button>)}

            {disabled && (<Button variant="outlined"
              className="SideRailfooterButtonStyle"
              onClick={() => {
                setDisabled(true);
                confirmMessage("Your about to delete this Host Configuration (Tokenization VGS).  Please confirm to do this.", () => { 
                  new Promise((resolve, reject) => {
                    try {
                      dispatch(thunkClientFetchConfigHostListAction(tokenizationHostValues.ClientId));
                      resolve({ value: true });
                    } catch (error) {
                      reject({ value: false, error: error });
                    }

                  });
                });
              }}>Delete</Button>)}
          </div>}

        </Grid>


      </Grid>

      <Box className="StatusBoxStyle">
        <Typography fontWeight="bold">Status</Typography>
        <ToggleSwitch
          disabled={disabled}
          isChecked={(values.IsTokenizationEnabled !== undefined) ? values.IsTokenizationEnabled : true}
          handleOnChange={(value: any) => {
            setTokenizationHostValues(
              {
                ...values,
                IsActive: value,
                IsTokenizationEnabled: value
              });
            submitFormValues();
          }}
        ></ToggleSwitch>
      </Box>

      <Box className="MarginTop" display="inline-flex">
        <FormControl className="FormFieldLabelStyle">
          <TextField
            id={'UserName'}
            name={'UserName'}
            label="Username"
            disabled={disabled}
            className="LeftControlStyle"
            value={tokenizationHostValues['UserName']}
            onChange={(e: any) => {
              const {
                target: { value },
              } = e;
              formik.setFieldValue('UserName', value);
              setTokenizationHostValues((prevState: any) => ({
                ...prevState,
                UserName: value,
              }));
            }}
            onBlur={(e: any) => {
              formik.setFieldTouched('UserName');
              submitFormValues();
            }}
            error={
              formik.touched?.UserName && formik.errors?.UserName ? true : false
            }
            helperText={
              formik.touched?.UserName && formik.errors['UserName']
                ? formik.errors['UserName']
                : ''
            }
          />
        </FormControl>
        <FormControl id={'Password'} fullWidth className="FieldLabelStyle">
          <TextField
            id={'Password'}
            name={'Password'}
            label="Password"
            className="FirstNameStyle"
            disabled={disabled}
            value={tokenizationHostValues?.Password}
            type={passwordValues?.showPassword ? 'text' : 'password'}
            onChange={(e: any) => {
              const {
                target: { value },
              } = e;
              formik.setFieldValue('Password', value);
              setTokenizationHostValues((prevState: any) => ({
                ...prevState,
                Password: value,
              }));
            }}
            onBlur={(e: any) => {
              formik.setFieldTouched('Password');
              submitFormValues();
            }}
            InputProps={{
              autoComplete: 'new-password',
              // <-- This is where the toggle button is added.
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {passwordValues?.showPassword ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={
              formik.touched.Password && formik.errors.Password ? true : false
            }
          />
          {formik.touched.Password && formik.errors.Password && (
            <FormHelperText className="FormHelperTextStyle">
              {formik.touched.Password && formik.errors.Password
                ? formik.errors.Password
                : ''}
            </FormHelperText>
          )}
        </FormControl>
      </Box>

      <Box className="MarginTop" display="inline-flex">
        <FormControl className="FormFieldLabelStyle">
          <TextField
            id={'VaultId'}
            name={'VaultId'}
            label="Vault ID"
            disabled={disabled}
            className="LeftControlStyle"
            value={tokenizationHostValues['VaultId']}
            onChange={(e: any) => {
              const {
                target: { value },
              } = e;
              formik.setFieldValue('VaultId', value);
              setTokenizationHostValues((prevState: any) => ({
                ...prevState,
                VaultId: value,
              }));
            }}
            onBlur={(e: any) => {
              submitFormValues();
              formik.setFieldTouched('VaultId');
            }}
            error={
              formik.touched?.VaultId && formik.errors['VaultId'] ? true : false
            }
            helperText={
              formik.touched?.VaultId && formik.errors['VaultId']
                ? formik.errors['VaultId']
                : ''
            }
          />
        </FormControl>
        <FormControl className="FormFieldLabelStyle">
          <TextField
            id={'Url'}
            name={'Url'}
            label="URL"
            disabled={disabled}
            className="LeftControlStyle"
            value={tokenizationHostValues['Url']}
            onChange={(e: any) => {
              const {
                target: { value },
              } = e;
              formik.setFieldValue('Url', value);
              setTokenizationHostValues((prevState: any) => ({
                ...prevState,
                Url: value,
              }));
            }}
            onBlur={(e: any) => {
              submitFormValues();
              formik.setFieldTouched('Url');
            }}
            error={formik.touched?.Url && formik.errors['Url'] ? true : false}
            helperText={
              formik.touched?.Url && formik.errors['Url']
                ? formik.errors['Url']
                : ''
            }
          />
        </FormControl>
      </Box>
    </Box>
  );
};

export default TokenizationHostForm;
