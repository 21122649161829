/**
 * Tsys host form
 */
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';

import ToggleSwitch from '../../../../Common/ToggleSwitch';
import './WorldpayHostForm.scss';
import { validationSchema } from './validationSchema';
import { useStore } from 'lnox';
import { thunkClientFetchConfigHostListAction } from '../../../../../store/actions/clientActions';

interface WorldpayHostFormProps {
  values?: any;
  onConfigChange: (values: any) => void;
  isDisabled?: boolean;
  selectedMerchantId: string;
  setIsFiservFormValid?: (value: boolean) => void;
}
const WorldpayHostForm: React.FC<WorldpayHostFormProps> = ({
  values = {},
  onConfigChange,
  isDisabled = false,
  setIsFiservFormValid: setIsFiservFormValid,
  selectedMerchantId
}) => {


  const {
    dispatch,
    state: {
      client: { clients },
    },
  } = useStore();

  const {
    fetchClientHostConfigs: {
      isPending: isPendingClientHostConfigs,
      isFulfilled: isFulfilledClientHostConfigs,
      message: { ClientHosts },
    }

  } = clients;


  const [worldpayHostFormValues, setWorldpayHostFormValues] = useState<any>(values);

  const [disabled, setDisabled] = useState<boolean>(isDisabled);

  const [confirmDialog, setConfirmDialog] = useState<any>(null);

  const confirmMessage = (message: string, handleClose: ((event: {}) => void) | undefined) => {

    setConfirmDialog(<Dialog
      open={true}
      onClose={(event) => {
        handleClose && handleClose(event);
        setConfirmDialog(null);
      }}

      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Please Confirm this action."}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={(event) => {
          setConfirmDialog(null);
        }}>Cancel</Button>
        <Button onClick={(event) => {
          handleClose && handleClose(event);
          setConfirmDialog(null);
        }} autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>);

  }

  const formik: any = useFormik({
    initialValues: {},
    validationSchema: validationSchema,
    onSubmit: (fromValues: any) => { },
    validateOnChange: true,
  });

  const submitFormValues = () => {
    if (!worldpayHostFormValues.IsChanged) {
      setWorldpayHostFormValues((prevState: any) => ({
        ...prevState,
        IsChanged: true,
      }));
    }
    onConfigChange && onConfigChange(worldpayHostFormValues);
    setIsFiservFormValid &&
      setIsFiservFormValid(
        formik.errors && Object.keys(formik.errors).length ? false : true
      );
  };


  return (
    <Box className="HostContainerStyle">

      {confirmDialog}

      <Grid container>
        <Grid item xs={8}>
          <img
            alt={`/logos/worldpay.png`}
            src={`/logos/worldpay.png`}
            className="HostImageStyle"
          />

        </Grid>
        <Grid xs={4}>
          {Object.keys(values).length > 0 && <div style={{ width: 180, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>

            <Button variant="outlined"
              className="SideRailfooterButtonStyle"
              onClick={() => {
                setDisabled(!disabled);
              }}>{disabled ? "Edit" : "Cancel"}</Button>

            {!disabled && (<Button variant="outlined"
              className="SideRailfooterButtonStyle"
              onClick={() => {
                setDisabled(true);
              }}>Save</Button>)}

            {disabled && (<Button variant="outlined"
              className="SideRailfooterButtonStyle"
              onClick={() => {
                setDisabled(true);
                confirmMessage("Your about to delete this Host Configuration (Worldpay).  Please confirm to do this.", () => {
                  new Promise((resolve, reject) => {
                    try {
                      dispatch(thunkClientFetchConfigHostListAction(worldpayHostFormValues.ClientId));
                      resolve({ value: true });
                    } catch (error) {
                      reject({ value: false, error: error });
                    }

                  });

                });
              }}>Delete</Button>)}
          </div>}

        </Grid>


      </Grid>

      <Typography className="TitleStyle">General</Typography>

      <Box className="StatusBoxStyle">
        <Typography fontWeight="bold">Status</Typography>
        <ToggleSwitch
          isChecked={true}
          handleOnChange={(value: any) => {
            setWorldpayHostFormValues((prevState: any) => ({
              ...prevState,
              IsActive: value,
            }));
            submitFormValues();
          }}
        ></ToggleSwitch>
      </Box>



      <Box className="MarginTop" display="inline-flex">

        <FormControl className="FormFieldLabelStyle">
          <TextField
            id={'Name'}
            name={'Name'}
            label="Name"
            disabled={disabled}
            className="LeftControlStyle"
            value={worldpayHostFormValues['Name']}
            onChange={(e: any) => {
              const {
                target: { value },
              } = e;
              formik.setFieldValue('Name', value);
              setWorldpayHostFormValues((prevState: any) => ({
                ...prevState,
                Name: value,
              }));
            }}
            onBlur={(e: any) => {
              submitFormValues();
              formik.setFieldTouched('Name');
            }}
            error={formik.touched.MCC && formik.errors['Name'] ? true : false}
            helperText={
              formik.touched?.MCC && formik.errors['Name']
                ? formik.errors['Name']
                : ''
            }
          />
        </FormControl>

        <FormControl className="FormFieldLabelStyle">
          <TextField
            id={'Password'}
            name={'Password'}
            label="Password"
            disabled={disabled}
            className="RightControlStyle"
            value={worldpayHostFormValues['Password']}
            onChange={(e: any) => {
              const {
                target: { value },
              } = e;
              formik.setFieldValue('Password', value);
              setWorldpayHostFormValues((prevState: any) => ({
                ...prevState,
                GroupId: value,
              }));
            }}
            onBlur={(e: any) => {
              formik.setFieldTouched('Password');
              submitFormValues();
            }}
            error={formik.touched?.GroupId && formik.errors['Password'] ? true : false}
            helperText={
              formik.touched?.GroupId && formik.errors['Password']
                ? formik.errors['Password']
                : ''
            }
          />
        </FormControl>
      </Box>


      <Box className="MarginTop" display="inline-flex">

        <FormControl className="FormFieldLabelStyle">
          <TextField
            id={'Url'}
            name={'Url'}
            label="Url"
            disabled={disabled}
            className="LeftControlStyle"
            value={worldpayHostFormValues['Url']}
            onChange={(e: any) => {
              const {
                target: { value },
              } = e;
              formik.setFieldValue('Url', value);
              setWorldpayHostFormValues((prevState: any) => ({
                ...prevState,
                Url: value,
              }));
            }}
            onBlur={(e: any) => {
              submitFormValues();
              formik.setFieldTouched('Url');
            }}
            error={formik.touched.MCC && formik.errors['Url'] ? true : false}
            helperText={
              formik.touched?.MCC && formik.errors['Url']
                ? formik.errors['Url']
                : ''
            }
          />
        </FormControl>

        <FormControl className="FormFieldLabelStyle">
          <TextField
            id={'Username'}
            name={'Username'}
            label="Username"
            disabled={disabled}
            className="RightControlStyle"
            value={worldpayHostFormValues['Username']}
            onChange={(e: any) => {
              const {
                target: { value },
              } = e;
              formik.setFieldValue('Username', value);
              setWorldpayHostFormValues((prevState: any) => ({
                ...prevState,
                Username: value,
              }));
            }}
            onBlur={(e: any) => {
              formik.setFieldTouched('Username');
              submitFormValues();
            }}
            error={formik.touched?.GroupId && formik.errors['Username'] ? true : false}
            helperText={
              formik.touched?.GroupId && formik.errors['Username']
                ? formik.errors['Username']
                : ''
            }
          />
        </FormControl>
      </Box>

    </Box>
  );
};

export default WorldpayHostForm;
