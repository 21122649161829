/**
 * API class
 * used to call the apis from orchestration layer
 * This class is also used for adding request headers as middleware
 * Adds middleware to handle error response from api
 * Also used for creating the response object suitable for ui from the response recevied from api
 */
import axios, { AxiosResponse } from 'axios';
import { ApiResponse, AuthApiProps } from '../types/api';
import { APIKEY, EK, IV, LOCALENCRYPTIONKEY } from '../store/constants';
import CryptoJS from 'crypto-js';
// TODO: Remove after equipment will be in the database
import { SanitizationHelper } from './SanitizationHelper';
import {
  Decrypt,
  Encrypt,
  getApiKeyValue,
  getDKValue,
  getIVValue,
  getJWTToken,
  getTKValue,
  swapDefaultKeysAndCrypto,
} from './EncryptionHelper';

const successStatusCodes = [200, 201, 204];
const skipInterceptorErrorCodes = [404];

axios.defaults.timeout = process.env.REQUEST_TIMEOUT
  ? parseInt(process.env.REQUEST_TIMEOUT)
  : 0;

const createResponse = (
  rawResponse: AxiosResponse | object | any
): ApiResponse => {
  if (rawResponse && 'response' in rawResponse) {
    const { response } = rawResponse;
    return createResponse(response);
  }
  let apiResponse: ApiResponse;
  if (rawResponse && 'data' in rawResponse) {
    const { data, statusText, status, config } = rawResponse;
    let message = Decrypt(
      data?.message || data?.Message,
      config?.headers?.iv,
      config?.headers?.ek
    );
    if (!successStatusCodes.includes(status)) {
      message = message?.ErrorLists[0]?.DisplayMessage;
    }
    // Sort in case it's not.  The lowest Client.Id is the root client
    if (message?.Clients && message?.Clients.length > 0) {
      message.Clients = message.Clients.sort((a: any, b: any) => {
        if (a.Id > b.Id) return 1;
        else if (a.Id < b.Id) return -1;
        else return 0;
      });
    }

    if (successStatusCodes.includes(status)) {
      //let crypto =
      //  message && message.Clients && message.Clients.length > 0
      //    ? message.Clients[0].Crypto
      //    : message?.Crypto;

      //swapDefaultKeysAndCrypto(data?.message || data?.Message, crypto);
    }
    apiResponse = {
      error: !successStatusCodes.includes(status),
      message:
        message ||
        statusText ||
        'Something went wrong. Please contact our support team',
      ...rawResponse,
    };
  } else {
    apiResponse = {
      error: true,
      status: 503,
      statusText: 'INTERNAL_ERROR',
      message:
        'Something went wrong. Please check your internet connection or contact our support.',
      data: {},
      headers: {},
    };
  }

  if (process.env.REACT_APP_SHOW_OUTGOING_INCOMMING_UNENCRYPTED === 'true') {
    console.log('-----DATA FROM API RESPONSE DECRYPTED------', apiResponse);
  }
  return apiResponse;
};

/**
 * API
 */
const api: AuthApiProps = {
  auth: {
    login: async (login: string, password: string, ipAddress?: string) => {
      let loginResponse: ApiResponse;
      try {
        const response = await axios.post('v2/Authentication/Login', {
          UserId: login,
          Password: password,
        });
        loginResponse = createResponse(response);
      } catch (err) {
        loginResponse = createResponse(err);
      }
      return loginResponse;
    },
    changepassword: async (
      userId: string,
      otp: string,
      newPassword: string
    ) => {
      let changePasswordResponse: ApiResponse;
      try {
        const response = await axios.post('v2/Authentication/Password/Change', {
          userId,
          otp,
          newPassword,
        });
        changePasswordResponse = createResponse(response);
      } catch (err) {
        changePasswordResponse = createResponse(err);
      }
      return changePasswordResponse;
    },
    sendVerificationOTP: async (userid: string) => {
      let verificationResponse: ApiResponse;
      try {
        const response = await axios.post('v2/Authentication/OTP/Get', {
          userid,
        });
        verificationResponse = createResponse(response);
      } catch (err) {
        verificationResponse = createResponse(err);
      }
      return verificationResponse;
    },
    sendVerificationForgotPasswordOTP: async (userid: string) => {
      let verificationResponse: ApiResponse;
      try {
        // v2/Authentication/Password/Forgot
        //const response = await axios.post('v2/Authentication/OTP/Get', {
        const response = await axios.post('v2/Authentication/Password/Forgot', {
          username: userid,
        });
        verificationResponse = createResponse(response);
      } catch (err) {
        verificationResponse = createResponse(err);
      }
      return verificationResponse;
    },
    verifyOTPCode: async (data: object) => {
      let verificationResponse: ApiResponse;
      try {
        const response = await axios.post('v2/Authentication/OTP/Validate', {
          ...data,
        });
        verificationResponse = createResponse(response);
      } catch (err) {
        verificationResponse = createResponse(err);
      }

      return verificationResponse;
    },
    createDevice: async (data: object) => {
      let verificationResponse: ApiResponse;
      try {
        const response = await axios.post('v2/Device/Create', {
          ...data,
        });
        verificationResponse = createResponse(response);
      } catch (err) {
        verificationResponse = createResponse(err);
      }
      return verificationResponse;
    },
  },
  reporting: {
    transactions: {
      fetch: async (params: any = {}) => {
        try {
          const response = await axios.post(
            `v2/Reporting/Transactions/GetAll`,
            {
              ...params,
            }
          );
          return createResponse(response);
        } catch (err) {
          return createResponse(err);
        }
      },
      fetchSummary: async (params: any = {}) => {
        try {
          const response = await axios.post(
            `v2/Reporting/Transactions/Summary`,
            {
              ...params,
            }
          );
          return createResponse(response);
        } catch (err) {
          return createResponse(err);
        }
      },
    },
    batches: {
      fetch: async (params: any = {}) => {
        try {
          const response = await axios.post(`v2/Reporting/Batches/GetAll`, {
            ...params,
          });
          return createResponse(response);
        } catch (err) {
          return createResponse(err);
        }
      },
      close: async (batchId: string) => {
        try {
          const response = await axios.post(`v2/Batch/Close`, {
            BatchId: batchId,
          });
          return createResponse(response);
        } catch (err) {
          return createResponse(err);
        }
      },
    },
  },
  users: {
    fetch: async (params: any = {}) => {
      try {
        const response = await axios.post(`v2/User/Users/GetAll`, {
          ...params,
        });
        return createResponse(response);
      } catch (err) {
        return createResponse(err);
      }
    },
    fetchById: async (params: any = {}) => {
      try {
        const { id } = params;
        const response = await axios.get(`v2/User/${id}`, {
          ...params,
        });
        return createResponse(response);
      } catch (err) {
        return createResponse(err);
      }
    },
    create: async (params: any = {}) => {
      try {
        const response = await axios.post(`v2/User/Create`, {
          ...params,
        });
        return createResponse(response);
      } catch (err) {
        return createResponse(err);
      }
    },
    update: async (params: any = {}) => {
      try {
        const response = await axios.put(`v2/User/Update`, {
          ...params,
        });
        return createResponse(response);
      } catch (err) {
        return createResponse(err);
      }
    },
    profile: {
      fetch: async (params: any = {}) => {
        try {
          const response = await axios.post(`v2/User/Users/GetAll`, {
            ...params,
          });
          return createResponse(response);
        } catch (err) {
          return createResponse(err);
        }
      },
      update: async (params: any = {}) => {
        try {
          const response = await axios.post(`v2/User/Update`, {
            ...params,
          });
          return createResponse(response);
        } catch (err) {
          return createResponse(err);
        }
      },
    },
  },
  virtualTerminal: {
    sale: async (transactionRequest: any) => {
      try {
        const response = await axios.post(`v2/Transaction/Sale`, {
          ...transactionRequest,
        });
        return createResponse(response);
      } catch (err) {
        return createResponse(err);
      }
    },
    authorise: async (transactionRequest: any) => {
      try {
        const response = await axios.post(`v2/Transaction/Auth`, {
          ...transactionRequest,
        });
        return createResponse(response);
      } catch (err) {
        return createResponse(err);
      }
    },
    refund: async (transactionRequest: any) => {
      try {
        const response = await axios.post(`v2/Transaction/Refund`, {
          ...transactionRequest,
        });
        return createResponse(response);
      } catch (err) {
        return createResponse(err);
      }
    },
    capture: async (transactionRequest: any) => {
      try {
        const response = await axios.post(`v2/Transaction/Capture`, {
          ...transactionRequest,
        });
        return createResponse(response);
      } catch (err) {
        return createResponse(err);
      }
    },
    void: async (transactionRequest: any) => {
      try {
        const response = await axios.post(`v2/Transaction/Void`, {
          ...transactionRequest,
        });
        return createResponse(response);
      } catch (err) {
        return createResponse(err);
      }
    },
    pay: async (transactionRequest: any) => {
      try {
        const response = await axios.post(`v2/Transaction/Pay`, {
          ...transactionRequest,
        });
        return createResponse(response);
      } catch (err) {
        return createResponse(err);
      }
    },
    take: async (transactionRequest: any) => {
      try {
        const response = await axios.post(`v2/Transaction/Take`, {
          ...transactionRequest,
        });
        return createResponse(response);
      } catch (err) {
        return createResponse(err);
      }
    },
  },

  clients: {
    fetch: async (params: any = {}) => {
      try {
        const response = await axios.post(`v2/Client/GetAll`, {
          ...params,
        });
        let clientsResponse = createResponse(response);
        return clientsResponse;
      } catch (err) {
        return createResponse(err);
      }
    },
    fetchApiKey: async (clientId: string = '') => {
      try {
        // LEGACY WE SHOULD NOT BE CALLING IT
        const response = await axios.post(`cli/client/GetApiKeyByClientId`, {
          Id: clientId,
        });
        return createResponse(response);
      } catch (err) {
        return createResponse(err);
      }
    },
    fetchClientHostConfigs: async (clientId: string = '') => {
      try {
        const response = await axios.get(`v2/Client/Hosts/${clientId}`);
        return createResponse(response);
      } catch (err) {
        return createResponse(err);
      }
    },
    create: async (params: any = {}) => {
      try {
        const response = await axios.post(`v2/Client/Application/Create`, {
          ...params,
        });
        return createResponse(response);
      } catch (err) {
        return createResponse(err);
      }
    },
    createApplicationID: async () => {
      try {
        // LEGACY WE SHOULD NOT BE CALLING IT
        const response = await axios.get(`cli/client/GetApplicationId`);
        return createResponse(response);
      } catch (err) {
        return createResponse(err);
      }
    },
    host: {
      createTsysHost: async (params: any = {}) => {
        try {
          const response = await axios.post(
            `v2/HostConfiguration/tsys/create`,
            {
              ...params,
            }
          );
          return createResponse(response);
        } catch (err) {
          return createResponse(err);
        }
      },
      createTokenizationHost: async (params: any = {}) => {
        try {
          const response = await axios.post(`v2/HostConfiguration/vgs/create`, {
            ...params,
          });
          return createResponse(response);
        } catch (err) {
          return createResponse(err);
        }
      },
      createShazamHost: async (params: any = {}) => {
        try {
          const response = await axios.post(
            `v2/HostConfiguration/shazam/create`,
            {
              ...params,
            }
          );
          return createResponse(response);
        } catch (err) {
          return createResponse(err);
        }
      },
      createFiservHost: async (params: any = {}) => {
        try {
          const response = await axios.post(
            `v2/HostConfiguration/fiserv/create`,
            {
              ...params,
            }
          );
          return createResponse(response);
        } catch (err) {
          return createResponse(err);
        }
      },
      createWorldpayHost: async (params: any = {}) => {
        try {
          const response = await axios.post(
            `v2/HostConfiguration/worldpayfis/create`,
            {
              ...params,
            }
          );
          return createResponse(response);
        } catch (err) {
          return createResponse(err);
        }
      },
      updateTokenizationHost: async (params: any = {}) => {
        try {
          const response = await axios.post(
            `v2/HostConfiguration/vgs/create`,
            {
              ...params,
            }
          );
          return createResponse(response);
        } catch (err) {
          return createResponse(err);
        }
      },
      updateShazamHost: async (params: any = {}) => {
        try {
          const response = await axios.post(
            `v2/HostConfiguration/shazam/create`,
            {
              ...params,
            }
          );
          return createResponse(response);
        } catch (err) {
          return createResponse(err);
        }
      },
      updateTsysHost: async (params: any = {}) => {
        try {
          const response = await axios.put(
            `v2/HostConfiguration/tsys/update`,
            {
              ...params,
            }
          );
          return createResponse(response);
        } catch (err) {
          return createResponse(err);
        }
      },
      updateFiservHost: async (params: any = {}) => {
        try {
          const response = await axios.put(
            `v2/HostConfiguration/fiserv/update`,
            {
              ...params,
            }
          );
          return createResponse(response);
        } catch (err) {
          return createResponse(err);
        }
      },
      updateWorldpayHost: async (params: any = {}) => {
        try {
          const response = await axios.put(
            `v2/HostConfiguration/worldpayfis/update`,
            {
              ...params,
            }
          );
          return createResponse(response);
        } catch (err) {
          return createResponse(err);
        }
      },
      fetchTsysHost: async (hostId: string) => {
        try {
          const response = await axios.get(`v2/HostConfiguration/tsys/${hostId}`);
          return createResponse(response);
        } catch (err) {
          return createResponse(err);
        }
      },
      fetchTokenizationHost: async (hostId: string) => {
        try {
          const response = await axios.get(`v2/HostConfiguration/vgs/${hostId}`);
          return createResponse(response);
        } catch (err) {
          return createResponse(err);
        }
      },
      fetchShazamHost: async (hostId: string) => {
        try {
          const response = await axios.get(`v2/HostConfiguration/shazam/${hostId}`);
          return createResponse(response);
        } catch (err) {
          return createResponse(err);
        }
      },
      fetchFiservHost: async (hostId: string) => {
        try {
          const response = await axios.get(`v2/HostConfiguration/fiserv/${hostId}`);
          return createResponse(response);
        } catch (err) {
          return createResponse(err);
        }
      },
      fetchWorldpayHost: async (hostId: string) => {
        try {
          const response = await axios.get(`v2/HostConfiguration/worldpayfis/${hostId}`);
          return createResponse(response);
        } catch (err) {
          return createResponse(err);
        }
      },
    },
  },
};

const sanitizeInputData = (data: any) => {
  let payload = data ? new SanitizationHelper().trimValues(data) : data;
  if (process.env.REACT_APP_SHOW_OUTGOING_INCOMMING_UNENCRYPTED === 'true') {
    console.log('-----DATA FOR API REQUEST BEFORE ENCRYPTED------', payload);
  }
  return Encrypt(payload);
};

const getBaseUrl = () => {
  return (
    process.env.REACT_APP_ORCHESTRATION_URL ||
    localStorage.getItem('REACT_APP_ORCHESTRATION_URL') ||
    ''
  );
};

export const withInterceptors = (api: AuthApiProps) => {
  axios.interceptors.request.use(
    async (config: any) => {
      const { headers, data } = config;
      let header = { ...headers };
      let jwtKey = getJWTToken();
      let ekValue = getDKValue();
      let apiKey = getApiKeyValue();
      let ivValue = getIVValue();
      if (localStorage.getItem('IsFinancialTransaction') === 'true') {
        ekValue = getTKValue();
      }
      header = {
        ...headers,
        ['Authorization']: `Bearer ${jwtKey}`,
        [APIKEY]: apiKey,
        [IV]: ivValue,
        [EK]: CryptoJS.AES.encrypt(ekValue, LOCALENCRYPTIONKEY).toString(),
      };
      let request = {
        ...config,
        headers: {
          ...header,
        },

        data: { Message: sanitizeInputData(data) },
      };
      request.baseURL = getBaseUrl();
      return request;
    },
    (error: any) => Promise.reject(error)
  );

  axios.interceptors.response.use(
    (response: any) => response,
    (error: any) => {
      const {
        response: { status, data, config },
      } = error;

      let errorResponse = Decrypt(
        data?.message || data?.Message,
        config?.headers?.iv,
        config?.headers?.ek
      );

      const {
        onUnauthorizedInterceptor,
        onCommonErrorInterceptor,
        onTimeoutErrorInterceptor,
      } = api;

      if (
        status === 401 &&
        localStorage.getItem('IsSessionExpired') !== 'true'
      ) {
        if (error.response.data === 'Session Expired') {
          localStorage.setItem('IsSessionExpired', 'true');
        }
        onUnauthorizedInterceptor && onUnauthorizedInterceptor();
      }

      // this is added to check gateway timeout or request timeout
      if (error.code === 'ECONNABORTED' || status === 408 || status === 504) {
        error.message = 'Connection Timed out';
        onTimeoutErrorInterceptor && onTimeoutErrorInterceptor();
      } else {
        if (
          !skipInterceptorErrorCodes.includes(status) &&
          onCommonErrorInterceptor
        ) {
          onCommonErrorInterceptor(errorResponse?.ErrorLists[0]);
        }
      }

      return Promise.reject(error);
    }
  );
  return api;
};

export default withInterceptors(api);
